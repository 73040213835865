import { FC } from "react";

import { VStack, Box, Flex, Text, StackProps } from "@chakra-ui/react";
import { PrismicLink, ShiIcon } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";

import Renderer from "src/lib/renderer";

import { GatsbyImage } from "gatsby-plugin-image";
import { logEvent } from "src/lib/analytics";
import { useLocation } from "@reach/router";
import { buildPageProperties } from "src/lib/event/properties";

const PackageCard: FC<
  StackProps &
    Gatsby.CreationPackageRedirectionCardFragment & { announcement?: string }
> = ({ data, ...rest }) => {
  const location = useLocation();

  if (!data) return null;

  const {
    name,
    plan_icon,
    description,
    buttons,
    section_id,
    cta_link,
    cta_label,
  } = data;

  return (
    <VStack
      {...rest}
      border="1px solid"
      borderColor="greyLight-3"
      borderRadius="lg"
      w="full"
      spacing={{ base: "space-24", md: "space-40" }}
      overflow="hidden"
      mt="space-8"
    >
      <Box
        px="space-32"
        pt={{ base: "space-16", md: "space-32" }}
        w="full"
        textAlign="center"
      >
        {plan_icon && plan_icon.gatsbyImageData && (
          <Flex alignItems="center" justifyContent="center" mb="space-8">
            <GatsbyImage
              image={plan_icon.gatsbyImageData}
              alt={plan_icon.alt || ""}
            />
          </Flex>
        )}
        <Flex
          minH="54px"
          alignItems="center"
          justifyContent="center"
          fontSize="font-18"
        >
          <Text color="text-primary">{name}</Text>
        </Flex>
        <Box my="space-16">
          <ShiIcon name="plus" w="24px" h="24px" />
        </Box>
        <Text color="text-primary">{description}</Text>
      </Box>
      <VStack spacing="space-16" pb="space-24" px="space-16" w="full">
        {cta_link && cta_label && (
          <ShiButton
            onClick={() => {
              const event = {
                name: 'Offers Redirection Clicked',
                properties: {
                  label: cta_label,
                  block: 'block_plans_presentation',
                  ...buildPageProperties(location)
                }
              }

              logEvent(event);
            }}
            as={PrismicLink}
            link={cta_link}
            param={section_id ? `?section=${section_id}` : null}
            w="full"
            hasArrow={false}
          >
            {cta_label}
          </ShiButton>
        )}
        <Renderer field={buttons} />
      </VStack>
    </VStack>
  );
};

export default PackageCard;
