import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  VStack,
  Container
} from "@chakra-ui/react";

import { AnimatePresence } from "framer-motion";
import { FC, Fragment } from "react";
import { BlockContainer, IconBox, MotionBox, ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";

const BlockFaq: FC<Gatsby.PageBlockFaqFragment> = ({ primary, items }) => {
  if (!primary || !items) throw Error();

  const { is_large: isLarge } = primary;

  const renderBox = () => {
    return <Box sx={{
      ".chakra-divider": {
        marginX: "auto"
      }
    }}>
      <Renderer field={primary.content} />
    </Box>;
  }

  const renderAccordion = (isLarge?: boolean) => {
    const paddingRightProps = isLarge ? { paddingRight: { md: "0" }} : {};
    return <Accordion textAlign="left" width="100%" allowToggle allowMultiple>
      {items.map((item) => {
        if (!item) return null;

        return (
          <AccordionItem key={item.question} borderColor="greyLight-3">
            {({ isExpanded }) => (
              <Fragment>
                <AccordionButton
                  display="flex"
                  justifyContent="space-between"
                  paddingY="space-24"
                  textAlign="left"
                  paddingLeft={{ md: "0" }}
                  {...paddingRightProps}
                >
                  <Heading
                    as="h5"
                    variant="subtitle"
                    fontSize={{ base: "font-20", lg: "font-24" }}
                    lineHeight="short"
                  >
                    {item.question}
                  </Heading>

                  <IconBox bg="greyLight-5" position="relative">
                    <AnimatePresence>
                      <MotionBox
                        position="absolute"
                        key={isExpanded ? "minus" : "plus"}
                        initial={{ opacity: 0, rotate: 60 }}
                        animate={{ opacity: 1, rotate: 0 }}
                        exit={{ display: "none" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ShiIcon
                          name={isExpanded ? "minus" : "plus"}
                          color="bg-primary-active"
                        />
                      </MotionBox>
                    </AnimatePresence>
                  </IconBox>
                </AccordionButton>

                <AccordionPanel
                  color="text-secondary"
                  sx={{
                    b: {
                      color: "text-primary",
                    },
                    ul: {
                      marginLeft: "space-32",
                    },
                    p: {
                      _last: {
                        marginBottom: 0,
                      },
                    },
                  }}
                >
                  <Renderer field={item.answer} />
                </AccordionPanel>
              </Fragment>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>;
  };

  if (isLarge) {
    return (
      <BlockContainer>
        <VStack spacing={{ base: "space-24", md: "space-40" }} width="full">
          <Container variant="heading">
            {renderBox()}
          </Container>
          {renderAccordion(isLarge)}
        </VStack>
      </BlockContainer>
    );
  }

  return (
    <BlockContainer>
      <Container variant="heading">
        <VStack spacing="space-24" width="100%">
          {renderBox()}
          {renderAccordion(isLarge)}
        </VStack>
      </Container>
    </BlockContainer>
  )
};

export default BlockFaq;
