import { Box, VStack, Text, BoxProps, Center } from "@chakra-ui/react";

import { FC } from "react";
import Renderer from "src/lib/renderer";

import { PrismicLink } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";

const Card: FC<BoxProps & { item: Gatsby.PageBlockPersonaItemFragment }> = ({
  item,
  ...rest
}) => {
  if (!item) return null;

  const { link, label, image, content } = item;

  const isLink = Boolean(link?.url);

  const imageFile = image?.document?.data?.image_file;

  if (!imageFile) {
    return null;
  }

  return (
    <Box
      h="500px"
      w="320px"
      flexShrink="0"
      borderRadius="lg"
      position="relative"
      overflow="hidden"
      willChange="transform" //require to prevent safari display bug
      className={`${label}`}
      _hover={{
        ".descriptionWrapper": {
          transform: "translateY(0)",
        },
        ".description": {
          paddingTop: "space-32",
        },
      }}
      {...rest}
    >
      {imageFile ? <Center
        position="absolute"
        top="50%"
        left="50%"
        h="full"
        w="full"
        transform="translate(-50%, -50%)"
        sx={{
          ".gatsby-image-wrapper": {
            h: "inherit",
          },
        }}
      >
        <Box
          as={GatsbyImage}
          image={imageFile?.gatsbyImageData}
          alt={imageFile?.alt ?? ""}
        />
      </Center> : null}

      <Box
        className="descriptionWrapper"
        bg="greyLight-3"
        position="absolute"
        bottom="0"
        w="full"
        zIndex="1"
        transform={"translateY(calc(100% - 60px))"}
        transitionProperty="all"
        transitionDuration="300ms"
        transitionTimingFunction="ease-out"
        _hover={{
          bg: "yellow-0",
        }}
      >
        <Box position="relative" py="space-20" className="description">
          <VStack spacing="space-16">
            <Text as="div" fontWeight="medium">
              {label} →
            </Text>
            <Box
              sx={{
                p: {
                  fontSize: "font-15",
                  color: "text-secondary",
                  _last: {
                    marginBottom: 0,
                  },
                },
              }}
              padding="space-32"
              paddingTop="0"
            >
              <Renderer field={content} />
            </Box>
          </VStack>
          {isLink && (
            <Box
              as={PrismicLink}
              link={link}
              position="absolute"
              w="full"
              h="full"
              top="0"
              left="0"
              zIndex="2"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default Card;
