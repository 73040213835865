import headingToAnchorLink from "src/lib/headingToAnchorLink";

const createSummary = (
  richText?: Gatsby.PrismicStructuredTextType["raw"]
): Array<{ text: string; anchor: string; type: string }> =>
  richText
    ?.filter((paragraph: any) => paragraph?.type === "heading2")
    .map((paragraph: any) => ({
      type: paragraph.type,
      text: paragraph.text,
      anchor: headingToAnchorLink(paragraph.text),
    }));

export default createSummary;
