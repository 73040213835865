import { FC } from "react";
import { Box, Container, ContainerProps, Stack } from "@chakra-ui/react";

import PlanCard from "./PlanCard";
import PackageCard from "./PackageCard";

const SwipeCarousel: FC<
  ContainerProps & { announcement?: string; hasBackground?: boolean, isLarge?: boolean } & (
      | Gatsby.PageBlockCreationPackagePricingItemsFragment
      | Gatsby.PageBlockPlansPresentationItemsFragment
    )
> = ({ items, announcement, hasBackground = false, isLarge = false }) => {
  if (!items) return null;

  const boxMd = isLarge
    ? "calc((1434px - 2 * 40px) / 3)"
    : (hasBackground ? "306px" : "346px");

  const renderCard = (
    type: string,
    item:
      | Gatsby.PricingPlanCardFragment
      | Gatsby.CreationPackageRedirectionCardFragment,
    override_usp: any,
  ) => {
    switch (type) {
      case "pricing_plan_card":
        return (
          <PlanCard
            {...item}
            override_usp={override_usp}
            announcement={announcement}
            h={{ base: "full", md: "auto" }}
          />
        );
      case "creation_package_redirection":
        return <PackageCard {...item} h={{ base: "full", md: "auto" }} />;
      default:
        return null;
    }
  };

  return (
    <Container size="full" p={{ md: "0" }}>
      {items && items.length > 0 && (
        <Stack
          overflowX="scroll"
          overflowY="hidden"
          whiteSpace="nowrap"
          px={{
            base: "space-20",
            md: hasBackground ? "0" : "space-58",
            "2xl": 0,
          }}
          pb="space-8"
          maxW="full"
          direction="row"
          spacing={{ base: "space-24", md: "space-40" }}
          sx={{
            scrollbarWidth: "none",
            scrollSnapType: "x mandatory",
            WebkitOverflowScrolling: "touch",
            "::-webkit-scrollbar": {
              display: "none",
            },
            ".slide": {
              scrollSnapAlign: "center",
            },
          }}
        >
          {items?.map((item) => {
            if (!item?.plan?.document) return null;

            const override_usp = item.override_usp?.document
              ? item.override_usp?.document?.data?.usp
              : null;

            return (
              <Box
                key={item?.plan?.document.id}
                className="slide"
                w={{
                  base: "calc(100% - 24px)",
                  sm: "calc(100% - 40px)",
                  md: boxMd,
                }}
                flexShrink={{ base: "0", md: "unset" }}
                h="inherit"
              >
                {renderCard(
                  item.plan.document.type,
                  item.plan.document,
                  override_usp,
                )}
              </Box>
            );
          })}
        </Stack>
      )}
    </Container>
  );
};

export default SwipeCarousel;
