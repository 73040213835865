import {
  Box,
  Stack,
  Container,
  ContainerProps,
} from "@chakra-ui/react";
import { FC, useRef } from "react";
import Card from "../atoms/Card";

const CONTAINER_MAX_WIDTH = 1440;
const DESKTOP_PADDING = 58;

const Carousel: FC<
  Gatsby.PageBlockTrioOfferFragment & ContainerProps
> = ({ items, ...rest }) => {
  const ref = useRef<HTMLDivElement>();

  if (!items) throw Error();


  //Carousel has a specific design that requires transitioning padding to make layout work with the global container's maxwidth
  const TRANSITION_PADDING = `calc(${DESKTOP_PADDING}px + ((100vw - ${CONTAINER_MAX_WIDTH}px) / 2))`;

  return (
    <Container
      size="full"
      p={{ md: "0" }}
      alignItems="start"
      width="100vw"
      maxWidth="1540px"
      marginRight={{ "2xl": "auto !important" }}
      {...rest}
    >
      <Stack
        ref={ref}
        overflowX="scroll"
        overflowY="hidden"
        whiteSpace="nowrap"
        maxW="100vw"
        w={{ base: "max-content", "2xl": "full" }}
        mx="auto"
        direction="row"
        paddingX={{
          base: "space-16",
          md: "space-58",
          xl: TRANSITION_PADDING,
          "2xl": 0,
        }}
        spacing={{ base: "space-16", md: "space-32" }}
        paddingTop="space-8" //to give space for item hover
        sx={{
          scrollSnapType: "x mandatory",
          WebkitOverflowScrolling: "touch",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {items?.map((item) => {
          if (!item) return null;

          return (
            <Box
              key={item?.title || ""}
              w={{ base: "270px", md: "360px" }}
              flexShrink="0"
              h="inherit"
              sx={{
                scrollSnapAlign: "start",
                scrollSnapStop: "always",
                scrollMargin: {
                  base: "16px",
                  md: "58px",
                  xl: TRANSITION_PADDING,
                  "2xl": 0,
                },
              }}
            >
              <Card item={item} h="full" />
            </Box>
          );
        })}
      </Stack>
    </Container>
  );
};

export default Carousel;
