import { FC, useState } from "react";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Card } from "src/atoms";
import Renderer from "src/lib/renderer";
import { heading3 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "src/atoms/PrismicLink";

const ITEMS_BY_SECTION = 5;

const PressArticles: FC<{ articles: Gatsby.PressArticleFragment[] }> = ({
  articles,
}) => {
  const [showItems, setShowItems] = useState(ITEMS_BY_SECTION);

  return (
    <VStack align="stretch" spacing="space-40">
      {articles?.slice(0, showItems).map((article) => (
        <Card
          key={article.article_title?.text}
          textAlign="left"
          paddingX={{ base: "space-24", md: "space-64" }}
          paddingY="space-32"
        >
          <HStack spacing="space-32">
            {article.article_logo?.document?.__typename ===
              "PrismicElementImage" && (
              <GatsbyImage
                image={
                  article.article_logo.document.data?.image_file
                    ?.gatsbyImageData
                }
                alt={article.article_logo.document.data?.image_file?.alt ?? ""}
              />
            )}

            <Box>
              {article.article_release_date && (
                <Text
                  as="div"
                  variant="body-small"
                  color="text-secondary"
                  textTransform="capitalize"
                  marginBottom="space-8"
                >
                  {new Date(article.article_release_date).toLocaleDateString(
                    "fr-FR",
                    {
                      month: "long",
                      year: "numeric",
                    }
                  )}
                </Text>
              )}

              <Renderer
                field={article.article_title}
                overrides={{
                  heading3: (args) =>
                    heading3({
                      ...args,
                      overrideProps: {
                        fontSize: "font-18",
                      },
                    }),
                }}
              />

              <Renderer
                field={article.article_description}
                overrides={{
                  paragraph: (args) =>
                    paragraph({
                      ...args,
                      overrideProps: {
                        fontSize: "font-16",
                      },
                    }),
                }}
              />

              {article.article_link && (
                <Button
                  as={Link}
                  target="_blank"
                  rel="noopener"
                  marginTop="space-32"
                  href={article.article_link.url}
                  variant="primary"
                >
                  {article.article_type === "Press release"
                    ? "Lire le communiqué de presse"
                    : "Lire l'article"}
                </Button>
              )}
            </Box>
          </HStack>
        </Card>
      ))}

      {showItems <= articles.length - 1 && (
        <Button
          alignSelf="center"
          paddingX="space-48"
          marginTop="space-32"
          variant="primary"
          onClick={() => setShowItems(showItems + ITEMS_BY_SECTION)}
        >
          Voir plus d&apos;articles
        </Button>
      )}
    </VStack>
  );
};

export default PressArticles;
