import { Slice } from "../types";

export const getBottomPadding = (nextBlock: Slice) => {
  
  let bottomPadding = { base: "space-40", md: "space-60" };

  if (nextBlock) {
    const { slice_type, primary } = nextBlock;

    if (slice_type === "block_columns" && primary.has_colored_background) {
      bottomPadding = { base: "0", md: "0" };
    }
  }

  return bottomPadding;
};
