import { FC } from "react";

import {
  VStack,
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Divider,
  BoxProps,
  HStack,
} from "@chakra-ui/react";
import { PrismicLink, ShiIcon } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";
import Renderer from "src/lib/renderer";
import { CardPrice } from "./styles";

import { GatsbyImage } from "gatsby-plugin-image";

import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import { onClickNewAccount } from "src/lib/event";
import { ClickInfo } from "src/lib/event/types";
import { getSubtitleText } from "src/lib/event/properties";

const PlanCard: FC<
  BoxProps &
    Gatsby.PricingPlanCardFragment & {
      announcement?: string;
      override_usp?: any;
    }
> = ({ data, announcement, override_usp, ...rest }) => {
  const visitorContext = useVisitorContext();
  const location = useLocation();

  if (!data) return null;

  const {
    title,
    tarif,
    tarif_subtitle,
    button_label,
    button_link,
    usp,
    image,
    plan_badge,
    plan_badge_color,
    subtitle
  } = data;

  /**
   * For backward compatibility purposes, we apply the following logic:
   * - button_label is present (but button_link is absent): we show the button to open an account (legacy behavior)
   * - button_label and button_link are present: we show the button with the respective link
   * - button_label is absent: we don't show the button (even if button_link is present)
   */
  const hasButtonLabel = !!button_label;
  const hasButtonLink = !!button_link && button_link.link_type !== "Any";
  const buttonProps = hasButtonLink ? {
    as: PrismicLink,
    link: button_link
  } : {
    onClick: (e) => {
      const tarifSubtitleText = getSubtitleText(tarif_subtitle);

      const clickInfo: ClickInfo = {
        label: button_label,
        price: tarif,
        title,
        block: 'block_plans_presentation',
        subtitle: tarifSubtitleText,
        fromLocation: location
      };

      onClickNewAccount(e, visitorContext, clickInfo)
    }
  };

  let planUsp = usp;

  if (override_usp) {
    planUsp = override_usp;
  }

  const subtitleSx = {
    p: {
      fontSize: "font-12",
      marginBottom: 0,
      color: "text-secondary",
    },
    em: {
      textDecoration: "line-through",
      fontStyle: "normal",
    },
    "@media (min-width: 60em)": {
      p: {
        fontSize: "font-14",
      },
    },
  };

  return (
    <Box {...rest}>
      <Box
        position="relative"
        marginTop="space-8"
        borderRadius="lg"
        border="1px solid"
        borderColor="greyLight-3"
        bg="white"
        w="full"
        h={"calc(100% - 12px)"}
        sx={{
          ".gatsby-image-wrapper": {
            width: "100%",
          },
        }}
      >
        {plan_badge && plan_badge_color && (
          <Box
            bg={
              plan_badge_color === "green"
                ? "green-1"
                : plan_badge_color === "blue"
                ? "blue-3"
                : "orange-2"
            }
            color={
              plan_badge_color === "green"
                ? "green-0"
                : plan_badge_color === "blue"
                ? "blue-4"
                : "orange-0"
            }
            py="space-4"
            px="space-8"
            borderRadius="lg"
            fontWeight="bold"
            fontSize="9px"
            lineHeight="none"
            textTransform="uppercase"
            left="50%"
            transform="translateX(-50%)"
            position="absolute"
            top="-8px"
            zIndex="1"
          >
            {plan_badge}
          </Box>
        )}
        {image && image.gatsbyImageData && (
          <Box
            overflow="hidden"
            borderTopLeftRadius="lg"
            borderTopRightRadius="lg"
            bg="greyLight-5"
          >
            <GatsbyImage image={image?.gatsbyImageData} alt={image.alt || ""} />
          </Box>
        )}
        <Box pt="space-16" pb="space-24">
          <Box paddingX="space-16">
            <HStack spacing="space-28">
              <Heading
                as="h3"
                fontFamily="body"
                fontSize={{ base: "font-16", md: "font-18" }}
                fontWeight="medium"
              >
                {title}
              </Heading>
            </HStack>
            <Box mt={{ base: "space-4", md: "space-8" }}>
              <CardPrice price={tarif!} priceUnit="€" />
              <Box
                color="text-secondary"
                display="inline-block"
                ml="space-8"
                sx={subtitleSx}
              >
                <Renderer field={tarif_subtitle} />
              </Box>
            </Box>
            {subtitle?.raw?.length > 0 ? <Box mt={{ base: "space-4", md: "space-8" }}>
              <Box
                color="text-secondary"
                display="inline-block"
                sx={subtitleSx}
              >
                <Renderer field={subtitle} />
              </Box>
            </Box> : null}
          </Box>
          <VStack spacing={{ base: "space-24", md: "space-20" }}>
            <Box w="full" paddingX="space-16">
              {announcement && (
                <Box
                  alignSelf="flex-start"
                  mt="space-16"
                  bg="greyLight-5"
                  p="space-4"
                  borderRadius="4px"
                  display={{ base: "inline-block", md: "none" }}
                >
                  <Text color="text-primary" fontSize="font-12">
                    {announcement}
                  </Text>
                </Box>
              )}
            </Box>

            <Box w="full">
              {hasButtonLabel ? <Box paddingX="space-16">
                <ShiButton
                  hasArrow={false}
                  w={{ base: "full" }}
                  marginBottom={{ base: "space-16", md: "space-24" }}
                  {...buttonProps}
                >
                  {button_label}
                </ShiButton>
              </Box> : null}
              <Divider variant="greyLight" />
            </Box>

            <List spacing="space-16" paddingX="space-16" w="full">
              {planUsp?.map((listItem, index) => {
                return (
                  <ListItem
                    display="flex"
                    alignItems="center"
                    key={"point-" + index}
                  >
                    <ShiIcon name="check" fill="#5CA3FF" />
                    <Box
                      ml="space-16"
                      w="full"
                      sx={{
                        p: {
                          fontSize: "16px",
                          marginBottom: "space-4",
                        },
                        "p:last-of-type": {
                          marginBottom: 0,
                        },
                      }}
                    >
                      <Renderer
                        field={listItem?.point}
                        overrides={{
                          [Element.paragraph]: (args) => {
                            const hasBadge = args.children.some((child) =>
                              child?.props?.className?.includes("badge"),
                            );
                            return paragraph({
                              ...args,
                              overrideProps: {
                                display: hasBadge ? "flex" : "block",
                                alignItems: "center",
                                justifyContent: "space-between",
                                sx: {
                                  ".chakra-badge": {
                                    fontSize: "font-10",
                                    textTransform: "upperCase",
                                    paddingX: "8px !important",
                                    bg: "green-1",
                                    color: "green-0",
                                    marginLeft: "space-4",
                                  },
                                  ".badge-blue": {
                                    bg: "blue-3",
                                    color: "blue-4",
                                  },
                                },
                              },
                            });
                          },
                        }}
                      />
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanCard;
