import { Box } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { BlockContainer } from "src/atoms";
import useIframe from "src/hooks/useIframe";

const CollectionIframe: FC<Gatsby.CollectionIframeFragment> = ({ data }) => {
  const script = data?.html_script_file?.localFile?.text;

  const [iframeHeight, setIframeHeight] = useState(0);
  const iframe = useIframe(script ?? "");

  useEffect(() => {
    setIframeHeight(
      iframe.current?.contentWindow?.document.body.scrollHeight ?? 0
    );
  }, [iframe]);

  if (!script) return null;

  return (
    <BlockContainer textAlign="center">
      <Box as="iframe" ref={iframe} width="100%" height={`${iframeHeight}px`} />
    </BlockContainer>
  );
};

export default CollectionIframe;
