import { Box, BoxProps } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";

import { FC, useEffect, useRef } from "react";

const ParallaxMedia: FC<
  BoxProps & {
    item: Gatsby.PageBlockAnimatedTestimonialItemFragment;
    speed?: number;
    imageProps?: BoxProps;
    imageContainerProps?: BoxProps;
  }
> = ({ item, speed = 0.2, imageProps, imageContainerProps, ...rest }) => {
  const mediaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parallax = () => {
      if (mediaRef.current) {
        const scrollY = window.scrollY;

        const rect = mediaRef.current.getBoundingClientRect();
        const offset = rect.top + scrollY;
        const height = rect.height;
        const distance = (scrollY - offset) / height;
        const pos = distance * (height * -speed);

        mediaRef.current.style.transform = `translate3d(0,${pos}px,0)`;
      }
    };

    parallax();

    window.addEventListener("scroll", parallax);

    return () => {
      window.removeEventListener("scroll", parallax);
    };
  }, [speed]);

  if (!item) return null;

  return (
    <Box key={item?.image?.id} {...rest}>
      <Box ref={mediaRef} {...imageContainerProps}>
        <Box
          as={GatsbyImage}
          image={item?.image?.document?.data?.image_file?.gatsbyImageData}
          alt={item?.image?.document?.data?.image_file?.alt ?? ""}
          {...imageProps}
        />
      </Box>
    </Box>
  );
};

export default ParallaxMedia;
