import { Box, Flex, Table, Tbody, Text, Thead } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC, Fragment } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";
import { image } from "src/lib/renderer/elements/image";
import paragraph from "src/lib/renderer/elements/paragraph";
import RatingsGradeStars from "src/components/GradeStars";
import TableCell from "./components/TableCell";
import TableRow from "./components/TableRow";

const CollectionTable: FC<Gatsby.CollectionTableFragment> = ({ data }) => {
  if (!data) throw Error();

  const { content, head_images_height: headImagesHeight, head_row, body, buttons } = data;

  return (
    <BlockContainer>
      <Flex
        marginBottom="space-40"
        direction="column"
        align="center"
        maxWidth="730px"
      >
        <Renderer field={content} />
      </Flex>

      <Table
        sx={{
          tableLayout: "fixed",
        }}
        variant="simple"
        backgroundColor="white"
        color="text-primary"
        display={{ base: "block", md: "table" }}
        width="auto"
        maxWidth="100%"
        margin="auto"
        overflowX={{ base: "scroll", md: "inherit" }}
      >
        <Thead backgroundColor="ctaBlue-6" maxHeight="100px">
          <TableRow>
            <Box />

            {head_row?.map((cell, i) => {
              const image = cell?.head_image?.document;
              const imageFile = image?.__typename === "PrismicElementImage"
                ? image.data?.image_file
                : null;

              const imageFileHeight = imageFile?.gatsbyImageData?.height || 1;
              const imageFileWidth = imageFile?.gatsbyImageData?.width || 1;

              const imageProp = !!headImagesHeight && headImagesHeight > 0
                ? {
                    height: `${headImagesHeight}px`,
                    width: `${Math.round(headImagesHeight * imageFileWidth / imageFileHeight)}px`,
                    my: 'space-32',
                    mx: 'space-24',
                  }
                : { width: "100px" };

              return (
                <TableCell key={i} isHeadRow>
                  {image?.__typename === "PrismicElementImage" && (
                    <Box
                      as={GatsbyImage}
                      image={imageFile?.gatsbyImageData}
                      alt={imageFile?.alt ?? ""}
                      {...imageProp}
                    />
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </Thead>

        <Tbody>
          {body?.map((row, i) =>
            row?.__typename === "PrismicCollectionTableDataBodyTableRow" ? (
              <TableRow key={i}>
                <TableCell fontWeight="medium" textAlign="start">
                  {row.primary?.row_label}
                </TableCell>

                {row.items?.map((cell, i) => (
                  <TableCell key={i}>
                    {cell?.cell_icon ? (
                      <ShiIcon
                        name={cell.cell_icon as any}
                        fontSize="20px"
                        color={
                          cell.cell_icon === "cross"
                            ? "pink-2"
                            : "turquoise-2"
                        }
                      />
                    ) : (
                      <Renderer
                        field={cell?.cell_content}
                        overrides={{
                          image: (args) =>
                            image({
                              ...args,
                              overrideProps: {
                                width: 90,
                                marginBottom: "0",
                              },
                            }),
                          paragraph: (args) =>
                            paragraph({
                              ...args,
                              overrideProps: {
                                fontSize: "font-14",
                                marginBottom: "0",
                              },
                            }),
                        }}
                      />
                    )}

                    {cell?.cell_star_rating && (
                      <Fragment>
                        <RatingsGradeStars
                          value={cell.cell_star_rating}
                          paddingY="space-4"
                        />
                        <Text>
                          <Text as="span" fontWeight="medium">
                            {`${cell.cell_star_rating} `}
                          </Text>
                          /5
                        </Text>
                      </Fragment>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ) : null,
          )}
        </Tbody>
      </Table>

      <Box
        marginTop="space-32"
        sx={{
          ".chakra-button": {
            width: "100%",
          },
        }}
      >
        <Renderer field={buttons} />
      </Box>
    </BlockContainer>
  );
};

export default CollectionTable;
