import { Box, Stack, chakra } from "@chakra-ui/react";
import { FC, useRef, useEffect, useState } from "react";
import Renderer from "src/lib/renderer";
import { BlockContainer } from "src/atoms";
import { InView } from "react-intersection-observer";
import { getAdjustedStep, isDigit } from "./utils";

const DEFAULT_FONT_SIZE = 16;

const BlockNumber: FC<Gatsby.PageBlockNumberFragment> = ({
  primary,
  items,
}) => {
  if (!primary) throw Error();

  const { content } = primary;

  const emRef = useRef<HTMLDivElement>(null);
  const numberWrapperRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const numberRef = useRef<HTMLDivElement>(null);
  const numberSpanRefs = useRef<Array<HTMLSpanElement | null>>([]);

  //animatable array texts should be joined with a space " "
  const fixedItems = items?.map((item, index) => {
    const isLast = index === items.length - 1;
    if (!item) return null;
    if (!item.text) return item;
    return { ...item, text: isLast ? item.text : `${item.text} ` };
  });

  const animatedNumberString = fixedItems
    ?.map((item) => (item?.text ? item.text : ""))
    .join("");

  const charDatas = fixedItems
    ?.filter((item) => item && item.text)
    .map((item) => {
      if (!item || !item.text) return null;
      const textArray = [...item.text];

      const firstDigitIndex = textArray.findIndex((char) => isDigit(char));
      const firstDigit = textArray && firstDigitIndex !== -1 ? Number(textArray[firstDigitIndex]) : 0;
      let adjustedStep = getAdjustedStep(firstDigit);

      if (item.direction === "decrease") {
        //if the direction is decrease, the first number will iterate from 9(max value) until its value.
        const distance = 9 - firstDigit;
        adjustedStep = getAdjustedStep(distance);
      }
      return textArray.map((char, i) => ({
        direction: item?.direction,
        animated: item?.is_animated,
        firstDigit: firstDigit,
        adjustedStep: adjustedStep,
        isFirstDigit: i === firstDigitIndex,
      }));
    })
    .flat();

  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [active, setActive] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const hasAnimation =
    !!animatedNumberString && !!numberSpanRefs.current && !!charDatas;

  let charIndex = -1;
  const [numbers] = useState(() => {
    if (!fixedItems) return [];
    let numberIndex = 0;

    return fixedItems?.map((item) => {
      if (!item || !item.text || !charDatas) return null;

      return [...item.text].map((char) => {
        const isSpace = char.trim() === "";
        const isNonDigit = isSpace || !isDigit(char);
        const key = `${char}-${charIndex}`;
        charIndex++;

        if (isNonDigit) {
          return (
            <chakra.span
              ref={(el) => numberSpanRefs.current.push(el)}
              key={key}
            >
              {isSpace ? "\u00A0" : char}
            </chakra.span>
          );
        } else {
          const delay = numberIndex * 100; //number animation should be staggered
          numberIndex = numberIndex + 1;
          const adjustedStep = charDatas?.[charIndex]?.adjustedStep ?? 0;
          return (
            <chakra.span
              ref={(el) => numberSpanRefs.current.push(el)}
              transform="translateY(0)"
              transitionProperty="common"
              transitionDuration={`${adjustedStep}s`}
              transitionTimingFunction="ease-out"
              transitionDelay={`${delay}ms`}
              display="flex"
              flexDirection="column"
              h="full"
              textAlign="center"
              position="relative"
              key={key}
            >
              <chakra.span flex="1">{item.is_animated ? 0 : char}</chakra.span>
            </chakra.span>
          );
        }
      });
    });
  });

  useEffect(() => {
    //The number string has to adjust its size to fit to space despite its length
    //therefore, the font size is dynamic according to the length of the string and the screen size & available space
    const fitFontToSpace = () => {
      const em = emRef.current?.clientWidth;
      const numberWrapper = numberWrapperRef.current;
      const placeholder = placeholderRef.current;

      if (em && numberWrapper && placeholder) {
        const numberWrapperWidth = numberWrapper.clientWidth;
        const placeholderWidth = placeholder.clientWidth;
        const factor = numberWrapperWidth / placeholderWidth;
        const calc = em * factor;
        setFontSize(calc);
        setActive(true);
      }
    };

    const clearNumberAnimation = () => {
      if (hasAnimation) {
        for (let i = 0; i < animatedNumberString.length; i++) {
          const item = numberSpanRefs.current[i];
          const data = charDatas[i];

          if (!item || !data || !data.animated) return;

          const value = animatedNumberString[i];

          const isNumber = isDigit(value);

          if (isNumber) {
            const span = document.createElement("span");
            span.style.position = "absolute";
            span.style.transform = "translate(-50%, -50%)";
            span.style.left = "50%";
            span.style.top = "50%";
            span.innerHTML = `${value}`;

            const placeholder = document.createElement("span");
            placeholder.style.flex = "1";
            placeholder.innerHTML = "0";
            placeholder.style.visibility = "hidden";

            while (item.firstChild) {
              item.removeChild(item.firstChild);
            }

            item.style.transform = "none";
            item.style.transitionProperty = "none";

            item.appendChild(span);
            item.appendChild(placeholder);
          }
        }
      }
    };

    const animateNumbers = () => {
      if (hasAnimation) {
        setTimeout(() => {
          for (let i = 0; i < animatedNumberString.length; i++) {
            const item = numberSpanRefs.current[i];
            const data = charDatas[i];

            if (!item || !data || !data.animated) return;

            const { isFirstDigit, adjustedStep, direction } = data;
            const isNumber = isDigit(animatedNumberString[i]);

            let translateY = 0;

            if (isNumber) {
              const value = Number(animatedNumberString[i]);

              if (!isFirstDigit) {
                translateY = adjustedStep * 100;
              } else if (direction === "increase") {
                translateY = value * 100;
              } else {
                translateY = (10 - value) * 100;
              }
            }

            item.style.transform = `translateY(-${translateY}%)`;
          }
        }, 100);

        const largestAdjustedStep = charDatas.reduce(
          (maxAdjustedStep, item) => {
            if (!item) return maxAdjustedStep;

            return Math.max(item.adjustedStep, maxAdjustedStep);
          },
          0,
        );

        const delay = largestAdjustedStep * 1000 + charDatas.length * 100; //animation duration + staggered effect delay

        setTimeout(() => {
          //restore to the original state of the DOM elements
          clearNumberAnimation();
        }, delay);
      }
    };

    const startNumberAnimation = () => {
      if (hasAnimation) {
        for (let i = 0; i < animatedNumberString.length; i++) {
          const item = numberSpanRefs.current[i];
          const data = charDatas[i];

          if (!item || !data || !data.animated) return;

          const isNumber = isDigit(animatedNumberString[i]);
          const { isFirstDigit, adjustedStep, direction } = data;

          if (isNumber) {
            const value = Number(animatedNumberString[i]);
            //prepend or append DOM elements
            if (isFirstDigit) {
              if (direction === "increase") {
                for (let j = value; j >= 0; j--) {
                  const stepDigit = j;
                  const span = document.createElement("span");
                  span.innerHTML = `${stepDigit}`;

                  item.prepend(span);
                }
              } else {
                for (let j = 9; j >= value; j--) {
                  const stepDigit = j;
                  const span = document.createElement("span");
                  span.innerHTML = `${stepDigit}`;

                  item.append(span);
                }
              }
            } else {
              for (let j = 0; j <= adjustedStep; j++) {
                let digit = 0;

                if (j !== adjustedStep) {
                  digit = j === 0 ? value : Math.floor(Math.random() * 10);
                }

                const span = document.createElement("span");
                span.innerHTML = `${digit}`;

                item.prepend(span);
              }
            }
          }

          animateNumbers();
        }
      }
    };

    fitFontToSpace();

    if (shouldAnimate) {
      startNumberAnimation();
    }

    window.addEventListener("resize", fitFontToSpace);

    return () => {
      window.removeEventListener("resize", fitFontToSpace);
    };
  }, [fontSize, shouldAnimate, animatedNumberString, charDatas, hasAnimation]);

  return (
    <BlockContainer>
      <Box
        w="full"
        sx={{
          "#InView": {
            w: "full",
          },
        }}
      >
        <InView
          as="div"
          id="InView"
          threshold={0.5}
          onChange={(inView) => {
            //this state change should only occur once
            if (inView) {
              setShouldAnimate(true);
            }
          }}
        >
          <Stack
            color="white"
            borderRadius="lg"
            width="full"
            bg="grey-0"
            padding={{ base: "space-40", md: "space-80" }}
            position="relative"
            spacing={{ base: "space-24", md: "space-40" }}
            direction={{ base: "column", md: "row" }}
            textAlign="left"
            alignItems="center"
          >
            <Box
              ref={numberWrapperRef}
              flex="1"
              position="relative"
              width="full"
              maxW={{ base: "full", md: "33.3%" }}
              overflow="hidden"
              h={fontSize + "px"}
            >
              <chakra.span
                ref={placeholderRef}
                display="inline-block"
                fontSize="16px !important"
                opacity="0"
                position="absolute"
              >
                {/* placeholder*/}
                {fixedItems &&
                  fixedItems.map((item) => {
                    if (!item || !item.text) return null;
                    return [...item.text].map((char, i) => {
                      return (
                        <chakra.span key={`${char}-${i}`}>
                          {/* 0 has the widest width from 0 to 9 */}
                          {isDigit(char) ? 0 : char}
                        </chakra.span>
                      );
                    });
                  })}
              </chakra.span>

              {/* animated numbers */}
              <Box
                fontSize={`${fontSize}px`}
                lineHeight="1"
                opacity={active ? 1 : 0}
                ref={numberRef}
                position="relative"
                display="flex"
                alignItems="center"
                height={`${fontSize}px`}
              >
                {numbers}
              </Box>
            </Box>
            <Box flex="2" sx={{ h2: { color: "inherit" } }}>
              <Renderer field={content} />
            </Box>

            <Box width="1em" ref={emRef} position="absolute" />
          </Stack>
        </InView>
      </Box>
    </BlockContainer>
  );
};

export default BlockNumber;
