import { Box, Button, Text, BoxProps, Link, VStack, AspectRatio } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { DEFAULT_ASPECT_RATIO } from "../constants";
import { useInView } from "react-intersection-observer";

const Item: FC<NonNullable<Gatsby.PageBlockAnimationFragment["items"]>[number] & {
  selected: boolean,
  desktopInView: boolean,
  forceMP4: boolean,
  progressBarFillingIn: string,
  progressBarFillingOut: string,
  duration: number,
  progressBarRemainingWidthProps: {
    width?: string
  },
  onClick?: () => void,
  containerProps?: BoxProps
}> = ({
  label,
  link,
  title,
  description,
  mobile_video_mp4,
  mobile_video_webm,
  mobile_placeholder_image,
  aspect_ratio,
  selected,
  desktopInView,
  forceMP4,
  progressBarRemainingWidthProps,
  progressBarFillingIn,
  progressBarFillingOut,
  duration,
  onClick,
  containerProps
}) => {
  const mobileVideoMP4 = mobile_video_mp4?.url;
  const mobileVideoWebM = mobile_video_webm?.url;

  const mobilePlaceholderImageUrl = mobile_placeholder_image?.document?.data?.image_file?.url;

  const aspectRatio = aspect_ratio || DEFAULT_ASPECT_RATIO;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const videoElement = document.getElementById('mobile-video') as HTMLVideoElement;
      const videoMP4Element = document.getElementById("mobile-video-mp4");
      const videoWebMElement = document.getElementById("mobile-video-webm");
      if (videoElement) {
        videoElement.pause();

        if (videoMP4Element && mobileVideoMP4) {
          videoMP4Element.setAttribute("src", mobileVideoMP4);
        }

        if (videoWebMElement && mobileVideoWebM) {
          videoWebMElement.setAttribute("src", mobileVideoWebM);
        }

        videoElement.load();
      }
    }
  }, [mobileVideoMP4, mobileVideoWebM]);

  const { ref: mobileRef, inView: mobileInView } = useInView({
    rootMargin: "50px 0px 50px 0px",
    triggerOnce: true,
  });

  return (
    <VStack
      alignItems="stretch"
      justifyContent="flex-start"
      width="full"
      spacing="0"
    >
      <Box
        ref={mobileRef}
        display={{ base: "block", md: "none" }}
        mb={{ base: "space-32", md: "0" }}
      >
        {mobileVideoMP4 || mobileVideoWebM ? (
          <AspectRatio
            width="100%"
            ratio={aspectRatio}
            overflow="hidden"
            objectFit="cover"
            borderBottom="1px solid"
            borderColor="grey-0"
          >
            {mobileVideoMP4 || mobileVideoWebM ? (
              <video id="mobile-video" poster={mobilePlaceholderImageUrl} autoPlay loop muted playsInline>
                {mobileInView && mobileVideoWebM && !forceMP4 ? <source id="mobile-video-webm" src={mobileVideoWebM} type="video/webm" /> : null}
                {mobileInView && mobileVideoMP4 ? <source id="mobile-video-mp4" src={mobileVideoMP4} type="video/mp4" /> : null}
              </video>
            ) : null}
          </AspectRatio>
        ) : null}
      </Box>

      <Box
        mb="0px !important"
        px={{ base: "space-24", md: "0" }}
        fontFamily="body"
        fontWeight="normal"
        color={selected ? "grey-0" : "#CDCDCD"}
        {...containerProps}
      >
        <Button
          w="full"
          display="inline-flex"
          fontSize="font-20"
          lineHeight="base"
          fontWeight="normal"
          justifyContent="left"
          variant="ghost"
          paddingX="0"
          paddingTop="0 !important"
          paddingBottom={selected ? "space-16" : "0 !important"}
          backgroundColor="white"
          _active={{
            color: "grey-0"
          }}
          _hover={{
            backgroundColor: "white",
          }}
          onClick={() => onClick?.()}
        >
          {title}
        </Button>

        {selected ? (
          <Box
            marginBottom={{ base: "space-40", md: "space-8" }}
            textAlign="left"
          >
            <Text
              fontSize="font-16"
              lineHeight="short"
              textAlign="left"
              maxWidth={{ base: "full", md: "70%" }}
            >
              {description}
            </Text>

            {link ? (
              <Link
                display="inline-block"
                href={link.url}
                target={link.target}
                marginTop="space-16"
                textAlign="left"
                bg="grey-200"
                py="space-6"
                px="space-16"
                borderRadius="50px"
                fontWeight="medium"
                fontSize="font-13"
                lineHeight="base"
                color="grey-700"
              >
                {label}
              </Link>
            ) : null}
          </Box>
        ) : null}

        {selected ? (
          <Box
            display={{ base: "none", md: "inline-block" }}
            animation={desktopInView ? `${progressBarFillingIn} ${duration}ms linear infinite` : undefined}
            borderTop="1px solid"
            borderColor={"grey-0"}
          />
        ) : null}
        
        <Box
          animation={selected && desktopInView ? `${progressBarFillingOut} ${duration}ms linear infinite` : undefined}
          {...progressBarRemainingWidthProps}
          borderTop="1px solid"
          display={{ base: "none", md: "inline-block" }}
          borderColor={"#CDCDCD"}
        />
      </Box>
    </VStack>
  )
};

export default Item;
