type ValidBreakpoint = 'md' | 'lg' | 'xl' | '2xl' | '3xl';
const ALLOWED_BREAKPOINTS = ['md', 'lg', 'xl', '2xl', '3xl'];

const isValidDesktopMediaBreakpoint = (breakpoint?: string): breakpoint is ValidBreakpoint => {
  return !!(breakpoint && ALLOWED_BREAKPOINTS.includes(breakpoint));
};

const areOrderedBreakpoints = (breakpoint1: ValidBreakpoint, breakpoint2: ValidBreakpoint) => {
  return ALLOWED_BREAKPOINTS.indexOf(breakpoint1) <= ALLOWED_BREAKPOINTS.indexOf(breakpoint2);
};

export {
  areOrderedBreakpoints,
  isValidDesktopMediaBreakpoint
};
