import { FC, Fragment } from "react";
import BlockShineStart from "./slices/BlockShineStart";
import PageShineStart from "./slices/PageShineStart";

const CollectionShineStartBlocks: FC<Gatsby.CollectionShineStartBlocksFragment> = ({
  data,
}) => {
  if (!data?.body) throw Error();

  return (
    <Fragment>
      {data.body.map((slice) => {
        if (!slice) return null;

        switch (slice.__typename) {
          case "PrismicCollectionShinestartBlocksDataBodyBlockShineStart":
            return <BlockShineStart {...slice} />;
          case "PrismicCollectionShinestartBlocksDataBodyPageShineStart":
            return <PageShineStart {...slice} />;
        }
      })}
    </Fragment>
  );
};

export default CollectionShineStartBlocks;
