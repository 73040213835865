import { HStack, Box, VStack, Grid, GridItem, Text, Center } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";
import Renderer from "src/lib/renderer";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { SvgOrImg } from "src/components/SvgOrImg";
import BlockHubspotFormIntegration from "../BlockHubspotForm/BlockHubspotFormIntegration";
import { useLocation } from "@reach/router";
import { areOrderedBreakpoints, isValidDesktopMediaBreakpoint } from "./utils";
import { clamp } from "../utils";

const BlockEmbedHubspotForm: FC<Gatsby.PageBlockEmbedHubspotFormFragment & { index: number }> = ({
  index: formIndex,
  primary,
  items
}) => {
  if (!primary) throw Error();

  const hasValidItems = items && items.length > 0 && !!items[0]?.label;

  const {
    content,
    media_1: media1,
    media_2: media2,
    media_3: media3,
    media_2_breakpoint: media2BreakpointUnsafe,
    media_3_breakpoint: media3BreakpointUnsafe,
    cta_subtitle: ctaSubtitle,
    cta_subicon: ctaSubIcon,
    error_title: errorTitle,
    error_subtitle: errorSubtitle,
    error_image: errorImage,
    post_submission_title: postSubmissionTitle,
    post_submission_subtitle: postSubmissionSubtitle,
    post_submission_image: postSubmissionImage,
    form_id: formId,
    form_min_height: formMinHeightUnsafe,
    form_min_width: formMinWidthUnsafe,
    redirect_url: redirectUrl
  } = primary;

  const media2Breakpoint = isValidDesktopMediaBreakpoint(media2BreakpointUnsafe)
    ? media2BreakpointUnsafe
    : 'lg';

  const media3BreakpointUnordered = isValidDesktopMediaBreakpoint(media3BreakpointUnsafe)
    ? media3BreakpointUnsafe
    : 'xl';

  const media3Breakpoint = areOrderedBreakpoints(media2Breakpoint, media3BreakpointUnordered)
    ? media3BreakpointUnordered
    : media2Breakpoint;

  const formMinWidth = clamp(300, 700, 500, formMinWidthUnsafe);
  const formMinHeight = clamp(100, Number.MAX_SAFE_INTEGER, 800, formMinHeightUnsafe);

  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const debugValue = params.get('debug');
  const debugFormHasErrored = debugValue === 'error';
  const debugFormIsSubmitted = debugValue === 'submitted';

  const [formIsAvailable, setFormIsAvailable] = useState(false);
  const [formIsLoading, setFormIsLoading] = useState(true);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [formHasErrored, setFormHasErrored] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);

  if (debugFormIsSubmitted) {
    setTimeout(() => setFormIsSubmitted(true));
  }

  if (debugFormHasErrored) {
    setTimeout(() => setFormHasErrored(true));
  }

  // Handlers
  const setFormIsAvailableCallback = (flag: boolean) => {
    setFormIsAvailable(flag);
  };

  const setFormIsLoadingCallback = (flag: boolean) => {
    setFormIsLoading(flag);
  };

  const setFormHasErroredCallback = (flag: boolean) => {
    setFormHasErrored(flag);
  }

  const setFormIsSubmittedCallback = (flag: boolean) => {
    setFormIsSubmitted(flag);
  }

  useEffect(() => {
    if (!formIsLoading) {
      if (formIsAvailable) {
        setTimeout(() => setIsBlurred(false), 300);
      } else if (formHasErrored) {
        setIsBlurred(false);
      } else {
        setIsBlurred(true);
      }
    }
  }, [formIsAvailable, formHasErrored, formIsLoading]);

  if (!formId) {
    return null;
  }

  const redirectUrlString = redirectUrl?.url || '';

  const renderItems = (items: Gatsby.PageBlockEmbedHubspotFormFragment['items']) => {
    return hasValidItems ? (
      <VStack align="flex-start" spacing="space-4" w="full">
        {items?.map((item) => {
          if (!item) return null;
          return (
            <HStack
              key={item.label}
              spacing="space-8"
              align="flex-start"
            >
              <Center
                w={{ base: "28px", md: "32px" }}
                h={{ base: "28px", md: "32px" }}
                flexShrink="0"
                borderRadius="md"
                justifyContent="center"
                alignItems="center"
              >
                <SvgOrImg
                  imageFile={item.icon?.document?.data?.image_file}
                  isBox={true}
                  size={16}
                  colorChanges={[["#25241D", "white"]]}
                />
              </Center>

              <Box>
                <Text
                  as="div"
                  textAlign="left"
                  variant="body-small"
                  mt={{ base: "3px", md: "8px" }} //required for the center alignment
                  textColor="white"
                  fontSize={{ base: "font-16", md: "font-15" }}
                  lineHeight={{ base: 1.3, md: 1.5 }}
                  fontFamily="body"
                >
                  {item.label}
                </Text>
              </Box>
            </HStack>
          );
        })}
      </VStack>
    ) : null
  };

  const renderFinalScreen = (primaryText: string, secondaryText: string, image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageConstrained200Fragment>;
  }>) => {
    return <VStack>
        {renderPostImg(image)}
        <Box>
          <Text
            as="div"
            textAlign="center"
            fontSize={{ base: 'font-42', [media2Breakpoint]: 'font-50', ...{ [media3Breakpoint]: 'font-58' }}}
            lineHeight={1}
            fontFamily="heading"
            textColor="grey-0"
          >
            {primaryText}
          </Text>
        </Box>
        <Box>
          <Text
            as="div"
            textAlign="center"
            fontSize="font-15"
            lineHeight={{ base: 1.3, md: 1.5 }}
            fontFamily="body"
            textColor="grey-1"
            mt="space-8"
          >
            {secondaryText}
          </Text>
        </Box>
    </VStack>
  };

  const renderForm = () => {
    const blurredProps = isBlurred ? {
      filter: 'blur(5px)',
      pointerEvents: 'none'
    } : {};

    const formIsInFinalState = formIsSubmitted || formHasErrored;

    const centerProps = formIsInFinalState ? {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    } : {};

    return (
      <Box
        bg="grey-3"
        borderRadius="lg"
        color="grey-1"
        fontFamily="body"
        fontSize="font-15"
        fontWeight="normal"
        padding={{ base: "space-20", md: "space-32" }}
        position="relative"
        minHeight={{ base: "400px", md: `${formMinHeight}px` }}
        {...blurredProps}
        {...centerProps}
      >
        {!formIsInFinalState ? <BlockHubspotFormIntegration
          formId={formId}
          formIndex={formIndex}
          redirectUrl={redirectUrlString}
          embedLayout
          setFormIsAvailableCallback={setFormIsAvailableCallback}
          setFormIsLoadingCallback={setFormIsLoadingCallback}
          setFormIsSubmittedCallback={setFormIsSubmittedCallback}
          setFormHasErroredCallback={setFormHasErroredCallback}
        /> : null}
        {formIsAvailable && !isBlurred && !formIsInFinalState
          ? renderSubtitleText(ctaSubIcon, ctaSubtitle)
          : null
        }
        {formIsSubmitted && !formHasErrored ? renderFinalScreen(
          postSubmissionTitle || "Merci pour votre confiance.",
          postSubmissionSubtitle || "Nous avons bien reçu votre demande. Un conseiller prendra contact avec vous rapidement.",
          postSubmissionImage
        ) : null}
        {formHasErrored ? renderFinalScreen(
          errorTitle || "Aïe, nous n'avons pas trouvé ce formulaire.",
          errorSubtitle || "Il est probable que ce formulaire ait été supprimé ou qu'il n'ait jamais existé.",
          errorImage
        ) : null}
      </Box>
    );
  };

  const renderSubtitleText = (icon: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageFixed12Fragment>;
  }>, title?: string) => {
    return (
      <HStack
        key={title}
        spacing="space-2"
        align="flex-start"
        justifyContent="center"
      >
        <Center
          w="24px"
          h="24px"
          flexShrink="0"
          borderRadius="md"
          justifyContent="center"
          alignItems="center"
        >
          <SvgOrImg
            imageFile={icon?.document?.data?.image_file}
            isBox={true}
            size={12}
          />
        </Center>

        {title ? <Box>
          <Text
            as="div"
            textAlign="left"
            mt="2px" //required for the center alignment
            fontSize={{ base: "font-16", md: "font-15" }}
            lineHeight={{ base: 1.3, md: 1.5 }}
            fontFamily="body"
            textColor="grey-1"
          >
            {title}
          </Text>
        </Box> : null}
      </HStack>
    );
  };

  const renderPostImg = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageConstrained200Fragment>;
  }>) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        as={GatsbyImage}
        borderRadius="lg"
        maxHeight="200px"
        maxWidth="200px"
        marginBottom="space-36"
        image={imageFile.gatsbyImageData}
        alt={imageFile.alt ?? ""}
      />
    );
  };

  const renderImg = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageConstrained600Fragment>;
  }>) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        as={GatsbyImage}
        borderRadius="lg"
        maxHeight={{ base: "250px", sm: "300px", md: "unset" }}
        image={imageFile.gatsbyImageData}
        alt={imageFile.alt ?? ""}
        sx={{ 'aspectRatio': { base: 'unset', md: '1' }}}
      />
    );
  };

  const renderBlock = (items: Gatsby.PageBlockEmbedHubspotFormFragment['items'], content: Gatsby.Maybe<Pick<Gatsby.PrismicStructuredTextType, "raw">>) => {
    return (
      <VStack
        textAlign={{ base: "left" }}
        spacing={{ base: "space-16", md: "space-24" }}
        align={{ base: "flex-start" }}
        style={{ display: "flex", justifyContent: "space-between", height: "100%" }}
      >
        <VStack
          spacing="space-16"
          align={{ base: "flex-start" }}
        >
          <Renderer
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                    textColor: 'white',
                    fontSize: { base: 'font-42', [media2Breakpoint]: 'font-50', ...{ [media3Breakpoint]: 'font-58' }},
                    fontFamily: "heading",
                    fontWeight: "normal",
                    lineHeight: { base: 1.1, md: 1 },
                  },
                }),
              [Element.heading3]: (args) =>
                heading3({
                  ...args,
                  overrideProps: {
                    variant: "subtitle-large",
                    marginBottom: 0,
                    textColor: 'white',
                    fontSize: { base: 'font-18', [media2Breakpoint]: 'font-22', ...{ [media3Breakpoint]: 'font-24' }},
                    fontFamily: "heading",
                    fontWeight: "normal",
                    lineHeight: 1.2
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    textColor: 'white'
                  },
                }),
            }}
            field={content}
          />
        </VStack>
        {hasValidItems ? <Box display={{ base: "none", md: "block" }}>{renderItems(items)}</Box> : null}
      </VStack>
    );
  };

  return (
    <BlockContainer
      paddingY="space-40"
    >
      <Grid
        w='full'
        gridTemplateRows={{ base: 'auto', md: '1fr auto' }}
        gridTemplateColumns={{ base: 'auto', md: 'repeat(2, 1fr)', ...{ [media2Breakpoint]: 'repeat(3, 1fr)' }, ...{ [media3Breakpoint]: 'repeat(4, 1fr)' }}}
        gridGap="space-32"
        alignItems="start"
      >
        <GridItem
          colSpan={{ base: 1, [media2Breakpoint]: 2, ...{ [media3Breakpoint]: 3 }}}
          alignSelf="stretch"
          bg="grey-0"
          borderRadius="lg"
          color="white"
          fontFamily="heading"
          fontSize="font-58"
          fontWeight="normal"
          textAlign="left"
          padding="space-32"
        >
          {renderBlock(items, content)}
        </GridItem>
        <GridItem rowSpan={{ base: 1, md: 2 }} minWidth={{ base: "full", md: `${formMinWidth}px` }}>
          {renderForm()}
        </GridItem>
        <GridItem>
          {renderImg(media1)}
        </GridItem>
        <GridItem display={{ base: "none", [media2Breakpoint]: "grid" }}>
          {renderImg(media2)}
        </GridItem>
        <GridItem display={{ base: "none", [media3Breakpoint]: "grid" }}>
          {renderImg(media3)}
        </GridItem>
        {hasValidItems ? <GridItem
          alignSelf="stretch"
          bg="grey-0"
          borderRadius="lg"
          color="white"
          fontFamily="heading"
          fontSize="font-58"
          fontWeight="normal"
          textAlign="left"
          padding="space-32"
          display={{ base: "block", md: "none" }}
        >
          {renderItems(items)}
        </GridItem> : null}
      </Grid>
    </BlockContainer>
  );
};

export default BlockEmbedHubspotForm;
