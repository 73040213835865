export const getMediaStyle = (index: number, sx: boolean) => {
  switch (index) {
    case 0:
      return sx ? "800px" : "100vh";

    case 2:
      return sx ? "350px" : "45vh";

    default:
      return 0;
  }
};

export const getSpeed = (index: number) => {
  switch (index) {
    case 0:
      return 0.08

    case 1:
      return 0.1;

    default:
      return 0.2;
  }
};
