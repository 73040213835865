import { Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { PrismicLink, ShiIcon } from "src/atoms";
import { ReactComponent as Rocket } from "src/assets/icons/shine-start-circle.svg";

const ShineStartButton: FC<{
  button_label: string;
  button_sublabel?: string;
  button_link: Gatsby.LinkFragment;
  hasIcon?: boolean;
}> = ({ button_label, button_sublabel, button_link, hasIcon }) => (
  <Button
    as={PrismicLink}
    link={button_link}
    bg="white"
    borderRadius="md"
    shadow="sm-light"
    color="text-primary"
    border="1px solid"
    borderColor="transparent"
    paddingY="16px !important"
    justifyContent="space-between"
    _hover={{
      color: "bg-primary-active",
      shadow: "sm-light-hover",
      borderColor: "bg-primary-active",
      ".arrow-icon": {
        transform: (theme) => `translateX(${theme.space[1]})`,
      },
    }}
    display="flex"
    padding="space-24"
  >
    <HStack>
      {hasIcon && (
        <Icon
          display={{ base: "none", sm: "initial" }}
          as={Rocket}
          fontSize="50px"
          marginRight="space-8"
        />
      )}

      <VStack
        alignItems="flex-start"
        spacing="space-4"
        textAlign="left"
        whiteSpace="break-spaces"
      >
        <Text as="span">{button_label}</Text>
        {button_sublabel && (
          <Text
            as="span"
            variant="body-small"
            fontWeight="normal"
            color="text-secondary"
          >
            {button_sublabel}
          </Text>
        )}
      </VStack>

      <ShiIcon
        className="arrow-icon"
        name="arrowicon"
        transition="transform .2s ease-out"
      />
    </HStack>
  </Button>
);

export default ShineStartButton;
