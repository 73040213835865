import { FC } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  ListItem,
  Heading,
  Text,
  chakra,
  Center,
  Grid
} from "@chakra-ui/react";
import Renderer from "src/lib/renderer";
import SwipeCarousel from "../BlockPlansPresentation/components/SwipeCarousel";
import ShiButton from "src/atoms/ShiButton";
import ButtonSection from "../BlockPlansPresentation/components/ButtonSection";
import { Link as GatsbyLink } from "gatsby";

const BlockCreationPackagePricing: FC<
  Gatsby.PageBlockCreationPackagePricingFragment
> = ({ primary, items }) => {
  if (!primary) throw Error();

  const {
    content,
    is_header,
    parent_section,
    usp_title,
    usp_one,
    usp_two,
    usp_three,
    card_grid_title,
    cta_link,
    show_scroll,
  } = primary;

  const renderUsp = (usp: string) => {
    if (!usp) return null;
    return (
      <ListItem
        as={Grid}
        gridTemplateColumns={{ base: "auto 1fr" , sm: "1fr"}}
        gridGap="space-16"
        alignItems="flex-start"
        flex="1"
        justifyContent={{ sm: "center" }}
        textAlign={{ base: "left", sm: "center" }}
      >
        <Center
          minW={{ base: "unset", sm: "full" }}
          mt={{ base: "space-4", sm: "0"}}
        >
          <ShiIcon name="check" fill="#5CA3FF" />
        </Center>
        <Text minW={{ base: "unset", sm: "full" }}>{usp}</Text>
      </ListItem>
    );
  };

  return (
    <Container
      size="full"
      paddingY={is_header ? "0" : { base: "space-40", md: "space-60" }}
    >
      <Box
        w="full"
        paddingX={{ base: "0", md: "space-58", "2xl": 0 }}
        maxWidth={{ md: "1440px", "2xl": "1434px" }}
      >
        {is_header && parent_section && (
          <Container
            w="full"
            display={{ base: "none", sm: "block" }}
            paddingX={{ base: "space-16", md: "0" }}
          >
            <ShiButton
              as={GatsbyLink}
              to={`?section=${parent_section}`}
              hasArrow={false}
              variant="lightGrey"
              color="text-secondary"
              sx={{
                ":hover": {
                  bg: "greyLight-3",
                  ".element-cta-icon": {
                    transform: (theme) => `translateX(-${theme.space[1]})`,
                  },
                },
              }}
            >
              <chakra.span
                transition="transform .2s ease-out"
                marginRight="space-8"
                className="element-cta-icon"
              >
                ←
              </chakra.span>
              Voir tous les packs
            </ShiButton>
          </Container>
        )}
        <BlockContainer
          paddingY="0"
          paddingTop={is_header ? { base: "space-40", md: "space-60" } : 0}
          paddingBottom={{ base: "space-24", md: "space-48" }}
        >
          <Box
            sx={{
              h2: {
                marginBottom: "space-16",
                fontWeight: "medium",
              },
              "p:last-of-type": {
                marginBottom: 0,
              },
              p: {
                fontFamily: "heading",
                fontSize: "font-20",
              },
              "@media (min-width: 40em)": {
                h2: {
                  fontSize: "font-40",
                },
              },
            }}
            textAlign="center"
            maxW="1000px"
          >
            <Renderer field={content} />
          </Box>
        </BlockContainer>

        <Box
          bg="greyLight-4"
          borderRadius={{ md: "lg" }}
          paddingX={{ md: "space-64" }}
          paddingBottom="space-40"
          sx={{
            "> *": {
              maxWidth: { md: "1000px" },
              mx: "auto",
            },
          }}
        >
          {/* Mobile USPs */}
          <Accordion
            allowToggle
            display={{ base: "block", sm: "none" }}
            pt="space-32"
            w="full"
          >
            <AccordionItem
              border="none"
              sx={{
                svg: {
                  path: {
                    fill: "text-light",
                  },
                },
              }}
            >
              <AccordionButton
                _hover={{ backgroundColor: "transparent" }}
                alignItems="flex-start"
                justifyContent="space-between"
                textAlign="left"
                pb="space-24"
                pt="0"
              >
                <Heading as="div" variant="subtitle" fontWeight="medium">
                  {usp_title}
                </Heading>
                <AccordionIcon ml="space-8" mt="space-4" />
              </AccordionButton>
              <AccordionPanel>
                <List spacing="space-16">
                  {usp_one && renderUsp(usp_one)}
                  {usp_two && renderUsp(usp_two)}
                  {usp_three && renderUsp(usp_three)}
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          {/* Desktop USPs */}

          <Container
            borderRadius="lg"
            display={{ base: "none", sm: "block" }}
            pt="space-32"
            pb="space-24"
            maxWidth={{ md: "880px" }}
          >
            <Heading
              as="div"
              variant="subtitle"
              fontWeight="medium"
              textAlign="center"
              pb="space-32"
              fontSize={{ base: "font-18", lg: "font-20" }}
            >
              {usp_title}
            </Heading>
            <HStack as={List} spacing="space-40" alignItems="flex-start">
              {usp_one && renderUsp(usp_one)}
              {usp_two && renderUsp(usp_two)}
              {usp_three && renderUsp(usp_three)}
            </HStack>
          </Container>

          <Flex
            justifyContent="center"
            alignItems="center"
            w="full"
            position="relative"
          >
            <Center
              bg="white"
              w="36px"
              h="36px"
              borderRadius="md"
              position="relative"
              zIndex="1"
            >
              <ShiIcon name="plus" w="16px" h="16px" />
            </Center>
            <Divider
              w="full"
              position="absolute"
              transform="translateY(-50%)"
              top="50%"
              left="0"
              variant="greyLight"
            />
          </Flex>
          <Box w="full">
            <Container
              paddingTop="space-32"
              paddingBottom="space-16"
              textAlign="center"
            >
              <Heading
                as="div"
                variant="subtitle"
                fontWeight="medium"
                fontSize={{ base: "font-18", lg: "font-20" }}
              >
                {card_grid_title}
              </Heading>
            </Container>
            <SwipeCarousel items={items} hasBackground/>
          </Box>
          <Center>
            <ButtonSection
              is_header={is_header}
              cta_link={cta_link}
              show_scroll={show_scroll}
              variant="secondary"
            />
          </Center>
        </Box>
      </Box>
    </Container>
  );
};

export default BlockCreationPackagePricing;
