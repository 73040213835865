import { Box, Flex } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";

import { FC } from "react";
import { BlockContainer } from "src/atoms";

import Renderer from "src/lib/renderer";
import { getBackgroundColor } from "./utils";

import { Element } from "@prismicio/react";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";

const BlockBanner: FC<Gatsby.PageBlockBannerFragment> = ({ primary }) => {
  if (!primary) throw Error();

  const { background_color, badge, button, content, image } = primary;

  const isPrimaryVariant = !!background_color && ["light grey", "light blue"].includes(background_color);

  const renderButton = () => {

    return (
      <Renderer
        field={button}
        overrides={{
          [Element.hyperlink]: (args) =>
            hyperlink({
              ...args,
              overrideProps: {
                variant: isPrimaryVariant ? "primary" : "lightGrey",
              },
            }),
        }}
      />
    );
  }

  return (
    <BlockContainer>
      <Flex
        bg={getBackgroundColor(background_color!)}
        borderRadius="lg"
        position="relative"
        padding={{ base: "space-24", md: "space-80" }}
        w="full"
        alignItems={{ md: "flex-end" }}
        justifyContent={{ md: "space-between" }}
        flexDir={{ base: "column", md: "row" }}
        sx={{
          ".chakra-text:last-of-type": {
            marginBottom: 0,
          },
        }}
      >
        {badge && (
          <Box
            position="absolute"
            top="-13px"
            left={{ base: "space-36", md: "space-80" }}
            sx={{
              ".chakra-badge": {
                textTransform: "uppercase",
              },
            }}
          >
            <Renderer field={badge} />
          </Box>
        )}
        <Box
          as={GatsbyImage}
          maxW={{ base: "170px", md: "260px" }}
          image={image?.document?.data?.image_file?.gatsbyImageData}
          alt={image?.document?.data?.image_file?.alt ?? ""}
          justifySelf="center"
          order={{ md: 1 }}
          alignSelf={{ base: "center", md: "flex-start" }}
          mt={{ md: "-165px" }}
        />
        <Box
          mt={{ base: "space-16", md: "0" }}
          textAlign="left"
          order={{ md: 0 }}
          maxW={{ md: "587px" }}
          w="full"
          sx={{
            ".chakra-text, a": {
              color:
                isPrimaryVariant
                  ? "text-primary"
                  : "greyLight-5",
            },
          }}
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    variant: "heading-3",
                  },
                }),
              [Element.heading3]: (args) =>
                heading3({
                  ...args,
                  overrideProps: {
                    variant: "heading-4",
                  },
                }),
            }}
          />
        </Box>
        <Box
          display={{ base: "block", md: "none" }}
          mt="space-16"
          sx={{
            ".chakra-link": {
              w: "full",
            },
          }}
        >
          {renderButton()}
        </Box>
        <Box display={{ base: "none", md: "block" }} order={2}>
          {renderButton()}
        </Box>
      </Flex>
    </BlockContainer>
  );
};

export default BlockBanner;
