import { Box, Container, Text, VStack, Grid, Flex } from "@chakra-ui/react";
import { FC, Fragment, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";
import { motionVariants } from "src/templates/Page/slices/PricingPlans/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import StickyCarousel from "./components/StickyCarousel";

import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";

const Mobile: FC<Gatsby.PageBlockPricingTableItemsFragment> = ({ items }) => {
  const [active, setActive] = useState(0);
  if (!items) return null;

  return (
    <Container size="full" display={{ base: "block", md: "none" }}>
      <StickyCarousel active={active} setActive={setActive} items={items} />
      {/* Table */}
      <Container>
        {items?.map((item, index) => {
          if (!item?.plan?.document?.data) return null;

          const { pricing_table } = item.plan.document.data;

          const isActive = active === index;

          return isActive ? (
            <AnimatePresence key={item?.plan?.document.id}>
              <motion.div
                initial="before"
                animate="in"
                exit="after"
                transition={{ ease: "easeOut", duration: 0.2 }}
                variants={motionVariants}
              >
                <VStack spacing="space-48" align="stretch">
                  {pricing_table?.document?.data?.body?.map((section) => {
                    if (!section?.primary) return null;
                    const { primary, items } = section;
                    return (
                      <Box key={section.id}>
                        <Text
                          color="text-light"
                          textTransform="uppercase"
                          fontSize="font-13"
                        >
                          {primary.title1}
                        </Text>
                        <VStack mt="space-32" spacing="space-8" align="stretch">
                          {items?.map((feature) => {
                            if (!feature) return null;
                            return (
                              <Grid
                                key={feature.feature_title}
                                gridTemplateColumns="2fr 1fr"
                                gap="space-16"
                                py="space-16"
                                borderBottom="1px solid"
                                borderBottomColor="greyLight-4"
                                w="full"
                              >
                                <VStack
                                  textAlign="left"
                                  spacing="space-4"
                                  align="stretch"
                                >
                                  <Text
                                    fontWeight="medium"
                                    color="text-secondary"
                                  >
                                    {feature.feature_title}
                                  </Text>
                                  {feature.feature_description && (
                                    <Text color="text-light" fontSize="font-14">
                                      {feature.feature_description}
                                    </Text>
                                  )}
                                  {feature.feature_badge && (
                                    <Renderer
                                      field={feature.feature_badge}
                                      overrides={{
                                        [Element.paragraph]: (args) =>
                                          paragraph({
                                            ...args,
                                            overrideProps: {
                                              sx: {
                                                ".chakra-badge": {
                                                  fontSize: "font-10",
                                                  textTransform: "upperCase",
                                                  paddingX: "8px !important",
                                                  bg: "green-1",
                                                  color: "green-0",
                                                },
                                                ".badge-blue": {
                                                  bg: "blue-3",
                                                  color: "blue-4",
                                                },
                                              },
                                            },
                                          }),
                                      }}
                                    />
                                  )}
                                </VStack>
                                <Flex
                                  justifyContent="center"
                                  alignItems="center"
                                  textAlign="center"
                                >
                                  {!feature.feature_included ? (
                                    <ShiIcon
                                      name="cross"
                                      fill="greyLight-1"
                                      w="12px"
                                      h="12px"
                                    />
                                  ) : (
                                    <Fragment>
                                      {feature.feature_icon?.gatsbyImageData ||
                                      (feature.feature_text?.text &&
                                        feature.feature_text?.text !== "") ? (
                                        <VStack
                                          spacing="space-4"
                                          align="stretch"
                                          sx={{
                                            p: {
                                              fontSize: "font-14",
                                              color: "text-primary",
                                            },
                                          }}
                                        >
                                          {feature.feature_icon
                                            ?.gatsbyImageData && (
                                            <GatsbyImage
                                              image={
                                                feature.feature_icon
                                                  ?.gatsbyImageData
                                              }
                                              alt={
                                                feature.feature_icon?.alt || ""
                                              }
                                              objectFit="contain"
                                            />
                                          )}
                                          {feature.feature_text && (
                                            <Renderer
                                              field={feature.feature_text}
                                            />
                                          )}
                                        </VStack>
                                      ) : (
                                        <ShiIcon
                                          name="check"
                                          w="12px"
                                          h="12px"
                                        />
                                      )}
                                    </Fragment>
                                  )}
                                </Flex>
                              </Grid>
                            );
                          })}
                        </VStack>
                      </Box>
                    );
                  })}
                </VStack>
              </motion.div>
            </AnimatePresence>
          ) : null;
        })}
      </Container>
    </Container>
  );
};

export default Mobile;
