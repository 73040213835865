import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";

import { FC } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";

import { Element } from "@prismicio/react";
import { heading2 } from "src/lib/renderer/elements/headings";

const BlockTestimonial: FC<Gatsby.PageBlockTestimonialFragment> = ({
  primary,
}) => {
  if (!primary) throw Error();

  const {
    content,
    author_picture,
    author_job,
    author_name,
    has_colored_background,
  } = primary;

  return (
    <BlockContainer>
      <Box
        bg={has_colored_background ? "greyLight-4" : "transparent"}
        borderRadius="lg"
        p={{
          base: has_colored_background ? "space-24" : 0,
          md: has_colored_background ? "space-80" : 0,
        }}
        w="full"
      >
        <Box maxWidth="750px" mx="auto">
          <Box>
            <ShiIcon
              name="quotationmark"
              boxSize="40px"
              marginBottom="space-4"
            />

            <Text as="div" sx={{ p: { color: "text-secondary" } }}>
              <Renderer
                overrides={{
                  [Element.heading2]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        variant: "heading-3",
                      },
                    }),
                }}
                field={content}
              />

              {author_name && (
                <Text as="div" color="text-secondary">
                  <Center flexDirection={{ base: "column", sm: "row" }}>
                    <Flex alignItems="center">
                      {author_picture?.document?.__typename ===
                        "PrismicElementImage" && (
                        <Box
                          as={GatsbyImage}
                          boxSize="40px"
                          borderRadius="full"
                          overflow="hidden"
                          isolation="isolate" //required to fix safari ui bug
                          image={
                            author_picture.document.data?.image_file
                              ?.gatsbyImageData
                          }
                          alt={
                            author_picture.document.data?.image_file?.alt ?? ""
                          }
                        />
                      )}

                      <Text as="span" marginLeft="space-8">
                        {author_name}
                      </Text>
                    </Flex>

                    <Text
                      as="span"
                      display={{ base: "none", sm: "initial" }}
                      marginX="space-8"
                    >
                      {"-"}
                    </Text>

                    {author_job}
                  </Center>
                </Text>
              )}
            </Text>
          </Box>
        </Box>
      </Box>
    </BlockContainer>
  );
};

export default BlockTestimonial;
