import { FC, Fragment } from "react";
import BlockContactCard from "./slices/BlockContactCard";
import BlockPressArticles from "./slices/BlockPressArticles";

const CollectionPresseBlocks: FC<Gatsby.CollectionPresseBlocksFragment> = ({
  data,
}) => {
  if (!data?.body) throw Error();

  return (
    <Fragment>
      {data.body.map((slice) => {
        if (!slice) return null;

        switch (slice.__typename) {
          case "PrismicCollectionPresseBlocksDataBodyBlockContactCard":
            return <BlockContactCard {...slice} />;
          case "PrismicCollectionPresseBlocksDataBodyBlockPressArticles":
            return <BlockPressArticles {...slice} />;
        }
      })}
    </Fragment>
  );
};

export default CollectionPresseBlocks;
