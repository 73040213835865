import { Box, VStack, Stack, Container } from "@chakra-ui/react";

import { FC } from "react";
import Renderer from "src/lib/renderer";

import { BlockContainer, PrismicLink } from "src/atoms";

import { ClickInfo } from "src/lib/event/types";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import { onClickNewAccount } from "src/lib/event";
import ShiButton from "src/atoms/ShiButton";
import Card from "./atoms/Card";
import Carousel from "./components/Carousel";

const BlockTrioOffer: FC<
  Gatsby.PageBlockTrioOfferFragment
> = ({ primary, items }) => {
  const visitorContext = useVisitorContext();
  const location = useLocation();

  if (!primary) throw Error();

  const { content, button_label, button_link } = primary;

  const hasButtonLabel = !!button_label;
  const hasButtonLink = !!button_link && button_link.link_type !== "Any";
  const buttonProps = hasButtonLink ? {
    as: PrismicLink,
    link: button_link
  } : {
    onClick: (e) => {
      const clickInfo: ClickInfo = {
        label: button_label,
        block: 'block_plans_presentation',
        fromLocation: location
      };

      onClickNewAccount(e, visitorContext, clickInfo)
    }
  };

  return (
    <BlockContainer>
      <VStack spacing={{ base: "space-24", md: "space-40" }}>
        <Container variant="heading">
          <VStack
            spacing="space-16"
            sx={{
              "h2": {
                fontSize: { base: "font-35", md: "font-50" }
              },
              "h2, h3": {
                marginBottom: 0,
              },
              "h2, h3, p": {
                color: "greyLight-3",
              },
            }}
          >
            <Renderer field={content} />
          </VStack>
        </Container>

        {items && (
          <Carousel
            items={items}
            primary={primary}
            display={{ base: "flex", lg: "none" }}
          />
        )}

        {items && (
          <Stack
            direction={{ base: "column", md: "row" }}
            w="full"
            spacing={{ base: "space-16", md: "space-32" }}
            display={{ base: "none", lg: "flex" }}
          >
            {items?.map((item) => {
              if (!item) return null;

              return (
                <Card key={item.title} item={item} />
              );
            })}
          </Stack>
        )}

        <Box w="full">
          {hasButtonLabel ? <Box paddingX="space-16">
            <ShiButton
              variant="yellow"
              hasArrow={true}
              marginBottom={{ base: "space-16", md: "space-24" }}
              marginTop={0}
              {...buttonProps}
            >
              {button_label}
            </ShiButton>
          </Box> : null}
        </Box>
      </VStack>
    </BlockContainer>
  );
};

export default BlockTrioOffer;
