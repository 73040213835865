import { Box, VStack } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { BlockContainer } from "src/atoms";

import { Element } from "@prismicio/react";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import { isValidElement } from "react";
import Renderer from "src/lib/renderer";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";



const BlockCta: FC<Gatsby.PageBlockCtaFragment> = ({ primary }) => {
  if (!primary) throw Error();

  const { buttons, content, has_colored_background, media, media_position } =
    primary;

  return (
    <BlockContainer>
      <VStack
        bg={has_colored_background ? "greyLight-3" : "transparent"}
        borderRadius="lg"
        p={{
          base: has_colored_background ? "space-24" : 0,
          md: has_colored_background ? "space-80" : 0,
        }}
        w="full"
        spacing={{ base: "space-24", md: "space-40" }}
      >
        {media_position === "top" &&
          media?.document?.__typename === "PrismicElementImage" && (
            <Box
              as={GatsbyImage}
              image={media.document.data?.image_file?.gatsbyImageData}
              alt={media.document.data?.image_file?.alt ?? ""}
              maxW="218px"
            />
          )}
        <VStack
          direction="column"
          align="center"
          paddingX={{ md: has_colored_background ? 0 : "135px"}}
          textAlign="center"
          spacing={{ base: "space-16", md: "space-8" }}
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    variant: "subtitle",
                    fontSize: { base: "font-20", lg: "font-24"}
                  },
                }),
            }}
          />
        </VStack>

        {media_position !== "top" &&
          media?.document?.__typename === "PrismicElementImage" && (
            <Box
              as={GatsbyImage}
              image={media.document.data?.image_file?.gatsbyImageData}
              alt={media.document.data?.image_file?.alt ?? ""}
              maxW="720px"
              borderRadius="lg"
              sx={{
                img: {
                  borderRadius: "lg",
                },
              }}
            />
          )}

        <Renderer
          overrides={{
            [Element.hyperlink]: (args) => {
              const isSecondaryButton = args?.children.some(
                (child) =>
                  isValidElement(child) &&
                  child.props.className === "button-secondary",
              );

              return hyperlink({
                ...args,
                hasArrow: isSecondaryButton ? true : undefined,
                overrideProps: {},
              });
            },
          }}
          field={buttons}
        />
      </VStack>
    </BlockContainer>
  );
};

export default BlockCta;
