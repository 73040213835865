import { FC } from "react";
import { BlockContainer } from "src/atoms";
import { Box } from "@chakra-ui/react";
import Renderer from "src/lib/renderer";

const BLOCK_OFFSET = 600;

const HeaderSpotlight: FC<Gatsby.PageHeaderSpotlightFragment> = ({
  primary,
}) => {
  if (!primary) return null;

  const { content, background_image } = primary;

  return (
    <Box
      width="100%"
      className="cece"
    >
      <BlockContainer
        className="ceece"
        wrapperContainerProps={{
          paddingTop: "space-16",
          variant: "with-background",
          paddingBottom: `${BLOCK_OFFSET}px`,
          marginBottom: `-${BLOCK_OFFSET}px`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundSize: { base: "100%", md: "90%", lg: "80%", xl: "40%" },
          backgroundImage:
            background_image?.document?.__typename === "PrismicElementImage"
              ? `url(${background_image.document.data?.image_file?.url})`
              : undefined,
        }}
      />

      <BlockContainer
        position="relative"
        paddingTop={{ base: 0, md: 0 }}
        maxWidth={{ md: "1248px" }}
      >
        <Box
          width="100%"
          sx={{
            ".chakra-button": {
              margin: "space-8",
            },
          }}
        >
          <Renderer field={content} />
        </Box>
      </BlockContainer>
    </Box>
  );
};

export default HeaderSpotlight;
