import { Flex, VStack, Stack, Box, Text, Icon } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { BlockContainer } from "src/atoms";
import { NAVBAR_HEIGHT } from "src/slices/Header";
import Renderer from "src/lib/renderer";
import { GatsbyImage } from "gatsby-plugin-image";
import ShineStartButton from "../../components/ShineStartButton";
import { ReactComponent as Rocket } from "src/assets/icons/shine-start-circle.svg";

const PageShineStart: FC<Gatsby.PageShineStartFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content, main_image } = primary;

  return (
    <BlockContainer
      marginTop={`${NAVBAR_HEIGHT + 48}px`}
      paddingX="space-40"
      backgroundColor="orange-6"
    >
      <Flex alignItems="flex-end">
        <VStack
          align="flex-start"
          textAlign="left"
          marginBottom="space-48"
          spacing="space-40"
        >
          <Renderer
            field={content}
            overrides={{
              span: (args) =>
                args.content.split("🚀").map((span, index) => (
                  <Fragment key={span}>
                    {index !== 0 && (
                      <Icon
                        as={Rocket}
                        fontSize="90px"
                        verticalAlign="bottom"
                        marginRight="-space-8"
                      />
                    )}
                    <Text as="span">{span}</Text>
                  </Fragment>
                )),
            }}
          />

          <Stack direction={{ base: "column", md: "row" }}>
            {items.map((button) => {
              if (!button) return null;

              const { button_link, button_label } = button;

              if (!button_link || !button_label) return null;

              return (
                <ShineStartButton
                  key={button.button_label}
                  button_label={button_label}
                  button_link={button_link}
                />
              );
            })}
          </Stack>
        </VStack>

        <Box
          display={{ base: "none", lg: "initial" }}
          minWidth={{ base: "340px", lg: "420px" }}
          marginBottom="-space-48"
          marginRight="-space-48"
          marginLeft="space-48"
        >
          {main_image?.document?.data && (
            <GatsbyImage
              image={main_image.document.data?.image_file?.gatsbyImageData}
              alt={main_image.document.data?.image_file?.alt ?? ""}
            />
          )}
        </Box>
      </Flex>
    </BlockContainer>
  );
};

export default PageShineStart;
