import {
  Box,
  Stack,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Dispatch, FC, Fragment, SetStateAction } from "react";
import { InView } from "react-intersection-observer";

import ShiButton from "src/atoms/ShiButton";
import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import { onClickNewAccount } from "src/lib/event";
import Renderer from "src/lib/renderer";
import { CardPrice } from "src/slices/BlockPlansPresentation/components/PlanCard/styles";
import { ClickInfo } from "src/lib/event/types";
import { getSubtitleText } from "src/lib/event/properties";

const StickyCarousel: FC<
  Gatsby.PageBlockPricingTableItemsFragment & {
    active?: number;
    setActive: Dispatch<SetStateAction<number>>;
  }
> = ({ items, active, setActive }) => {
  const visitorContext = useVisitorContext();
  const location = useLocation();

  return (
    <Fragment>
      <Box
        position="sticky"
        top="0"
        w="full"
        bg="white"
        py="space-20"
        zIndex="20"
      >
        <Stack
          overflowX="scroll"
          overflowY="hidden"
          whiteSpace="nowrap"
          px="space-20"
          pb="space-16"
          maxW="full"
          direction="row"
          spacing={{ base: "space-8" }}
          sx={{
            scrollSnapType: "x mandatory",
            WebkitOverflowScrolling: "touch",
            "::-webkit-scrollbar": {
              display: "none",
            },
            ".slide": {
              scrollSnapAlign: "center",
            },
          }}
        >
          {items?.map((item, index) => {
            if (!item?.plan) return null;
            const {
              plan,
              override_mobile_tarif_subtitle,
              override_mobile_title,
              plan_description,
              cta_label
            } = item;

            const tarif = plan.document?.data?.tarif;
            const title = override_mobile_title
              ? override_mobile_title
              : plan?.document?.data?.title;
            const tarifSubTitle =
              override_mobile_tarif_subtitle &&
              override_mobile_tarif_subtitle?.text &&
              override_mobile_tarif_subtitle?.text !== ""
                ? override_mobile_tarif_subtitle
                : plan?.document?.data?.tarif_subtitle;
            return (
              <InView
                threshold={1}
                onChange={(inView: boolean) => {
                  if (inView) {
                    setActive(index);
                  }
                }}
                key={"mobile-" + plan?.document?.id || ""}
              >
                <Box
                  minW="calc(100vw - 72px)"
                  className="slide"
                  bg={active === index ? "greyLight-3" : "white"}
                  border="1px solid"
                  borderColor="greyLight-3"
                  borderRadius="lg"
                  transitionProperty="all"
                  transitionDuration="300ms"
                  transitionTimingFunction="ease-out"
                  p="space-16"
                  h="full"
                >
                  <HStack>
                    <Text fontWeight="medium">{title}</Text>
                    <Spacer />
                    {!plan_description && (
                      <Box>
                        <CardPrice
                          price={tarif || 0}
                          priceUnit="€"
                        />
                        <Box
                          display="inline-block"
                          ml="space-8"
                          sx={{
                            p: {
                              fontSize: "font-12",
                            },
                            "p:last-of-type": {
                              marginBottom: 0,
                            },
                            em: {
                              textDecoration: "line-through",
                              fontStyle: "normal",
                            },
                          }}
                        >
                          <Renderer field={tarifSubTitle} />
                        </Box>
                      </Box>
                    )}
                  </HStack>
                  {plan_description && (
                    <VStack
                      justifyContent="flex-start"
                      align="stretch"
                      spacing="space-4"
                    >
                      <Box>
                        <CardPrice
                          price={tarif || 0}
                          priceUnit="€"
                        />
                        <Box
                          display="inline-block"
                          ml="space-8"
                          sx={{
                            p: {
                              fontSize: "font-12",
                            },
                            "p:last-of-type": {
                              marginBottom: 0,
                            },
                            em: {
                              textDecoration: "line-through",
                              fontStyle: "normal",
                            },
                          }}
                        >
                          <Renderer field={tarifSubTitle} />
                        </Box>
                      </Box>

                      <Text fontSize="font-12" color="text-secondary">
                        {plan_description}
                      </Text>
                    </VStack>
                  )}
                  <ShiButton
                    onClick={(e) => {
                      const tarifSubtitleText = tarifSubTitle?.text ?? getSubtitleText(tarifSubTitle);
                      const enhancedTitle = [title, plan_description].filter((s) => !!s).join(' ');

                      const clickInfo: ClickInfo = {
                        label: cta_label,
                        price: tarif,
                        platform: 'web-mobile',
                        title: enhancedTitle,
                        block: 'block_pricing_table',
                        subtitle: tarifSubtitleText,
                        fromLocation: location
                      };

                      onClickNewAccount(e, visitorContext, clickInfo)
                    }}
                    hasArrow={false}
                    mt={"space-16"}
                    w={{
                      base: "full",
                    }}
                  >
                    {cta_label}
                  </ShiButton>
                </Box>
              </InView>
            );
          })}
        </Stack>
        <HStack spacing="space-4" justifyContent="center">
          {items?.map((item, index) => {
            if (!item?.plan) return null;

            return (
              <Box
                w="6px"
                h="6px"
                borderRadius="full"
                bg={index === active ? "greyLight-0" : "greyLight-3"}
                key={"mobile-index-" + item.plan.document?.id || ""}
                transitionProperty="all"
                transitionDuration="300ms"
                transitionTimingFunction="ease-out"
              />
            );
          })}
        </HStack>
      </Box>
    </Fragment>
  );
};

export default StickyCarousel;
