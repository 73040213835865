import { Box, Th, Td, BoxProps } from "@chakra-ui/react";
import { FC } from "react";

const TableCell: FC<
  BoxProps & {
    isHeadRow?: boolean;
  }
> = ({ isHeadRow, children, ...props }) => {
  return (
    <Box
      as={isHeadRow ? Th : Td}
      fontSize="font-14"
      textAlign="center"
      paddingX="space-16"
      paddingY="space-16"
      borderWidth="1px"
      borderColor="grey-3"
      sx={{
        // Necessary to space all elements, excepted paragraphs.
        // ¯\_(ツ)_/¯
        "> :not(.chakra-text) + *": {
          marginTop: "space-8",
        },
        "> *": {
          marginX: "auto",
        },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default TableCell;
