import Renderer from "src/lib/renderer";
import { Box, Wrap, Container } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer } from "src/atoms";
import Carousel from "src/atoms/rebrand/Carousel";
import ArticleCard from "./components/ArticleCard";

const BlockArticlesCarousel: FC<Gatsby.PageBlockArticlesCarouselFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content, isGrid } = primary;

  return (
    <BlockContainer>
      <Container variant="heading">
        <VStack
          textAlign="center"
          spacing="space-24"
          sx={{
            h2: {
              marginBottom: 0,
            },
          }}
        >
          <Renderer field={content} />
        </VStack>
      </Container>
      <Box maxWidth={{ md: "1176px" }} mx="auto">
        {isGrid ? (
          <Wrap
            align="stretch"
            justify="center"
            spacing="space-32"
            marginTop="space-48"
            shouldWrapChildren
          >
            {items.map(
              (item) =>
                item && (
                  <ArticleCard
                    {...item}
                    key={item.title}
                    w={{
                      base: "full",
                      md: "360px",
                    }}
                    height="100%"
                  />
                ),
            )}
          </Wrap>
        ) : (
          <Box paddingTop="0">
            <VStack spacing="space-12" alignItems="center">
              <Carousel
                carouselWrapperProps={{
                  paddingTop: "space-48",
                }}
                items={items.map(
                  (item) =>
                    item && (
                      <ArticleCard {...item} key={item.title} maxW="360px" />
                    ),
                )}
              />
            </VStack>
          </Box>
        )}
      </Box>
    </BlockContainer>
  );
};

export default BlockArticlesCarousel;
