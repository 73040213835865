export const OVERRIDE_CSS = `
@font-face {
  font-family: "Mabry Pro";
  src: local("MabryPro-Light"),
    url("${process.env.GATSBY_HOST}/fonts/Mabry-Pro-Light.woff2") format("woff2"),
    url("${process.env.GATSBY_HOST}/fonts/Mabry-Pro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.hs-form-field label:not(.hs-error-msg) {
  font-family: "Mabry Pro" !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: rgba(85, 82, 73, 1) !important;
}

.hs-main-font-element {
  font-family: "Mabry Pro" !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #DC4C56 !important;
}

.hs-richtext {
  font-family: "Mabry Pro" !important;
  color: rgba(85, 82, 73, 1) !important;
}

.hs-error-msg {
  display: none;
}

.hs-error-msgs {
  display: none !important;
}

.hs-form-required {
  color: #DC4C56 !important;
}

div:has(+.hs-error-msgs) input {
  border-color: #DC4C56 !important;
  color: #DC4C56 !important;
}

div:has(+.hs-error-msgs) span {
  color: #DC4C56 !important;
}

.hs-input:not([type=file]){
  background-color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(240, 239, 228, 1);
  border-radius: 32px !important;
  font-family: "Mabry Pro" !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: rgba(127, 123, 113, 1);
}

.hs-input:not([type=file]):focus{
  color: rgba(37, 36, 29, 1);
  border-color: rgba(37, 36, 29, 1);
}

.hs-input {
  color: rgba(37, 36, 29, 1);
}

.input {
  margin-right: 0px !important;
}

.hs-button {
  border-radius: 64px !important;
  min-width: 100%; !important;
  font-family: "Mabry Pro" !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 16.5px !important;
  color: white !important;
}

select {
  -webkit-appearance: none;
  border: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22currentColor%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}
`;
