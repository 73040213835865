const BACKGROUND_COLOR = {
  RED: "pink-0",
  BLUE: "blue-0",
  LIGHTGREY: "greyLight-3",
  DARKGREY: "grey-0",
  WHITE: "white"
};

export const getBackgroundColor = (backgroundColor: string | null) => {
  switch (backgroundColor) {
    case "blue":
      return BACKGROUND_COLOR.BLUE;

    case "light grey":
      return BACKGROUND_COLOR.LIGHTGREY;

    case "red":
      return BACKGROUND_COLOR.RED;

    case "white":
      return BACKGROUND_COLOR.WHITE;

    default:
      return BACKGROUND_COLOR.DARKGREY;
  }
};
