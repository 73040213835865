import {
  AspectRatio,
  Badge,
  Box,
  Center,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import type { FC } from "react";
import { Card, ShiIcon } from "src/atoms";
import { logEvent } from "src/lib/analytics";
import { useLocation } from "@reach/router";
import { buildPageProperties } from "src/lib/event/properties";

const OPEN_ACCOUNT_SECTION_ID = "ouvrir-un-compte";

const ToolboxOfferCard: FC<{ offer: Gatsby.PrismicToolboxOfferDataType }> = ({
  offer,
}) => {
  const location = useLocation();
  const partner = offer.partner?.document?.data as
    | Gatsby.PrismicToolboxPartnerDataType
    | undefined;

  const badge = offer.perk_badge?.text;
  const catchline = partner?.catchline?.text;
  const logoUrl = partner?.logo?.url;
  const partnerName = partner?.name?.text;

  const onClick = () => {
    const partnerNameObj = partnerName ? { partnerName } : {};
    const event = {
      name: "Toolbox Partner Clicked",
      properties: {
        ...partnerNameObj,
        ...buildPageProperties(location)
      }
    }

    logEvent(event);
    document
      ?.getElementById(OPEN_ACCOUNT_SECTION_ID)
      ?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Card
      padding="0"
      height="100%"
      cursor="pointer"
      onClick={onClick}
    >
      <Flex direction="column" flexGrow={1} height="100%">
        <AspectRatio ratio={5 / 2}>
          <Box
            background={`url(${offer.cover?.url}) no-repeat center center`}
            backgroundSize="cover"
            borderTopRadius="8px"
          />
        </AspectRatio>

        <Flex direction="column" flexGrow={1} padding="5">
          <Box pb="space-8">
            <Text fontWeight="500">{offer.title?.text}</Text>
          </Box>

          <Flex pb={partner ? "space-16" : 0}>
            {badge ? (
              <Box lineHeight={1} overflowWrap="break-word">
                <Badge
                  color="lightBlue-0"
                  backgroundColor="lightBlue-6"
                  label={badge}
                  size="-2"
                  padding="4px 8px"
                  textTransform="none"
                  title={badge}
                >
                  <Center display="flex">
                    <ShiIcon name="gift" boxSize="15px" marginRight="space-8" />
                    <Text noOfLines={1}>{badge}</Text>
                  </Center>
                </Badge>
              </Box>
            ) : null}
          </Flex>

          <Box flexGrow={1} />

          {partner ? (
            <Stack alignItems="center" direction="row" spacing="space-16">
              {logoUrl ? (
                <Image
                  alt={`${partnerName} logo`}
                  boxSize="40px"
                  objectFit="cover"
                  rounded="full"
                  src={logoUrl}
                />
              ) : null}

              <Stack spacing="0">
                <Text fontWeight="500" noOfLines={1}>
                  {partnerName}
                </Text>

                <Text
                  title={catchline}
                  fontSize="12"
                  type="secondary"
                  color="grey-1"
                  lineHeight="15px"
                  noOfLines={1}
                  paddingTop="1"
                >
                  {catchline}
                </Text>
              </Stack>
            </Stack>
          ) : null}
        </Flex>
      </Flex>
    </Card>
  );
};

export default ToolboxOfferCard;
