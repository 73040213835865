import { VStack, StackProps, Box, Tag, Heading } from "@chakra-ui/react";
import { FC } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import Renderer from "src/lib/renderer";
import { Element } from "@prismicio/react";
import { asText } from "@prismicio/client";
import { span } from "src/lib/renderer/elements/typography";

const Card: FC<
  StackProps & { item: Gatsby.PageBlockTrioOfferItemFragment }
> = ({ item }) => {
  if (!item) return null;

  const hasTag = !!asText(item?.tag?.raw);

  return (
    <VStack
      overflow="hidden"
      spacing={{ base: "space-24", md: "space-32" }}
      key={item.title || ""}
      w="full"
      h={{ base: "full", md: "inherit" }}
      sx={{
        "h2, h3, p": {
          color: "greyLight-3",
        },
      }}
    >
      <Box w="full">
        <Box borderRadius="lg"
          as={GatsbyImage}
          image={
            item.image?.document?.data?.image_file?.gatsbyImageData
          }
          alt={item.image?.document?.data?.image_file?.alt ?? ""}
          width="full"
        />
      </Box>
      <Box
        paddingTop={{ base: "0", md: "0" }}
        textAlign="left"
        sx={{
          p: {
            lineHeight: "base",
            _last: {
              marginBottom: 0,
            },
          },
        }}
      >
        <VStack alignItems="start" marginBottom="space-32">
          <Heading
            width={{
              base: "75%",
              md: "55%",
              lg: "75%",
              '2xl': "55%"
            }}
            color='greyLight-3'
            as="div"
            variant="heading-3"
            marginBottom="space-8"
          >
            {item.title}
          </Heading>
          {hasTag ? <Tag
            border='1px'
            borderColor='greyLight-3'
            bg='transparent'
            borderRadius='full'
            paddingX="space-16"
            paddingY="space-2"
          >
            <Renderer
              overrides={{
                [Element.span]: (args) =>
                  span({
                    ...args,
                    overrideProps: {
                      fontSize: "font-12",
                      _last: {
                        mb: 0,
                      },
                    },
                  }),
              }}
              field={item.tag}
            />
          </Tag> : null}
        </VStack>
        <VStack
          marginBottom={{ base: "space-32", md: "0" }}
          width={{ base: "80%", md: "100%" }}
        >
          <Renderer
            overrides={{
              [Element.span]: (args) =>
                span({
                  ...args,
                  overrideProps: {
                    fontFamily: "heading",
                    _last: {
                      mb: 0,
                    },
                  },
                }),
            }}
            field={item.content}
          />
        </VStack>
      </Box>
    </VStack>
  )
};

export default Card;
