import { Box, Divider } from "@chakra-ui/react";
import { Element } from "@prismicio/react";
import { FC, Fragment } from "react";
import { Link } from "src/atoms/PrismicLink";
import headingToAnchorLink from "src/lib/headingToAnchorLink";
import Renderer from "src/lib/renderer";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { listItem } from "src/lib/renderer/elements/list";
import paragraph from "src/lib/renderer/elements/paragraph";
import BlockQuote from "./serializers/BlockQuote";
import createSummary from "./utils/createSummary";

const BlockRichContent: FC<Gatsby.PageBlockRichContentFragment> = ({
  primary,
}) => {
  if (!primary) throw Error();

  const { content, has_summary } = primary;

  return (
    <Box maxWidth="702px" paddingX="space-16" fontWeight="light">
      {has_summary && (
        <Fragment>
          <Box marginBottom="space-24">
            {createSummary(content?.raw ?? content)?.map(
              ({ text, anchor, type }) => (
                <Box
                  as={Link}
                  display="block"
                  variant="blue"
                  to={`#${anchor}`}
                  key={anchor}
                  fontSize={type === "heading2" ? "font-22" : "font-16"}
                  marginTop={type === "heading2" ? "space-20" : "space-8"}
                  fontWeight={type === "heading2" ? "medium" : "normal"}
                >
                  {text}
                </Box>
              ),
            )}
          </Box>

          <Divider variant="dots" marginX="auto" />
        </Fragment>
      )}
      <Renderer
        field={content}
        overrides={{
          [Element.heading2]: (args) =>
            heading2({
              ...args,
              overrideProps: {
                variant: "heading-blog",
                id: headingToAnchorLink(args.element?.text),
              },
            }),

          [Element.heading3]: (args) =>
            heading3({
              ...args,
              overrideProps: {
                fontSize: "font-22",
                id: headingToAnchorLink(args.element?.text),
              },
            }),

          [Element.paragraph]: (args) =>
            paragraph({
              ...args,
              overrideProps: {
                marginBottom: "space-24",
                color: "text-primary",
              },
            }),

          [Element.listItem]: (args) =>
            listItem({
              ...args,
              overrideProps: {
                marginBottom: "space-12",
                color: "text-primary",
              },
            }),

          [Element.oListItem]: (args) =>
            listItem({
              ...args,
              overrideProps: {
                marginBottom: "space-12",
                color: "text-primary",
              },
            }),

          [Element.preformatted]: BlockQuote,
        }}
      />
    </Box>
  );
};

export default BlockRichContent;
