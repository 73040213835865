import Renderer from "src/lib/renderer";
import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { Text, VStack, Heading } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { Link } from "src/atoms/PrismicLink";

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type CarouselArticle = ArrayElement<
  Exclude<Gatsby.PageBlockArticlesCarouselFragment["items"], undefined>
>;

const ArticleCard: FC<BoxProps & CarouselArticle> = ({
  title,
  url,
  image,
  content,
  ...props
}) => {
  const hasImage = image?.document?.__typename === "PrismicElementImage";
  return (
    <Flex
      as={Link}
      role="group"
      key={title}
      href={url}
      padding="0"
      overflow="hidden"
      isolation="isolate" //required to fix safari ui bug
      borderRadius="lg"
      flexWrap="wrap"
      {...props}
    >
      {hasImage ? (
        <Box height="180px">
          <Box
            as={GatsbyImage}
            height="100%"
            _groupHover={{
              transform: "scale(1.01)",
            }}
            transitionProperty="all"
            transitionDuration="400ms"
            transitionTimingFunction="ease-out"
            image={image.document.data?.image_file?.gatsbyImageData}
            alt={image.document.data?.image_file?.alt ?? ""}
          />
        </Box>
      ) : null}

      <VStack
        bg="greyLight-4"
        padding="space-32"
        spacing="space-8"
        alignItems="stretch"
        textAlign="left"
        h={hasImage ? "calc(100% - 180px)" : "full"}
        w="full"
      >
        <Heading as="h3" variant="heading-4">
          {title}
        </Heading>

        <Text
          as="div"
          sx={{
            ".chakra-text": {
              fontSize: "font-16",
              marginBottom: "space-0",
            },
          }}
        >
          <Renderer field={content} />
        </Text>
      </VStack>
    </Flex>
  );
};

export default ArticleCard;
