import { Box, VStack, Stack, Container } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import ElementAnimation from "src/components/elements/ElementAnimation";
import Renderer from "src/lib/renderer";

import { getItemBackground } from "./utils";
import { getSizes } from "src/lib/image";

const BlockTrioProductHighlight: FC<
  Gatsby.PageBlockTrioProductHighlightFragment
> = ({ primary, items }) => {
  if (!primary) throw Error();

  const { content, color } = primary;
  const sizes = getSizes('md', items?.length ?? 0);

  return (
    <BlockContainer>
      <VStack spacing={{ base: "space-16", md: "space-48" }} w="full">
        <Container variant="heading">
          <VStack
            spacing={{ base: "space-16", md: "space-24" }}
            sx={{
              h2: {
                marginBottom: 0,
              },
            }}
          >
            <Renderer field={content} />
          </VStack>
        </Container>
        {items && (
          <Stack
            direction={{ base: "column", md: "row" }}
            w="full"
            spacing={{ base: "space-16", md: "space-32" }}
          >
            {items?.map((item, index) => {
              if (!item) return null;

              return (
                <VStack
                  borderRadius="lg"
                  overflow="hidden"
                  spacing={{ base: "space-24", md: "space-32" }}
                  key={item.media?.id || ""}
                  bg={getItemBackground(color!, index)}
                  w="full"
                  h={{ base: "full", md: "inherit" }}
                >
                  <Box w="full">
                    {item.media?.document?.__typename ===
                      "PrismicElementImage" && (
                      <Box
                        as={GatsbyImage}
                        image={
                          item.media.document.data?.image_file?.gatsbyImageData
                        }
                        alt={item.media.document.data?.image_file?.alt ?? ""}
                        sizes={sizes}
                        width="full"
                      />
                    )}

                    {item.media?.document?.__typename ===
                      "PrismicElementAnimation" && (
                      <ElementAnimation data={item.media.document.data} />
                    )}
                  </Box>
                  <Box
                    padding={{ base: "space-24", md: "space-32" }}
                    paddingTop={{ base: "0", md: "0" }}
                    textAlign="left"
                    sx={{
                      p: {
                        lineHeight: "base",
                        fontSize: "font-16",
                        _last: {
                          marginBottom: 0,
                        },
                      },
                    }}
                  >
                    <Renderer field={item.rich_description} />
                  </Box>
                </VStack>
              );
            })}
          </Stack>
        )}
      </VStack>
    </BlockContainer>
  );
};

export default BlockTrioProductHighlight;
