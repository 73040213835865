import { FC, useRef, useEffect } from "react";
import { BlockContainer } from "src/atoms";
import { Container, Box, Stack, Center } from "@chakra-ui/react";
import Renderer from "src/lib/renderer";

import { Link as GatsbyLink } from "gatsby";

import SwipeCarousel from "./components/SwipeCarousel";
import ButtonSection from "./components/ButtonSection";

const BlockPlansPresentation: FC<
  Gatsby.PageBlockPlansPresentationFragment & { section?: string }
> = ({ primary, items, section }) => {
  if (!primary) throw Error();

  const {
    content,
    is_header,
    is_large: isLarge,
    announcement,
    cta_link,
    show_scroll
  } = primary;

  const tabRef = useRef<HTMLDivElement>(null);

  const tabs = new Array(3).fill().map((_, i) => {
    const url = primary[`tab_section_${i + 1}`];
    const label = primary[`tab_label_${i + 1}`];
    return {
      url,
      label,
    };
  });

  let activeTab =
    section && tabs.findIndex((tab) => tab?.url?.includes(section!));

  if (!activeTab || activeTab < 0) {
    activeTab = 0;
  }

  useEffect(() => {
    if (tabRef.current) {
      const childNodes = Array.from(tabRef?.current?.childNodes);

      if (childNodes) {
        let position = 0;
        const positions = childNodes?.reduce(
          (previous: { width?: number; position?: number }[], curr: any) => {
            const arr = [
              ...previous,
              { width: curr.clientWidth, position: position },
            ];
            position = position + curr.clientWidth;
            return arr;
          },
          [],
        );

        const activeTabPosition = positions[activeTab];

        if (activeTabPosition.width && activeTabPosition.position) {
          if (
            activeTabPosition.width + activeTabPosition?.position >=
            window.innerWidth
          ) {
            tabRef.current.scrollLeft = activeTabPosition?.position;
          }
        }
      }
    }
  }, []);

  const boxSxAdditionalProps = isLarge ? {
    textAlign: "left",
    w: "full"
  } : {
    textAlign: "center",
    maxW: { md: "1000px" },
    mx: "auto"
  };
  
  const boxSxContentAdditionalProps = isLarge ? {
    h2: {
      marginBottom: "space-8",
    }
  } : {
    h2: {
      marginBottom: "space-12",
      fontWeight: "medium",
    },
    "@media (min-width: 40em)": {
      h2: {
        fontSize: "font-40",
        fontWeight: "medium",
      },
    },
  };

  return (
    <Box
      paddingY={is_header ? "0" : { base: "space-40", md: "space-60" }}
      paddingBottom={{ base: "space-40", md: "space-60" }}
      w="full"
    >
      <BlockContainer paddingY="0">
        <Box
          sx={{
            ...boxSxContentAdditionalProps,
            "p:last-of-type": {
              marginBottom: 0,
            },
            p: {
              fontFamily: "heading",
              fontSize: "font-20",
            },
          }}
          marginBottom={!is_header ? "space-32" : 0}
          {...boxSxAdditionalProps}
        >
          <Renderer field={content} />
        </Box>
      </BlockContainer>
      {is_header && tabs.length > 0 && (
        <Container size="full">
          <Stack
            ref={tabRef}
            overflowX="scroll"
            overflowY="hidden"
            whiteSpace="nowrap"
            paddingX="space-20"
            marginY="space-40"
            maxW="full"
            direction="row"
            spacing="0"
            sx={{
              scrollSnapType: "x mandatory",
              WebkitOverflowScrolling: "touch",
              "::-webkit-scrollbar": {
                display: "none",
              },
              ".slide": {
                scrollSnapAlign: "center",
              },
            }}
          >
            {tabs.map((tab, index) => {
              const isFirst = index === 0;
              const isLast = index === tabs.length - 1;
              const active = activeTab === index;

              return (
                <Box
                  key={tab.label}
                  background="greyLight-5"
                  border="1px solid"
                  borderColor="greyLight-3"
                  borderLeftColor={isFirst ? "greyLight-3" : "transparent"}
                  borderRightColor={isLast ? "greyLight-3" : "transparent"}
                  borderTopLeftRadius={isFirst ? "60px" : 0}
                  borderBottomLeftRadius={isFirst ? "60px" : 0}
                  borderTopRightRadius={isLast ? "60px" : 0}
                  borderBottomRightRadius={isLast ? "60px" : 0}
                >
                  <Box
                    display="inline-block"
                    as={GatsbyLink}
                    to={`?section=${tab.url}`}
                    paddingX="space-16"
                    paddingY="space-8"
                    whiteSpace="nowrap"
                    position="relative"
                    border="1px solid"
                    borderColor={active ? "yellow-0" : "transparent"}
                    borderRadius="60px"
                    background={active ? "yellow-0" : "transparent"}
                    fontWeight={active ? "medium" : "normal"}
                    h="calc(100% + 2px)"
                    w="calc(100% + 2px)"
                    mt="-1px"
                    ml="-1px"
                    overflowY="visible"
                    className="slide"
                  >
                    {tab.label}
                  </Box>
                </Box>
              );
            })}
          </Stack>
        </Container>
      )}
      {announcement && (
        <Container
          paddingY="0"
          paddingX={{ base: "space-20", md: "space-58", "2xl": 0 }}
          paddingBottom="space-24"
        >
          <Box
            display={{ base: "none", md: "block" }}
            borderRadius="md"
            bg="greyLight-5"
            textAlign="center"
            maxW="1118px"
            marginX="auto"
            w="full"
            p="space-8"
            color="text-primary"
            fontSize="font-13"
          >
            {announcement}
          </Box>
        </Container>
      )}

      <SwipeCarousel
        items={items}
        announcement={announcement}
        isLarge={isLarge}
      />
      <Center>
        <ButtonSection
          is_header={is_header}
          cta_link={cta_link}
          show_scroll={show_scroll}
        />
      </Center>
    </Box>
  );
};

export default BlockPlansPresentation;
