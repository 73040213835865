import { VStack, SimpleGrid, Box, Heading, Text } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, Card, IconBox, PrismicLink, Spacer } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";
import Renderer from "src/lib/renderer";

import { Element } from "@prismicio/react";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { SvgOrImg } from "src/components/SvgOrImg";

const BlockCardsRedirection: FC<Gatsby.PageBlockCardsRedirectionFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  return (
    <BlockContainer>
      <VStack
        spacing={{ base: "space-24", md: "space-48" }}
        width="full"
        color="text-secondary"
        paddingX={{ md: "81px" }}
      >
        <Text as={Box} paddingX={{ md: "space-58"}}>
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    variant: "subtitle",
                    _last: {
                      marginBottom: 0,
                    },
                  },
                }),
            }}
          />
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="space-32" w="full">
          {items.map((item) => {
            if (!item) return null;
            const icon = item.icon?.document;

            return (
              <Card
                as={VStack}
                bg="greyLight-4"
                alignItems="flex-start"
                textAlign="left"
                padding={{ base: "space-32", md: "space-40" }}
                position="relative"
                spacing="space-32"
                key={item.label}
                hoverable={false}
              >
                {icon?.__typename === "PrismicElementImage" ? (
                  <IconBox>
                    <SvgOrImg
                      additionalProps={{ boxSize: "24px" }}
                      imageFile={icon.data?.image_file}
                      isBox={true}
                      size={24}
                    />
                  </IconBox>
                ) : null}

                <VStack spacing="space-16" align="flex-start" h="full">
                  <Heading as="h3" variant="subtitle">
                    {item.label}
                  </Heading>

                  <Text as="div" color="text-primary">
                    {item.description}
                  </Text>
                  <Spacer />

                  {item.button_link && (
                    <ShiButton
                      as={PrismicLink}
                      link={item.button_link}
                      variant="secondary"
                    >
                      {item.button_label}
                    </ShiButton>
                  )}
                </VStack>
              </Card>
            );
          })}
        </SimpleGrid>
      </VStack>
    </BlockContainer>
  );
};

export default BlockCardsRedirection;
