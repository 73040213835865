import { DEV_HUBSPOT_FORM_ID } from "./constants";

const hasLanguage = (language: string) => {
  const isSSR = typeof window === "undefined";

  if (isSSR) return false;

  const languages = navigator?.languages ?? [];
  const hasSpecifiedLanguage = languages.some((currentLanguage) => {
    return currentLanguage === language || currentLanguage.startsWith(`${language}-`)
  });

  return hasSpecifiedLanguage;
};

/**
 * @param {string} inlineMessage Inline message to display in place of the form upon a successful form completion. Cannot be used with redirectUrl.
 * @param {string} redirectUrl URL to which the form will redirect upon a successful form completion. Cannot be used with inlineMessage.
 * @see https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options 
 */
const buildOptionalFormParams = (inlineMessage?: string, redirectUrl?: string) => {
  if (redirectUrl) {
    return { redirectUrl };
  }

  if (inlineMessage) {
    return { inlineMessage };
  }

  return {};
};

/**
 * Removes the risk that Hubspot Form would not locate the target id for injecting the form,
 * by using a very limited set of characters which has wide HTML support.
 */
const convertToId = (str?: string) => {
  if (!str) {
    return '';
  }

  const rx = new RegExp("[^a-zA-Z0-9-]", "g");
  const id = str
    .replace(rx, "-")
    .replace(/^-+|-+$|(-)-+/g, "$1");

  return id.toLowerCase();
}

/**
 * On development environment we render a fake Hubspot Form,
 * not to pollute production forms in terms of data being submitted
 * On staging, for easier CMS setup we allow production forms
 */
const tweakFormId = (formId: string) => {
  // For Netlify reasons around build, NODE_ENV being `production` is also the value used for staging link
  const environment = process.env.NODE_ENV || "";
  const isProductionOrStaging = environment === "production";

  return isProductionOrStaging ? formId : DEV_HUBSPOT_FORM_ID;
};

export { buildOptionalFormParams, convertToId, hasLanguage, tweakFormId };
