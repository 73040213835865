import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Grid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";

import { GatsbyImage } from "gatsby-plugin-image";
import { FC, useState } from "react";
import { BlockContainer, MotionBox, ShiIcon } from "src/atoms";
import ElementAnimation from "src/components/elements/ElementAnimation";
import Renderer from "src/lib/renderer";

import { heading2 } from "src/lib/renderer/elements/headings";
import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";

type AccordionProps = Gatsby.PageBlockAccordionFragment;

const BlockAccordion: FC<AccordionProps> = ({ primary, items }) => {
  if (!primary) throw Error();

  const { content, footer_content, media_position, hide_media_on_mobile } =
    primary;

  const [expandedIndex, setExpandedIndex] = useState(-1); //chakra accordion's index when no item is open is -1

  const correctedIndex = expandedIndex === -1 ? 0 : expandedIndex;
  const currentItem = items?.[correctedIndex];
  const media = currentItem?.media?.document
    ? currentItem?.media
    : items?.[0]?.media;

  const isMediaLeft = media_position === "left";

  return (
    <BlockContainer>
      <Grid
        gap={{ base: "space-16", md: "space-80" }}
        gridTemplateColumns={{
          base: "auto",
          md: "1fr 1fr",
        }}
        gridTemplateAreas={{
          base: hide_media_on_mobile ? '"content"' : '"media" "content"',
          md: isMediaLeft ? '"media content"' : '"content media"',
        }}
      >
        {currentItem && (
          <Center
            w="full"
            sx={{ aspectRatio: "1 / 1" }}
            gridArea="media"
            borderRadius="lg"
            overflow="hidden"
            isolation="isolate"
          >
            <AnimatePresence exitBeforeEnter>
              <MotionBox
                key={media?.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                w="full"
              >
                {media?.document?.__typename === "PrismicElementImage" && (
                  <Box
                    as={GatsbyImage}
                    image={media.document.data?.image_file?.gatsbyImageData}
                    alt={media.document.data?.image_file?.alt ?? ""}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                )}

                {media?.document?.__typename === "PrismicElementAnimation" && (
                  <ElementAnimation data={media.document.data} />
                )}
              </MotionBox>
            </AnimatePresence>
          </Center>
        )}
        <Box gridArea="content" mt={{ xl: "space-40", "2xl": "space-60" }}>
          <VStack
            spacing={{ base: "space-16", md: "space-24" }}
            textAlign={{ base: "left" }}
            align={{ base: "flex-start" }}
          >
            <Renderer
              overrides={{
                [Element.heading2]: (args) =>
                  heading2({
                    ...args,
                    overrideProps: {
                      marginBottom: 0,
                    },
                  }),
                [Element.paragraph]: (args) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      variant: "subtitle",
                      fontSize: { base: "font-18", lg: "font-24" },
                      lineHeight: { base: "shorter", lg: "short" },
                    },
                  }),
              }}
              field={content}
            />
            <VStack
              as={Accordion}
              allowToggle
              onChange={(i) => setExpandedIndex(i as number)}
              w="full"
              spacing="0"
            >
              {items?.map((item) => {
                if (!item) return null;

                return (
                  <AccordionItem
                    key={item.label}
                    border="none"
                    borderTop="1px solid"
                    borderTopColor="greyLight-3"
                    paddingY="space-8"
                    paddingRight="space-40"
                    w="full"
                    position="relative"
                  >
                    {({ isExpanded }) => (
                      <Box textAlign="left">
                        <AccordionButton
                          paddingX="0"
                          paddingY={{ base: "space-8", md: "space-16" }}
                          width="full"
                          position="relative"
                          _hover={{}} // Override the hover defaults of Accordion
                        >
                          <Text
                            as="h3"
                            textAlign="left"
                            fontSize={{ base: "font-16" }}
                            fontWeight="normal"
                            lineHeight="short"
                          >
                            {item.label}
                          </Text>
                          <ShiIcon
                            name="chevron"
                            position="absolute"
                            right="-40px"
                            top="calc(50% - 8px)"
                            transform={isExpanded ? "rotate(180deg)" : ""}
                            transitionProperty="common"
                            transitionDuration="300ms"
                            transitionTimingFunction="ease-out"
                          />
                        </AccordionButton>

                        <AccordionPanel
                          paddingX="0"
                          paddingTop={{ base: "space-8", md: "0" }}
                          paddingBottom={{ base: "0", md: "space-8" }}
                          fontSize="font-16"
                          sx={{
                            p: {
                              color: "text-light",
                              lineHeight: "short",
                              _last: {
                                marginBottom: 0,
                              },
                            },
                          }}
                        >
                          <Renderer field={item.rich_description} />
                        </AccordionPanel>
                      </Box>
                    )}
                  </AccordionItem>
                );
              })}
            </VStack>
            <Renderer field={footer_content} />
          </VStack>
        </Box>
      </Grid>
    </BlockContainer>
  );
};

export default BlockAccordion;
