const ANIMATING_DISTANCE = "20vw";

export const motionVariants = {
  before: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  after: (direction: number) => ({
    opacity: 0,
    x: direction < 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),
};
