import {
  Box,
  Container,
  HStack,
  Heading,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";

import useVisitorContext from "src/providers/VisitorContext/useVisitorContext";
import { useLocation } from "@reach/router";
import ShiButton from "src/atoms/ShiButton";
import { onClickNewAccount } from "src/lib/event";
import Renderer from "src/lib/renderer";
import { CardPrice } from "src/slices/BlockPlansPresentation/components/PlanCard/styles";
import { ClickInfo } from "src/lib/event/types";
import { getSubtitleText } from "src/lib/event/properties";

const StickyBanner: FC<
  Gatsby.PageBlockPricingTableItemsFragment & { shouldAnimate?: boolean }
> = ({ items, shouldAnimate }) => {
  const visitorContext = useVisitorContext();
  const location = useLocation();

  return (
    <Box
      position="sticky"
      top="0"
      w="full"
      bg="rgba(255, 255, 255, 0.8)"
      zIndex="20"
      backdropFilter="blur(16px)"
    >
      <Container paddingY="0" size="block">
        <HStack
          spacing="0"
          pt="space-16"
          pb="space-24"
          maxW="1000px"
          mx="auto"
          alignItems="flex-start"
          borderBottom="1px solid"
          borderColor="greyLight-4"
        >
          <Box flex={1}>
            <Heading
              as="div"
              variant="heading-2"
              fontSize="font-30"
              opacity={shouldAnimate ? 1 : 0}
              transitionProperty="opacity"
              transitionDuration=".3s"
              transitionTimingFunction="ease-out"
            >
              Tous les détails
            </Heading>
          </Box>
          {items?.map((item) => {
            if (!item?.plan) return null;
            const {
              plan,
              override_desktop_tarif_subtitle,
              override_desktop_title,
              cta_label,
            } = item;

            const tarif = plan.document?.data?.tarif;
            const title = override_desktop_title
              ? override_desktop_title
              : plan?.document?.data?.title;
            const tarifSubTitle =
              override_desktop_tarif_subtitle &&
              override_desktop_tarif_subtitle?.text &&
              override_desktop_tarif_subtitle?.text !== ""
                ? override_desktop_tarif_subtitle
                : plan?.document?.data?.tarif_subtitle;

            return (
              <Box flex={1} px="space-16" key={plan?.document?.id || ""}>
                <Box>
                  <Text fontSize="font-18">
                    {title}
                  </Text>
                  <Box mt="space-4">
                    <CardPrice
                      price={tarif || 0}
                      priceUnit="€"
                    />
                    <Box
                      ml="space-8"
                      display="inline-block"
                      sx={{
                        p: {
                          fontSize: "font-14",
                        },
                        "p:last-of-type": {
                          marginBottom: 0,
                        },
                        em: {
                          textDecoration: "line-through",
                          fontStyle: "normal",
                        },
                      }}
                    >
                      <Renderer field={tarifSubTitle} />
                    </Box>
                  </Box>
                  <ShiButton
                    onClick={(e) => {
                      const tarifSubtitleText = tarifSubTitle?.text ?? getSubtitleText(tarifSubTitle);

                      const clickInfo: ClickInfo = {
                        label: cta_label,
                        price: tarif,
                        platform: 'web-desktop',
                        title,
                        block: 'block_pricing_table',
                        subtitle: tarifSubtitleText,
                        fromLocation: location
                      };

                      onClickNewAccount(e, visitorContext, clickInfo)
                    }}
                    hasArrow={false}
                    mt="space-24"
                    w={{
                      base: "full",
                    }}
                  >
                    {cta_label}
                  </ShiButton>
                </Box>
              </Box>
            );
          })}
        </HStack>
      </Container>
    </Box>
  );
};

export default StickyBanner;
