import { VStack, Container } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";

import { Element } from "@prismicio/react";
import { heading3 } from "src/lib/renderer/elements/headings";
import { Slice } from "../types";
import { getBottomPadding } from "./utils";
import { shouldDisplayBlock } from "../utils";

const BlockTitle: FC<Gatsby.PageBlockTitleFragment & { nextBlock: Slice }> = ({
  primary,
  nextBlock,
}) => {
  if (!primary) throw Error();

  const { content, experiment_variant: experimentVariant } = primary;

  const bottomPadding = getBottomPadding(nextBlock);

  const shouldShow = shouldDisplayBlock(experimentVariant);
  if (!shouldShow) {
    return null;
  }

  return (
    <BlockContainer paddingBottom={bottomPadding}>
      <Container variant="heading" paddingBottom={{ md: "space-8" }}>
        <VStack
          spacing={{ base: "space-16", md: "space-8" }}
          marginTop={{ md: "-8px" }}
          sx={{
            "h2,h3": {
              marginBottom: 0,
            },
          }}
        >
          <Renderer
            field={content}
            overrides={{
              [Element.heading3]: (args) =>
                heading3({
                  ...args,
                  overrideProps: {
                    variant: "heading-4",
                  },
                }),
            }}
          />
        </VStack>
      </Container>
    </BlockContainer>
  );
};

export default BlockTitle;
