import { Box, Flex, Heading, SimpleGrid, Text, VStack} from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, IconBox, PrismicLink } from "src/atoms";
import ShiButton from "src/atoms/ShiButton";
import { SvgOrImg } from "src/components/SvgOrImg";
import Renderer from "src/lib/renderer";

const BlockHighlightCards: FC<Gatsby.PageBlockHighlightCardsFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  return (
    <BlockContainer>
      <Box>
        <VStack
          marginBottom="space-48"
          direction="column"
          align="center"
          mx="auto"
          paddingX={{md : "135px"}}
          sx={{
            "h2": {
              marginBottom: 0
            }
          }}
        >
          <Renderer field={content} />
        </VStack>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: items?.length ?? 1 }}
          spacing={{ base: "space-16" , md : "space-32"}}
        >
          {items?.map((item) => {
            if (!item) return null;
            const icon = item.icon?.document;

            return (
              <Flex
                key={item.label}
                padding={{ base: "space-24", md: "space-40" }}
                flexDirection="column"
                alignItems="flex-start"
                textAlign="start"
                backgroundColor="grey-6"
                borderRadius="xl"
                bg="greyLight-4"
              >
                {icon?.__typename === "PrismicElementImage" ? (
                  <IconBox marginBottom="space-40">
                    <SvgOrImg
                      imageFile={icon.data?.image_file}
                      isBox={true}
                      size={24}
                    />
                  </IconBox>
                ) : null}

                <Heading as="h3" variant="subtitle" marginBottom="space-16">
                  {item.label}
                </Heading>

                <Text as={Box} variant="body">
                  {item.description}
                </Text>

                {item.link_label && item.link && (
                  <Box mt="auto">
                    <ShiButton
                      as={PrismicLink}
                      link={item.link}
                      variant="secondary"
                      marginTop="space-40"
                      alignSelf="baseline"
                    >
                      {item.link_label}
                    </ShiButton>
                  </Box>
                )}
              </Flex>
            );
          })}
        </SimpleGrid>
      </Box>
    </BlockContainer>
  );
};

export default BlockHighlightCards;
