import { Box, VStack, Grid } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import ElementAnimation from "src/components/elements/ElementAnimation";
import Renderer from "src/lib/renderer";

import { getItemBackground } from "../BlockTrioProductHighlight/utils";
import { getSizes } from "src/lib/image";

const BlockTetrisProductHighlight: FC<
  Gatsby.PageBlockTetrisProductHighlightFragment
> = ({ primary, items }) => {
  if (!primary) throw Error();

  const { content, media } = primary;
  const sizes = getSizes('md', items?.length ?? 0);

  return (
    <BlockContainer>
      <Grid
        gridGap={{ base: "space-16", md: "space-32" }}
        w="full"
        gridTemplateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gridTemplateRows="auto"
        gridTemplateAreas={{
          md: '"content media media" "item-1 item-2 item-3"',
        }}
      >
        <VStack
          spacing={{ base: "space-16", md: "space-24" }}
          maxW={{ base: "730px", md: "unset" }}
          bg={{ md: "greyLight-3" }}
          p={{ md: "space-40" }}
          borderRadius="lg"
          overflow="hidden"
          alignItems="center"
          justifyContent="center"
          textAlign={{ md: "left" }}
          gridArea={{ md: "content" }}
          sx={{
            h2: {
              marginBottom: 0,
              lineHeight: 1.15
            },
          }}
        >
          <Renderer field={content} />
        </VStack>
        <Box
          borderRadius="lg"
          overflow="hidden"
          bg="greyLight-5"
          position="relative"
          gridArea="media"
          display={{ base: "none", md: "block" }}
        >
          {media?.document?.__typename === "PrismicElementImage" && (
            <Box
              as={GatsbyImage}
              image={media.document.data?.image_file?.gatsbyImageData}
              alt={media.document.data?.image_file?.alt ?? ""}
              sizes='67vw'
              top="0"
              left="0"
              w="full"
              h="full"
            />
          )}
        </Box>

        {items?.map((item, index) => {
          if (!item) return null;

          return (
            <VStack
              borderRadius="lg"
              overflow="hidden"
              spacing={{ base: "space-24", md: "space-32" }}
              key={item.media?.id || ""}
              bg={getItemBackground("grey", index)}
              w="full"
              gridArea={{ md: `item-${index + 1}` }}
            >
              <Box w="full">
                {item.media?.document?.__typename === "PrismicElementImage" && (
                  <Box
                    as={GatsbyImage}
                    image={
                      item.media.document.data?.image_file?.gatsbyImageData
                    }
                    alt={item.media.document.data?.image_file?.alt ?? ""}
                    sizes={sizes}
                    width="full"
                  />
                )}

                {item.media?.document?.__typename ===
                  "PrismicElementAnimation" && (
                  <ElementAnimation data={item.media.document.data} />
                )}
              </Box>
              <Box
                padding={{ base: "space-24", md: "space-32" }}
                paddingTop={{ base: "0", md: "0" }}
                textAlign="left"
                sx={{
                  p: {
                    lineHeight: "base",
                    fontSize: "font-16",
                    _last: {
                      marginBottom: 0,
                    },
                  },
                }}
              >
                <Renderer field={item.rich_description} />
              </Box>
            </VStack>
          );
        })}
      </Grid>
    </BlockContainer>
  );
};

export default BlockTetrisProductHighlight;
