import { Box, Container, VStack } from "@chakra-ui/react";
import { FC, useState, useEffect } from "react";

import Renderer from "src/lib/renderer";

import Mobile from "./components/Mobile";
import Desktop from "./components/Desktop";
import { InView } from "react-intersection-observer";

const BlockPricingTable: FC<Gatsby.PageBlockPricingTableFragment> = ({
  primary,
  items,
}) => {
  if (!primary) throw Error();

  const { content } = primary;

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const navbar = document.getElementById("site-navbar");
    if (shouldAnimate) {
      if (navbar) {
        navbar.style.opacity = "0";
        navbar.style.transform = "translateY(-100%)";
      }
    } else {
      if (navbar) {
        navbar.style.opacity = "1";
        navbar.style.transform = "translateY(0)";
      }
    }
  }, [shouldAnimate]);

  return (
    <Box w="full" id="pricing-plans-table">
      <Container textAlign="left" w="full" size="block" paddingY="0" paddingBottom={ { base: "0", md: "space-64"}}>
        <VStack
          display={{ base: "none", md: "flex" }}
          alignItems="flex-start"
          maxW="1000px"
          marginX="auto"
          spacing={{ base: "space-16", md: "space-24" }}
          sx={{
            h2: {
              marginBottom: 0,
            },
          }}
        >
          <Renderer field={content} />
        </VStack>
        <InView
          as="div"
          threshold={1}
          onChange={(inView, ref) => {
            if (!shouldAnimate && ref.boundingClientRect.top <= 0) {
              setShouldAnimate(true);
            }

            if (shouldAnimate && ref.boundingClientRect.top >= 0) {
              setShouldAnimate(false);
            }
          }}
        >
          <div />
        </InView>
      </Container>

      <Mobile items={items} />
      <Desktop items={items} shouldAnimate={shouldAnimate} />
      <InView
        as="div"
        threshold={1}
        onChange={(inView, ref) => {
          if (shouldAnimate && ref.boundingClientRect.top <= 0) {
            setShouldAnimate(false);
          }

          if (inView) {
            if (!shouldAnimate && ref.boundingClientRect.top >= 0) {
              setShouldAnimate(true);
            }
          }
        }}
      >
        <div />
      </InView>
    </Box>
  );
};

export default BlockPricingTable;
