/**
 * We'll animate cards and groups starting from an arbitrary value depending on the viewport
 * to have similar feelings between devices.
 */
const ANIMATING_DISTANCE = "33vw";

export const motionVariants = {
  before: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  after: (direction: number) => ({
    opacity: 0,
    x: direction < 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),
};
