import { Box, Flex, VStack } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import ShineStartButton from "../../components/ShineStartButton";

const BlockShineStart: FC<Gatsby.BlockShineStartFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content, main_image } = primary;

  return (
    <BlockContainer>
      <Flex direction="column" align="center" maxWidth="730px">
        <Renderer field={content} />
      </Flex>

      <Flex
        maxWidth="960px"
        marginX="auto"
        marginTop={{ lg: "space-48" }}
        bg="orange-5"
        borderRadius="xl"
        position="relative"
        justify="space-between"
      >
        <Box
          display={{ base: "none", md: "initial" }}
          alignSelf="flex-end"
          width="430px"
          marginTop="-50px"
          marginLeft="-50px"
        >
          {main_image?.document?.data && (
            <GatsbyImage
              image={main_image.document.data?.image_file?.gatsbyImageData}
              alt={main_image.document.data?.image_file?.alt ?? ""}
            />
          )}
        </Box>

        <Flex
          flexDirection="column"
          justify="center"
          align="flex-end"
          position={{ base: "initial", md: "relative" }}
          right={{ base: "", md: "-32px" }}
        >
          <VStack
            maxW="500px"
            paddingY={{ base: "space-24", md: "space-64" }}
            paddingX={{ base: "space-24", md: undefined }}
            marginRight={{ md: "-space-64" }}
            direction="column"
            justify="center"
            align="stretch"
          >
            {items.map((button) => {
              if (!button) return null;

              const { button_link, button_label, button_sublabel } = button;

              if (!button_link || !button_label) return null;

              return (
                <ShineStartButton
                  key={button.button_label}
                  button_label={button_label}
                  button_sublabel={button_sublabel}
                  button_link={button_link}
                  hasIcon
                />
              );
            })}
          </VStack>
        </Flex>
      </Flex>
    </BlockContainer>
  );
};

export default BlockShineStart;
