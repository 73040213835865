import { Box, HStack, Stack } from "@chakra-ui/react";

import { FC, useRef, useState, useEffect, Fragment } from "react";
import CarouselArrow from "src/atoms/rebrand/Carousel/components/CarouselArrow";
import Card from "../atoms/Card";

const DeskopCarousel: FC<Gatsby.PageBlockPersonaFragment> = ({ items }) => {
  const ref = useRef<HTMLDivElement>();

  const [slides, setSlides] = useState(items || []);
  const [slideIndex, setSlideIndex] = useState(1);
  const [inTransition, setInTransition] = useState(false);
  const [dir, setDir] = useState("");
  const [shouldShowArrows, setShouldShowArrows] = useState(false);
  const [shouldShowClones, setShouldShowClones] = useState(true); //if carousel is in infinite mode, we need to clone element to make movement smooth

  if (!items) throw Error();

  const handleTransitionEnd = () => {
    if (!inTransition) return;

    let newSlideIndex = slideIndex;
    let newSlides = [];

    if (dir === "next") {
      newSlideIndex -= 1;
      newSlides = [...slides, ...slides.slice(0, 1)].slice(-items.length);
    } else {
      newSlideIndex += 1;
      newSlides = [...slides.slice(-1), ...slides].slice(0, items.length);
    }

    setSlides([...newSlides]);
    setSlideIndex(newSlideIndex);
    setInTransition(false);
  };

  const sideScroll = (direction: string) => {
    let newIndex = slideIndex;

    if (inTransition) return;

    if (ref.current) {
      newIndex = direction === "prev" ? slideIndex - 1 : slideIndex + 1;

      setInTransition(true);
      setSlideIndex(newIndex);
      setDir(direction);
    }
  };

  const transLateValue = () => {
    return -(slideIndex * 352);
  };

  const sliderStyle = () => {
    if (!shouldShowClones) {
      return undefined;
    }

    if (inTransition) {
      return {
        transform: `translateX(${transLateValue()}px)`,
        transition: "transform 0.3s ease-in-out",
      };
    }

    return {
      transform: `translateX(${transLateValue()}px)`,
    };
  };

  useEffect(() => {
    const checkInfinite = () => {
      const windowWidth = window.innerWidth;

      if (352 * items.length > windowWidth) {
        return true;
      } else {
        return false;
      }
    };

    const checkArrows = () => {
      setShouldShowClones(checkInfinite());
      if (!shouldShowClones) {
        return setShouldShowArrows(false);
      }
      if (ref.current) {
        if (ref.current.scrollWidth - ref.current.clientWidth > 24) {
          setShouldShowArrows(true);
        } else {
          setShouldShowArrows(false);
        }
      }
    };

    checkArrows();

    window.addEventListener("resize", checkArrows);

    return () => {
      window.removeEventListener("resize", checkArrows);
    };
  }, [items.length, shouldShowClones]);

  return (
    <Box
      w="full"
      display={{ base: "none", md: "block" }}
      maxW="100vw"
      mx="auto"
      overflow="hidden"
    >
      <Stack
        ref={ref}
        style={sliderStyle()}
        onTransitionEnd={handleTransitionEnd}
        overflow="visible"
        whiteSpace="nowrap"
        w="full"
        direction="row"
        spacing="space-32"
        paddingX="space-24"
        justify={{
          base: !shouldShowClones ? "center" : "start",
        }}
      >
        {slides.map((item) => {
          if (!item) return null;

          return (
            <Fragment key={item.label}>
              <Card item={item} />
            </Fragment>
          );
        })}

        {shouldShowClones ?
          slides.map((item) => {
            if (!item) return null;

            return (
              <Fragment key={item.label}>
                <Card item={item} className="cloned" />
              </Fragment>
            );
          }) : null}
      </Stack>

      {shouldShowArrows && (
        <HStack
          spacing="space-16"
          mt="space-32"
          w="full"
          justifyContent="center"
          maxW="1794px"
          marginLeft="0"
        >
          <CarouselArrow
            onClick={() => sideScroll("prev")}
            transform={"rotate(-180deg)"}
          />
          <CarouselArrow onClick={() => sideScroll("next")} />
        </HStack>
      )}
    </Box>
  );
};

export default DeskopCarousel;
