import { breakpointsMapping } from "src/@chakra-ui/gatsby-plugin/foundations/breakpoints";

/**
 * Returns the sizes attribute for an image when used in a row on desktop,
 * and used as a column on smaller devices
 */
export const getSizes = (
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl',
  imageCount: number
) => {
  if (imageCount <= 1) return '100vw';

  const breakpointEm = breakpointsMapping[breakpoint];

  if (!breakpointEm) return '100vw';

  const viewportWidth = Math.ceil(100 / imageCount);

  return `(min-width: ${breakpointEm}) ${viewportWidth}vw, 100vw`;
};
