import { Tab } from "@chakra-ui/react";
import { FC } from "react";

interface Props {
  tab: Gatsby.CollectionPricingsTabFragment;
}
const PricingTab: FC<Props> = ({ tab }) => {
  return (
    <Tab
      fontWeight="medium"
      _selected={{
        backgroundColor: "bg-primary-active",
        color: "white",
      }}
    >
      {tab.primary?.name}
    </Tab>
  );
};

export default PricingTab;
