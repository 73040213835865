import { graphql, useStaticQuery } from "gatsby";
import { Box, Text, Container, SimpleGrid } from "@chakra-ui/react";
import type { FC } from "react";
import ToolboxOfferCard from "./ToolboxOfferCard";

const ToolboxOffers: FC = () => {
  const toolboxOffers = useStaticQuery<Gatsby.GetToolboxOffersQuery>(graphql`
    query GetToolboxOffers {
      allPrismicToolboxOffer(
        filter: { data: { is_featured_on_website: { eq: true } } }
        sort: { fields: data___priority, order: DESC }
      ) {
        nodes {
          id
          data {
            is_featured_on_website
            cover {
              url
            }
            title {
              text
            }
            perk_badge {
              text
            }
            partner {
              document {
                ... on PrismicToolboxPartner {
                  data {
                    logo {
                      url
                    }
                    name {
                      text
                    }
                    catchline {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Container id="nos-offres" maxWidth="960px" paddingTop="space-24">
      <Box pb="space-24">
        <Text fontWeight="500" fontSize="18px">
          Nos offres
        </Text>
      </Box>
      <SimpleGrid columns={[1, 2, 3]} spacing="space-24">
        {toolboxOffers.allPrismicToolboxOffer.nodes.map((offer) =>
          offer.data ? (
            <ToolboxOfferCard
              key={offer.id}
              offer={offer.data as Gatsby.PrismicToolboxOfferDataType}
            />
          ) : null,
        )}
      </SimpleGrid>
    </Container>
  );
};

export default ToolboxOffers;
