import { Flex, Portal } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { MotionBox } from "src/atoms";
import Renderer from "src/lib/renderer";

const FloatingBanner: FC<Gatsby.PageFloatingBannerFragment> = ({ primary }) => {
  if (!primary) throw Error();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const updateVisible = () => setIsVisible(window.scrollY >= 1000);

    document.addEventListener("scroll", updateVisible);

    return () => document.removeEventListener("scroll", updateVisible);
  }, [isVisible]);

  return (
    <Portal>
      <AnimatePresence>
        {isVisible && (
          <MotionBox
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            position="fixed"
            top={50}
            right={50}
          >
            <Flex align="center" direction="column">
              <Renderer field={primary.buttons} />
            </Flex>
          </MotionBox>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default FloatingBanner;
