import { FC, Fragment } from "react";
import { PrismicLink } from "src/atoms";

import { AnimatePresence } from "framer-motion";
import { MotionBox } from "src/atoms";
import {
  Flex,
  GridItem,
  Text,
  Box
} from "@chakra-ui/react";
import { SvgOrImg } from "src/components/SvgOrImg";

const Card: FC<
  {
    onMouseEnter: () => void,
    onMouseLeave: () => void,
    expandedItemsIndexes?: number[],
    index: number,
    item: NonNullable<Gatsby.PageBlockStretchableCardsFragment["items"]>[number]
  }
> = ({ item, index, expandedItemsIndexes, ...props }) => {
  // TODO: LATER: include in CMS
  const getXValue = (index: number) => {
    if (index === 0) return "center";
    if (index === 1) return "62%";
    if (index === 2) return "center";
    if (index === 3) return "32%";
    return 0;
  };

  const getYValue = (index: number) => {
    if (index === 0) return "5%";
    if (index === 1) return "15%";
    if (index === 2) return "25%";
    if (index === 3) return "center";
    return 0;
  };

  const isLarge = expandedItemsIndexes?.includes(index);

  if (!item) {
    return null;
  }

  const {
    title,
    description,
    background_image: backgroundImage,
    link,
    badge_label,
    badge_icon
  } = item;

  const backgroundImageUrl = backgroundImage?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;
  const hasLink = !!link && link.link_type !== "Any";
  const Wrapper = hasLink ? PrismicLink : Fragment;
  const wrapperProps = hasLink ? {
    link,
  } : {};

  const renderTitle = (text: string) => {
    return (
      <Fragment>
        <Text
          fontSize="font-20"
          lineHeight="base"
          display={isLarge ? "inline-block" : "block"}
          marginBottom={isLarge && badge_label ? "space-8" : 0}
        >{text}</Text>
        {badge_label ? <Box
          marginLeft={isLarge ? "space-16" : 0}
          marginBottom={isLarge ? 0 : "space-8"}
          display="inline-block"
          borderRadius="6px"
          bg="white"
          color="grey-0"
          paddingX="6px"
          paddingY="space-4"
        >
          <Box display="inline-block" marginRight="space-8">
            <SvgOrImg
              additionalBoxProps={{
                maxW: { base: "16px"},
                maxH: { base: "16px"}
              }}
              imageFile={badge_icon?.document?.data?.image_file}
              size={16}
            />
          </Box>
          <Text display="inline-block">{badge_label}</Text>
        </Box> : null}
      </Fragment>
    );
  };

  const renderAnimatedTitle = (title: string, description: string, isLarge: boolean) => {
    return (
      <AnimatePresence>
        <MotionBox
          key={`${title}-${description}-title-${isLarge}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ display: "none" }}
          transition={{ ease: "linear", duration: 1 }}
        >
          {renderTitle(title)}
        </MotionBox></AnimatePresence>
    );
  };

  // TODO: LATER: allow CMS values for the background-position
  return (
    <GridItem
      {...props}
      key={title}
      backgroundRepeat="no-repeat"
      backgroundPosition={`${getXValue(index)} ${getYValue(index)}`}
      backgroundSize="auto 100%"
      backgroundImage={`url('${backgroundImageUrl}')`}
      borderRadius="lg"
      transition="grid linear 500ms"
    >
      <Wrapper
        {...wrapperProps}
      >
        <Flex
          height="523px"
          color="white"
          padding="space-24"
          bg="linear-gradient(to bottom, #25241D00 70%, #25241DF0)"
          borderRadius="lg"
          textAlign="left"
        >
          <Flex
            alignSelf="flex-end"
            flexDirection="column"
            overflow="hidden"
          >
            {description && title && !isLarge ?
            renderAnimatedTitle(title, description, false)
            : null}
            {description && title && isLarge ?
              renderAnimatedTitle(title, description, true)
              : null}
            {!description && title ? renderTitle(title) : null}
            {description && isLarge ?
              <AnimatePresence>
                <MotionBox
                  key={`${title}-${description}-description`}
                  initial={{ opacity: 0, minWidth: "1100px" }}
                  animate={{ opacity: 1, minWidth: "0px" }}
                  transition={{ ease: "easeIn", duration: 1 }}
                  exit={{ display: "none" }}
                  maxWidth={{ md: "100%", lg: "70%" }}
                >
                  <Text
                    fontSize="font-15"
                    lineHeight={1.1}

                  >{description}</Text>
                </MotionBox>
              </AnimatePresence>

              : null}

          </Flex>
        </Flex>
      </Wrapper>
    </GridItem>
  );
};

export default Card;
