import {
  Box,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";

import { FC, Fragment } from "react";
import Renderer from "src/lib/renderer";

import { ShiIcon } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";

const MobileAccordion: FC<Gatsby.PageBlockCreationRedirectionFragment> = ({
  items,
}) => {
  if (!items) return null;
  return (
    <VStack
      as={Accordion}
      display={{ md: "none" }}
      w="full"
      spacing="space-16"
      allowToggle
    >
      {items.map((item) => {
        if (!item) return null;
        return (
          <AccordionItem
            key={item.title}
            bg="greyLight-3"
            borderRadius="lg"
            w="full"
          >
            {({ isExpanded }) => (
              <Fragment>
                <AccordionButton p="space-16">
                  <Text as="div">{item.title}</Text>
                  <Box ml="auto">
                    <ShiIcon
                      name="accordion"
                      boxSize="10px"
                      transitionProperty="common"
                      transitionDuration="300ms"
                      transitionTimingFunction="ease-out"
                      transform={isExpanded ? "rotate(180deg)" : "rotate(0deg)"}
                    />
                  </Box>
                </AccordionButton>
                <AccordionPanel p="space-16">
                  <VStack
                    spacing="space-16"
                    sx={{
                      p: {
                        w: "full",
                      },
                      ".chakra-link": {
                        w: "full",
                      },
                    }}
                  >
                    <Box>
                      <Box
                        as={GatsbyImage}
                        image={
                          item.image?.document?.data?.image_file?.gatsbyImageData
                        }
                        alt={item.image?.document?.data?.image_file?.alt ?? ""}
                      />
                    </Box>
                    <Renderer field={item.content} />
                    <Renderer field={item.button} />
                  </VStack>
                </AccordionPanel>
              </Fragment>
            )}
          </AccordionItem>
        );
      })}
    </VStack>
  );
};

export default MobileAccordion;
