import { FC } from "react";
import CollectionRatings from "./collections/Ratings";
import CollectionPricings from "./collections/Pricings";
import SliceZone from "../SliceZone";
import SLICES from "..";
import CollectionTable from "./collections/Table";
import CollectionShineStartBlocks from "./collections/ShineStartBlocks";
import CollectionPresseBlocks from "./collections/PresseBlocks";
import CollectionIframe from "./collections/Iframe";
import { Slice } from "../types";

const BlockRelation: FC<
  Gatsby.PageBlockRelationFragment & { prevBlock?: Slice; nextBlock?: Slice }
> = ({ primary, prevBlock, nextBlock }) => {
  if (!primary) throw Error();

  const doc = primary.relation?.document;

  if (!doc || !("data" in doc)) return null;

  switch (doc.__typename) {
    case "PrismicCollectionRatings":
      return <CollectionRatings data={doc.data} />;
    case "PrismicCollectionPricings":
      return <CollectionPricings data={doc.data} />;
    case "PrismicCollectionTable":
      return <CollectionTable data={doc.data} />;
    case "PrismicCollectionShinestartBlocks":
      return <CollectionShineStartBlocks data={doc.data} />;
    case "PrismicCollectionPresseBlocks":
      return <CollectionPresseBlocks data={doc.data} />;
    case "PrismicCollectionIframe":
      return <CollectionIframe data={doc.data} />;
  }

  return (
    <SliceZone
      slices={SLICES}
      body={((doc as any).data as any).body}
      is_block_relation={true}
      prevBlock={prevBlock}
      nextBlock={nextBlock}
    />
  );
};

export default BlockRelation;
