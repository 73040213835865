import { Box, Divider, Flex, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { BlockContainer, Card, ShiIcon } from "src/atoms";
import { ShiIconName } from "src/atoms/ShiIcon";
import Renderer from "src/lib/renderer";
import RatingsGradeStars from "src/components/GradeStars";

import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";

const CollectionRatings: FC<Gatsby.CollectionRatingsFragment> = ({ data }) => {
  if (!data) throw Error();

  const { content, legend, ratings, has_colored_background, testimonials } =
    data;

  return (
    <BlockContainer
      paddingY={{
        base: has_colored_background ? "space-40" : "space-20",
        md: has_colored_background ? "space-60" : "space-20",
      }}
    >
      <Box
        bg={has_colored_background ? "grey-4" : "transparent"}
        borderRadius="lg"
        p={{
          base: has_colored_background ? "space-24" : 0,
          md: has_colored_background ? "space-80" : 0,
        }}
        w="full"
      >
        {content?.text && (
          <Box
            marginBottom={{ base: "space-32", md: "space-48" }}
            maxW="800px"
            mx="auto"
          >
            <Renderer field={content} />
          </Box>
        )}

        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="center"
          alignItems="center"
        >
          <SimpleGrid
            columns={{ base: 1, md: Math.min(ratings?.length ?? 0, 3) }}
            gridGap={{ base: "space-32", md: "space-80" }}
            marginRight={{ lg: "space-80" }}
          >
            {ratings?.map((rating) => {
              if (rating?.rate === undefined) return null;

              return (
                <Stack
                  alignItems="center"
                  spacing="space-8"
                  key={rating.marketplace}
                >
                  <RatingsGradeStars value={rating.rate} />

                  <Text
                    as="div"
                    display="flex"
                    alignItems="center"
                    fontWeight="medium"
                    color={{ base: "text-primary", md: "text-secondary" }}
                    flex="1"
                  >
                    <Text as="span">{rating.rate}</Text>

                    <Text as="span" marginX="space-8">
                      {rating.marketplace}
                    </Text>

                    <Box h="24px" w="24px">
                      <ShiIcon
                        name={rating.marketplace_icon as ShiIconName}
                        width="20px"
                        height="20px"
                      />
                    </Box>
                  </Text>
                </Stack>
              );
            })}
          </SimpleGrid>

          {/* Vertical divider for large breakpoints */}
          <Divider
            marginY="space-16"
            marginX="space-32"
            display={{ base: "none", lg: "inherit" }}
            variant="grey"
            orientation="vertical"
            height="65px"
          />

          <Box
            textAlign={{ lg: "initial" }}
            marginTop={{ base: "space-32", lg: "0"}}
            sx={{
              ".btn-secondary": {
                borderBottomColor: "text-secondary",
              },
            }}
          >
            <Renderer
              overrides={{
                [Element.paragraph]: (args) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      fontFamily: "heading",
                      color: "text-secondary",
                      fontSize: "font-24",
                      marginBottom: "2px",
                      lineHeight: "none",
                      sx: {
                        strong: {
                          color: "inherit",
                        },
                        a: {
                          fontFamily: "body",
                        },
                      },
                      _last: {
                        marginBottom: 0,
                      },
                    },
                  }),
              }}
              field={legend}
            />
          </Box>
        </Flex>

        {testimonials?.length ? (
          <SimpleGrid
            columns={[1, 1, 2]}
            marginX="auto"
            paddingX={{
              md: "space-40",
            }}
            gridGap="space-32"
            marginTop="space-48"
          >
            {testimonials.map((testimonial) => {
              if (!testimonial) return null;
              const author_picture = testimonial.author_picture?.document;

              return (
                <Card
                  as={Flex}
                  flexDirection="column"
                  paddingY="space-40"
                  paddingX={{ md: "space-40" }}
                  textAlign="left"
                  position="relative"
                  key={testimonial.author_name}
                  hoverable={false}
                  background={has_colored_background ? "white" : "grey-4"}
                  sx={{
                    p: {
                      color: "text-secondary",
                      _last: {
                        mb: 0,
                      },
                    },
                  }}
                >
                  <ShiIcon name="quotationmark" boxSize="40px" mb="space-8" />

                  <Renderer field={testimonial.testimonial} />

                  <Flex marginTop="auto" alignItems="center" pt="space-16">
                    {author_picture?.__typename === "PrismicElementImage" && (
                      <Box
                        as={GatsbyImage}
                        borderRadius="full"
                        boxSize="40px"
                        image={author_picture.data?.image_file?.gatsbyImageData}
                        alt={`Portrait of ${testimonial.author_name}, ${testimonial.author_job}`}
                      />
                    )}

                    <Box marginLeft="space-16" color="text-secondary">
                      <Text as="div" display="inline">
                        {testimonial.author_name}
                      </Text>
                      {testimonial.author_name && testimonial.author_job && (
                        <Text as="div" display="inline" mx="space-4">
                          -
                        </Text>
                      )}
                      <Text as="div" display="inline">
                        {testimonial.author_job}
                      </Text>
                    </Box>
                  </Flex>
                </Card>
              );
            })}
          </SimpleGrid>
        ) : null}
      </Box>
    </BlockContainer>
  );
};

export default CollectionRatings;
