import { FC } from "react";
import { ShiIcon } from "src/atoms";
import { Box, BoxProps } from "@chakra-ui/react";
import Renderer from "src/lib/renderer";
import ShiButton from "src/atoms/ShiButton";
import { logEvent } from "src/lib/analytics";
import { useLocation } from "@reach/router";
import { buildPageProperties } from "src/lib/event/properties";

const ButtonSection: FC<
  BoxProps & {
    is_header?: boolean;
    show_scroll?: boolean;
    variant?: string;
    cta_link?: Pick<Gatsby.PrismicStructuredTextType, "raw">;
  }
> = ({ is_header, cta_link, show_scroll, variant = "lightGrey", ...rest }) => {
  const location = useLocation();

  const scrollToTable = (e: React.MouseEvent) => {
    e.preventDefault();

    const table = document.getElementById("pricing-plans-table");

    if (table) {
      table.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const label = 'Comparer toutes les offres';

  return (
    <Box {...rest}>
      {is_header && show_scroll && (
        <Box mt="space-40">
          <ShiButton
            onClick={(e) => {
              scrollToTable(e)
              const event = {
                name: 'Offers Comparison Clicked',
                properties: {
                  label,
                  block: 'block_plans_presentation',
                  ...buildPageProperties(location)
                }
              }

              logEvent(event);
            }}
            variant={variant}
            hasArrow={false}
            sx={{
              ":hover": {
                bg: "greyLight-3",
                ".element-cta-icon": {
                  transform: (theme) =>
                    `rotate(90deg) translateX(${theme.space[1]})`,
                },
              },
            }}
          >
            {`${label} `}
            <ShiIcon
              transition="transform .2s ease-out"
              name="arrowicon"
              marginLeft="space-8"
              transform="rotate(90deg)"
              className="element-cta-icon"
            />
          </ShiButton>
        </Box>
      )}
      {cta_link && (
        <Box
          mt="space-40"
          textAlign="center"
          sx={{
            p: {
              _last: {
                marginBottom: 0,
              },
            },
          }}
        >
          <Renderer field={cta_link} />
        </Box>
      )}
    </Box>
  );
};

export default ButtonSection;
