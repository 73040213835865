export const getAdjustedStep = (digit: number) => {
  if (digit <= 3) {
    return 2;
  }

  if (digit <= 5) {
    return 3;
  }

  return 5;
};

export const isDigit = (value: string) => {
  const isSpace = value.trim() === "";
  const valueToNumber = Number(value);
  return isSpace ? false : !isNaN(valueToNumber);
};
