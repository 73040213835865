import { Slice } from "./types";

const pageFragmentTypes = [
  "PrismicCollectionRatings",
  "PrismicCollectionPricings",
  "PrismicCollectionTable",
  "PrismicCollectionShinestartBlocks",
  "PrismicCollectionPresseBlocks",
  "PrismicCollectionIframe",
];

const sliceBgColors: { [key: string]: string } = {
  block_columns: "greyLight-4",
  block_title: "greyLight-4",
  block_persona: "blue-0",
  block_creation_redirection: "blue-0",
  block_animated_testimonial: "greyLight-4",
  block_plans_presentation: "greyLight-4",
  block_trio_offer: "blue-0"
};

const NON_PADDING_TYPES = ["sub_pages", "block_animated_testimonial"];

export const getBackgroundColor = (currentSlice: Slice | null) => {
  if (!currentSlice) return null;

  const typename =
    currentSlice.slice_type !== "block_relation"
      ? currentSlice?.slice_type
      : currentSlice?.primary?.relation?.document?.__typename;

  if (typename === "block_columns") {
    // block columns have a specific spacing exceptions depending on its background color
    if (currentSlice.primary?.has_colored_background === false) {
      return null;
    }   
  }

  if (typename === "block_plans_presentation") {
  // block plans presentation have a specific spacing exceptions depending on its background color
    if (currentSlice.primary?.is_header === true) {
      return null;
    }
  }

  const bgColor: string | null = sliceBgColors[typename] || null;

  return bgColor;
};

export const checkExtraPadding = (
  slice: Slice | null,
  nextSlice?: Slice | null,
  is_block_relation?: boolean,
) => {
  if (slice?.slice_type === "block_trio_offer") {
    return false
  }

  if (!nextSlice) return true;

  const currentBackground = getBackgroundColor(slice);

  const nextBackground = getBackgroundColor(nextSlice!);

  if (is_block_relation || (slice && NON_PADDING_TYPES.includes(slice?.slice_type)) ) {
    return false
  }

  return currentBackground !== nextBackground ? true : false;
};

export const getSliceType = (slice?: Slice | null) => {
  if (!slice) return null;

  let currentSlice = slice;

  if (!slice.primary) return slice; // this is a case when page fragment is used inside page fragment which isn't supported

  if (slice.slice_type === "block_relation") {
    if (
      pageFragmentTypes.includes(slice?.primary?.relation.document?.__typename)
    ) {
      return slice; // just return the block relation, as its reference is a custom type, not a slice
    }

    currentSlice =
      currentSlice.primary.relation.document?.data?.body &&
      currentSlice.primary.relation.document.data?.body[0];
  }

  return currentSlice;
};

// Note: different function prototype than ramda because of optional value from the CMS
export const clamp = (lower: number, upper: number, defaultValue: number, value?: number) => {
  if (typeof value !== 'number') {
    return defaultValue;
  }

  if (value < lower) {
    return lower;
  }

  if (value > upper) {
    return upper;
  }

  return value;
};

export const isHubspotFormSlice = (slice?: Slice | null) => {
  if (!slice) {
    return false;
  }

  return ['block_hubspot_form', 'block_embed_hubspot_form'].includes(slice.slice_type);
};

export const isEmbedHubspotFormSlice = (slice?: Slice | null) => {
  if (!slice) {
    return false;
  }

  return slice.slice_type === 'block_embed_hubspot_form';
};

export const shouldDisplayBlock = (experimentVariant?: string) => {
  if (!experimentVariant || experimentVariant === 'all') {
    return true;
  }

  switch (process.env.GATSBY_BRANCH) {
    case "master": return experimentVariant === "a";
    case "mbster": return experimentVariant === "b";
    default: return true;
  }
};
