import { Box, VStack, Text, HStack, Grid, Center } from "@chakra-ui/react";
import { Element } from "@prismicio/react";
import { FC } from "react";
import Renderer from "src/lib/renderer";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import { SvgOrImg } from "src/components/SvgOrImg";

const BlockMission: FC<Gatsby.PageBlockMissionFragment> = ({
  primary,
  items,
}) => {
  if (!primary) throw Error();

  const { content, buttons, media } = primary;

  return (
    <BlockContainer>
      <Grid
        borderRadius="lg"
        bg="yellow-0"
        p={{ base: "space-24", md: "space-80" }}
        gridTemplateColumns={{
          base: "auto",
          md: "auto 1fr",
        }}
        gridGap={{ base: "space-8", md: "space-40" }}
        w="full"
      >
        <Box alignSelf="center" w={{ md: "382px" }}>
          <Box
            as={GatsbyImage}
            image={media?.document?.data?.image_file?.gatsbyImageData}
            alt={media?.document?.data?.image_file?.alt ?? ""}
          />
        </Box>
        <Box
          sx={{
            p: {
              w: { base: "full", md: "auto" },
              _last: {
                marginBottom: 0,
              },
            },
            ".chakra-button": {
              w: { base: "full", md: "auto" },
            },
          }}
        >
          <VStack
            textAlign={{ base: "left" }}
            spacing={{ base: "space-16", md: "space-24" }}
            align={{ base: "center", md: "flex-start" }}
          >
            <VStack
              spacing={{ base: "space-16" , md: "space-8"}}
              align={{ base: "center", md: "flex-start" }}
            >
              <Renderer
                overrides={{
                  [Element.heading2]: (args) =>
                    heading2({
                      ...args,
                      overrideProps: {
                        marginBottom: 0,
                      },
                    }),
                  [Element.heading3]: (args) =>
                    heading3({
                      ...args,
                      overrideProps: {
                        variant: "subtitle-large",
                        marginBottom: 0,
                      },
                    }),
                }}
                field={content}
              />
            </VStack>
            {items && items.length > 0 && (
              <VStack align="flex-start" spacing="space-8" w="full">
                {items?.map((item) => {
                  if (!item) return null;
                  return (
                    <HStack
                      key={item.label}
                      spacing="space-8"
                      align="flex-start"
                    >
                      <Center
                        w={{ base: "28px", md: "32px" }}
                        h={{ base: "28px", md: "32px" }}
                        flexShrink="0"
                        bg="yellow-3"
                        borderRadius="md"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <SvgOrImg
                          imageFile={item.icon?.document?.data?.image_file}
                          isBox={true}
                          size={16}
                        />
                      </Center>

                      <Box>
                        <Text
                          as="div"
                          textAlign="left"
                          variant="body-small"
                          mt={{ base: "3px", md: "8px" }} //required for the center alignment
                        >
                          {item.label}
                        </Text>
                      </Box>
                    </HStack>
                  );
                })}
              </VStack>
            )}
            <Renderer field={buttons} />
          </VStack>
        </Box>
      </Grid>
    </BlockContainer>
  );
};

export default BlockMission;
