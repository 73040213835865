import {
  VStack,
  Box,
  Heading,
  Wrap,
  WrapItem,
  Flex,
  Text,
  Center,
  Container
} from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, PrismicLink } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import Renderer from "src/lib/renderer";
import ElementAnimation from "src/components/elements/ElementAnimation";
import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";

const BlockIllustratedList: FC<Gatsby.PageBlockIllustratedListFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  return (
    <BlockContainer>
      <Container variant="heading">
        <VStack spacing="space-16">
          <Renderer field={content} />
        </VStack>
      </Container>

      <Wrap
        justify="center"
        spacing="space-40"
        marginTop={{ base: "space-8", md: "space-32" }}
        maxWidth={{ base: "unset", md: "1010px" }}
      >
        {items.map((item) => {
          if (!item) return null;

          const { title, media, link, content: itemContent } = item;
          const isLink = link?.uid || link?.url;
          const WrapComponent = isLink ? PrismicLink : Box;

          return (
            <WrapItem
              key={title}
              alignItems="start"
              width={{
                base: "70%",
                sm: "240px",
                md: "250px",
                lg: "310px",
              }}
            >
              <WrapComponent link={link!} role={isLink ? "group" : undefined}>
                <Flex
                  w="100%"
                  textAlign={itemContent?.text ? "left" : "center"}
                  direction="column"
                  alignItems="stretch"
                >
                  <Center
                    overflow="hidden"
                    isolation="isolate" //required to fix safari ui bug
                    borderRadius="lg"
                    marginBottom={{
                      base: "space-16",
                      sm: "space-24",
                    }}
                    sx={{ img: { borderRadius: "lg", overflow: "hidden" } }} //required to override gatsby image's default css
                  >
                    {media?.document?.__typename === "PrismicElementImage" ? (
                      <Box
                        as={GatsbyImage}
                        maxHeight={{ base: "250px", sm: "inherit" }}
                        image={media.document.data?.image_file?.gatsbyImageData}
                        alt={media.document.data?.image_file?.alt ?? ""}
                        _groupHover={{
                          transform: "scale(1.04)",
                        }}
                        _groupActive={{
                          transform: "scale(1.03)",
                        }}
                        transitionProperty="all"
                        transitionDuration="slower"
                        transitionTimingFunction="ease-out"
                      />
                    ) : media?.document?.__typename ===
                      "PrismicElementAnimation" ? (
                      <ElementAnimation data={media.document.data} />
                    ) : null}
                  </Center>

                  <Box>
                    <Heading as="h3" variant="heading-4" marginBottom="space-8">
                      {title}
                    </Heading>

                    <Text as="div">
                      <Renderer
                        field={itemContent}
                        overrides={{
                          [Element.paragraph]: (args) =>
                            paragraph({
                              ...args,
                              overrideProps: {
                                marginBottom: "space-0",
                              },
                            }),
                        }}
                      />
                    </Text>
                  </Box>
                </Flex>
              </WrapComponent>
            </WrapItem>
          );
        })}
      </Wrap>
    </BlockContainer>
  );
};

export default BlockIllustratedList;
