import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { cloneElement, FC, Fragment, useState } from "react";
import CarouselArrow from "./components/CarouselArrow";
import { motionVariants } from "./utils";

const Carousel: FC<{ items: any[] }> = ({ items }) => {
  const [[carouselIndex, direction], setCarouselIndex] = useState([0, 0]);

  const numberOfItems = useBreakpointValue({ base: 1, md: 2, lg: 3 }) ?? 0;
  const pagesNumber = Math.ceil(items.length / numberOfItems);
  const shouldShowArrows = Boolean(numberOfItems && pagesNumber > 1);

  return (
    <Box position="relative">
      {shouldShowArrows && (
        <Fragment>
          {carouselIndex > 0 && (
            <CarouselArrow
              aria-label="prev"
              onClick={() =>
                setCarouselIndex([
                  Math.max(carouselIndex - numberOfItems, 0),
                  -1,
                ])
              }
              position="absolute"
              transform="rotate(180deg)"
              left="-20px"
              top="50%"
            />
          )}
          {carouselIndex / numberOfItems < pagesNumber - 1 && (
            <CarouselArrow
              aria-label="next"
              onClick={() =>
                setCarouselIndex([carouselIndex + numberOfItems, 1])
              }
              position="absolute"
              right="-20px"
              top="50%"
            />
          )}
        </Fragment>
      )}

      <AnimatePresence custom={direction} exitBeforeEnter initial={false}>
        <motion.div
          custom={direction}
          initial="before"
          animate="in"
          exit="after"
          variants={motionVariants}
          transition={{ ease: "easeOut", duration: 0.2 }}
          key={carouselIndex}
        >
          <SimpleGrid
            templateColumns={{
              base: "repeat(1, 1fr)",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            spacing="space-32"
            paddingTop="space-64"
          >
            {Array(3)
              .fill("")
              .map((_, i) => {
                const item = items[carouselIndex + i];

                return (
                  item &&
                  cloneElement(item, {
                    display: {
                      base: i <= 0 ? "block" : "none",
                      md: i <= 1 ? "block" : "none",
                      lg: i <= 2 ? "block" : "none",
                    },
                  })
                );
              })}
          </SimpleGrid>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default Carousel;
