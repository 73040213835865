export const formatPrice = (price?: number) => {
  if (!price && price !== 0) {
    return '';
  }

  const stringPrice = price.toLocaleString("fr-FR", {
    minimumFractionDigits: price % 1 ? 2 : 0,
    maximumFractionDigits: 2,
  });

  return stringPrice;
};
