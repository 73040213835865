import type { FC } from "react";
import ToolboxOffers from "./Toolbox/ToolboxOffers";

enum PrebuiltBlockType {
  Toolbox = "toolbox",
}

const PrebuiltBlock: FC<Gatsby.PagePrebuiltBlockFragment> = ({ primary }) => {
  if (primary?.type !== PrebuiltBlockType.Toolbox)
    throw Error('Only "toolbox" is supported');

  return <ToolboxOffers />;
};

export default PrebuiltBlock;
