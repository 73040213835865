import { FC } from "react";

import { chakra, Text, TextProps } from "@chakra-ui/react";
import { formatPrice } from "src/lib/pricing";

export const CardPrice: FC<
  TextProps & {
    price: number;
    priceUnit: string;
  }
> = ({ price, priceUnit, ...rest }) => {
  const stringPrice = formatPrice(price);

  return (
    <Text
      fontWeight="medium"
      position="relative"
      display="inline-block"
      fontSize={{ base: "font-16", md: "font-25" }}
      lineHeight={{ md: "none" }}
      {...rest}
    >
      <chakra.span position="relative" zIndex="1">
        {stringPrice} {priceUnit}
      </chakra.span>
      <chakra.span
        h="12px"
        w="100%"
        position="absolute"
        bottom="0"
        left="0"
        bg="lightBlue-4"
        borderRadius="lg"
      />
    </Text>
  );
};
