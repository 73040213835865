import {
  VStack,
  SimpleGrid,
  Box,
  Heading,
  HStack,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, PrismicLink, IconBox } from "src/atoms";
import Renderer from "src/lib/renderer";
import ShiButton from "src/atoms/ShiButton";

import { heading2 } from "src/lib/renderer/elements/headings";
import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";
import { SvgOrImg } from "src/components/SvgOrImg";

type NumberOfColumnOption = "2 columns" | "3 columns";

const BlockMatrix: FC<
  Gatsby.PageBlockMatrixFragment & {
    primary: {
      number_of_columns: NumberOfColumnOption | null;
    };
  }
> = ({ primary, items }) => {
  if (!primary || !items) throw Error();

  const { content, number_of_columns } = primary;

  return (
    <BlockContainer>
      <VStack
        spacing="space-24"
        width="full"
        color="text-secondary"
        bg="greyLight-4"
        borderRadius="lg"
        paddingY={{ base: "space-24", md: "space-80" }}
        paddingX={{ base: "space-24", md: "space-80" }}
      >
        <VStack
          spacing={{ base: "space-16", md: "space-24" }}
          maxW={{ md: "70%" }}
        >
          <Renderer
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    variant: "subtitle",
                  },
                }),
            }}
            field={content}
          />
        </VStack>

        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: number_of_columns === "3 columns" ? 3 : 2,
          }}
          spacing="space-40"
          paddingTop={{ base: "space-16", md: "space-56" }}
          width="100%"
          maxW={{ md: number_of_columns !== "3 columns" ? "90%" : "unset" }}
        >
          {items?.map((item) => {
            if (!item) return null;

            const icon = item.icon?.document;

            return (
              <HStack
                textAlign="left"
                spacing={{ base: "space-16" }}
                alignItems="flex-start"
                key={item?.description}
              >
                {icon?.__typename === "PrismicElementImage" ? (
                  <IconBox>
                    <SvgOrImg
                      additionalBoxProps={{ boxSize: "24px", flexShrink: 0 }}
                      imageFile={icon.data?.image_file}
                      isBox={true}
                      size={24}
                    />
                  </IconBox>
                ) : null}

                <Box>
                  <Heading as="h3" variant="subtitle" marginBottom="space-16">
                    {item?.label}
                  </Heading>

                  <Text as="div" variant="body">
                    {item?.description}
                  </Text>

                  {item.link_label && item.link && (
                    <ShiButton
                      as={PrismicLink}
                      link={item.link}
                      variant="secondary"
                      marginTop="space-24"
                    >
                      {item.link_label}
                    </ShiButton>
                  )}
                </Box>
              </HStack>
            );
          })}
        </SimpleGrid>
      </VStack>
    </BlockContainer>
  );
};

export default BlockMatrix;
