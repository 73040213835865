import { FC, useState, useEffect, Fragment } from "react";
import SLICES from "src/slices";
import SliceZone from "../SliceZone";

import { useLocation } from "@reach/router";

const SubPages: FC<Gatsby.PageSubPagesFragment> = ({ primary, items }) => {
  const location = useLocation();
  if (!primary) throw Error();

  const [active, setActive] = useState(primary.default_section);

  useEffect(() => {
    if (location) {
      const params = new URL(location.href).searchParams;
      const section = params.get("section");

      if (section) {
        setActive(section);
      }
    }
  }, [location]);

  const visibleTab = items?.find((item) => item?.section === active);

  return (
    <Fragment>
      {visibleTab && (
        <SliceZone
          body={visibleTab?.content?.document?.data?.body}
          section={visibleTab.section}
          slices={{ ...SLICES }}
        />
      )}
    </Fragment>
  );
};

export default SubPages;
