import { Box, Container, HStack, Text, VStack, Flex } from "@chakra-ui/react";
import { FC, Fragment } from "react";

import { ShiIcon } from "src/atoms";
import Renderer from "src/lib/renderer";
import { GatsbyImage } from "gatsby-plugin-image";
import StickyBanner from "./components/StickyBanner";

import { Element } from "@prismicio/react";
import paragraph from "src/lib/renderer/elements/paragraph";

const Desktop: FC<
  Gatsby.PageBlockPricingTableItemsFragment & { shouldAnimate?: boolean }
> = ({ items, shouldAnimate }) => {
  const pricingPlans =
    items?.map(
      (item) => item?.plan?.document?.data?.pricing_table?.document?.data?.body,
    ) || [];

  const tableSections: Array<string | undefined> =
    (pricingPlans[0] &&
      pricingPlans[0].map((section) => section?.primary?.title1)) ||
    [];

  const desktopTable = tableSections.reduce(
    (
      acc: { sectionTitle?: string; items: any[] }[],
      curr: string | undefined,
    ) => {
      let data: { sectionTitle?: string; items: any[] }[] = [...acc];

      // look for current section in each plans
      const sectionFeatures = pricingPlans?.map((plan) =>
        plan?.find((s) => s?.primary?.title1 === curr),
      );

      //current section data - this will be used later to render table
      const featureData: {
        title?: string;
        description?: string;
        badge?: Gatsby.Maybe<Pick<Gatsby.PrismicStructuredTextType, "raw">>;
        items: {
          item: any;
        }[];
      }[] = [];

      //loop through items of current section in each plans
      sectionFeatures?.forEach((s) => {
        s?.items?.forEach((item) => {
          const fd = featureData.find((f) => f.title === item?.feature_title);

          if (!fd) {
            featureData.push({
              title: item?.feature_title,
              description: item?.feature_description,
              badge: item?.feature_badge,
              items: [{ item }],
            });
          } else {
            fd.items = [...fd?.items, { item }];
          }
        });
      });

      data = [...acc, { sectionTitle: curr, items: featureData }];

      return data;
    },
    [],
  );

  return (
    <Box size="full" display={{ base: "none", md: "block" }}>
      <StickyBanner items={items} shouldAnimate={shouldAnimate} />

      {/* Table */}

      <Container size="block">
        <VStack w="full" spacing="space-48" maxW="1000px" mx="auto">
          {desktopTable.filter(Boolean).map((data) => (
            <Box key={data.sectionTitle || ""} w="full">
              <Box mb="space-16">
                <Text
                  color="text-light"
                  textTransform="uppercase"
                  fontSize="font-13"
                >
                  {data.sectionTitle}
                </Text>
              </Box>
              <VStack spacing="0" w="full">
                {data.items.filter(Boolean).map((item) => {
                  if (!item) return null;

                  const { items, title, description, badge } = item;

                  return (
                    <HStack
                      key={item.title}
                      spacing="0"
                      borderBottom="1px solid"
                      borderColor="greyLight-4"
                      py="space-16"
                      w="full"
                    >
                      <VStack textAlign="left" spacing="space-4" flex="1">
                        <Text
                          w="full"
                          fontWeight="medium"
                          color="text-secondary"
                          fontSize="font-15"
                        >
                          {title}
                        </Text>
                        {description && (
                          <Text w="full" color="text-light" fontSize="font-13">
                            {description}
                          </Text>
                        )}
                        {badge && (
                          <Renderer
                            field={badge}
                            overrides={{
                              [Element.paragraph]: (args) =>
                                paragraph({
                                  ...args,
                                  overrideProps: {
                                    w: "full",
                                    sx: {
                                      ".chakra-badge": {
                                        fontSize: "font-10",
                                        textTransform: "upperCase",
                                        paddingX: "8px !important",
                                        bg: "green-1",
                                        color: "green-0",
                                      },
                                      ".badge-blue": {
                                        bg: "blue-3",
                                        color: "blue-4",
                                      },
                                    },
                                  },
                                }),
                            }}
                          />
                        )}
                      </VStack>
                      {items.map((plan, index) => {
                        if (!plan) return null;

                        //it's a hacky solution, but if there's icon + text, text should always begin at the same position.
                        //by finding if there's one item with the very long text, this will decide whether text box should have minimum
                        //height to keep the alignment

                        const shouldBeAligned = items.some(
                          (p) =>
                            p.item.feature_icon?.gatsbyImageData &&
                            p.item.feature_text.text?.length >= 33, //33 is the maximum length of a text to fit in one line at desktop screen size
                        );

                        const key = `${plan.item.feature_title}-${index}`;

                        return (
                          <Box
                            flex={1}
                            key={key}
                            px="space-16"
                            py="space-18"
                          >
                            <Flex
                              justifyContent="center"
                              alignItems="center"
                              textAlign="center"
                            >
                              {!plan.item.feature_included ? (
                                <ShiIcon
                                  name="cross"
                                  fill="greyLight-1"
                                  w="16px"
                                  h="16px"
                                />
                              ) : (
                                <Fragment>
                                  {plan.item.feature_icon?.gatsbyImageData ||
                                  (plan.item.feature_text?.text &&
                                    plan.item.feature_text?.text !== "") ? (
                                    <VStack
                                      spacing="space-4"
                                      sx={{
                                        p: {
                                          fontSize: "font-15",
                                          color: "text-primary",
                                          _last: {
                                            marginBottom: 0,
                                          },
                                        },
                                      }}
                                    >
                                      {plan.item.feature_icon
                                        ?.gatsbyImageData && (
                                        <GatsbyImage
                                          image={
                                            plan.item.feature_icon
                                              ?.gatsbyImageData
                                          }
                                          alt={
                                            plan.item.feature_icon?.alt || ""
                                          }
                                          objectFit="contain"
                                        />
                                      )}
                                      {plan.item.feature_text && (
                                        <Box
                                          minH={
                                            shouldBeAligned ? "60px" : "unset"
                                          }
                                        >
                                          <Renderer
                                            field={plan.item.feature_text}
                                          />
                                        </Box>
                                      )}
                                    </VStack>
                                  ) : (
                                    <ShiIcon name="check" w="16px" h="16px" />
                                  )}
                                </Fragment>
                              )}
                            </Flex>
                          </Box>
                        );
                      })}
                    </HStack>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </VStack>
      </Container>
    </Box>
  );
};

export default Desktop;
