import { VStack, Container } from "@chakra-ui/react";

import { FC } from "react";
import Renderer from "src/lib/renderer";

import { BlockContainer } from "src/atoms";

import MobileAccordion from "./components/MobileAccordion";
import DesktopGrid from "./components/DesktopGrid";

const BlockCreationRedirection: FC<
  Gatsby.PageBlockCreationRedirectionFragment
> = ({ primary, items }) => {
  if (!primary) throw Error();

  const { content } = primary;

  return (
    <BlockContainer>
      <VStack spacing={{ base: "space-24", md: "space-40" }}>
        <Container variant="heading">
          <VStack
            spacing="space-16"
            sx={{
              "h2, h3": {
                marginBottom: 0,
              },
              "h2, h3, p": {
                color: "greyLight-3",
              },
            }}
          >
            <Renderer field={content} />
          </VStack>
        </Container>

        <MobileAccordion items={items} />
        <DesktopGrid items={items} />
      </VStack>
    </BlockContainer>
  );
};

export default BlockCreationRedirection;
