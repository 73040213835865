const THEME_YELLOW = ["yellow-0", "yellow-1", "yellow-2"];

const THEME_BLUE = ["blue-1", "blue-2", "blue-3"];

const THEME_GREY = ["greyLight-1", "greyLight-2", "greyLight-4"];

export const getItemBackground = (theme: string, index: number) => {
  switch (theme) {
    case "blue":
      return THEME_BLUE[index];

    case "grey":
      return THEME_GREY[index];

    default:
      return THEME_YELLOW[index];
  }
};
