import { FC, useState, useEffect } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import {
  AspectRatio,
  Grid,
  GridItem,
  keyframes,
  useBreakpointValue,
} from "@chakra-ui/react";
import { clamp } from "../utils";
import { useInView } from "react-intersection-observer";
import { isIOS, isSafari } from "react-device-detect";
import { DEFAULT_ASPECT_RATIO, DEFAULT_DURATION, DESKTOP_SECTION_WIDTH } from "./constants";
import Item from "./components/Item";
import { useLocation } from "@reach/router";

const progressBarFillingIn = keyframes({
  from: {
    width: "0"
  },

  to: {
    width: '100%'
  }
});

const progressBarFillingOut = keyframes({
  from: {
    width: "100%"
  },

  to: {
    width: '0'
  }
});

const BlockAnimation: FC<Gatsby.PageBlockAnimationFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();
  if (!items) throw Error();

  if ((items?.length ?? 0) <= 0) {
    throw Error();
  }

  const { search } = useLocation();

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const {
    content
  } = primary;

  const [itemIndex, setItemIndex] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const numberItems = items?.length || 0;

  const desktopVideoMP4 = items[itemIndex]?.video_mp4?.url;
  const desktopVideoWebM = items[itemIndex]?.video_webm?.url;

  const desktopPlaceholderImageUrl = items[itemIndex]?.placeholder_image?.document?.data?.image_file?.url;

  const aspectRatio = items[itemIndex]?.aspect_ratio || DEFAULT_ASPECT_RATIO;
  const duration = clamp(0, Number.MAX_SAFE_INTEGER, DEFAULT_DURATION, items[itemIndex]?.duration);

  // @see https://www.reddit.com/r/webdev/comments/1dpoxu6/webm_playback_is_broken_in_safari_175/
  const forceMP4 = isIOS || isSafari;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const videoElement = document.getElementById('desktop-video') as HTMLVideoElement;
      const videoMP4Element = document.getElementById("desktop-video-mp4");
      const videoWebMElement = document.getElementById("desktop-video-webm");
      if (videoElement) {
        videoElement.pause();

        if (videoMP4Element && desktopVideoMP4) {
          videoMP4Element.setAttribute("src", desktopVideoMP4);
        }

        if (videoWebMElement && desktopVideoWebM) {
          videoWebMElement.setAttribute("src", desktopVideoWebM);
        }

        videoElement.load();
      }
    }
  }, [desktopVideoMP4, desktopVideoWebM]);

  useEffect(() => {
    if (!isMounted || isMobile) {
      return;
    }

    const to = setTimeout(() => {
      setItemIndex((itemIndex) => (itemIndex + 1) % numberItems);
    }, duration);

    return () => clearTimeout(to);
  }, [itemIndex, isMounted, duration]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const video = params.get('video');

    if (video) {
      setItemIndex(Number(video));
      scrollIntoView();
    }
  }, [search]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const scrollIntoView = () => {
    const target = document.querySelector(".block-animation");
    if (target && window !== undefined) {
      window.scrollTo({
        top: target.getBoundingClientRect().top + window.scrollY - 24,
        behavior: "smooth"
      });
    }
  }

  const { ref: desktopRef, inView: desktopInView } = useInView({
    rootMargin: "50px 0px 50px 0px",
    triggerOnce: true,
  });

  return (
    <BlockContainer
      className="block-animation"
      pr={{ base: 0, md: 'space-58' }}
      pl={{ base: 0, md: 'space-58' }}
    >
      <Renderer
        field={content}
      />
      <Grid
        gridRow={1}
        gridTemplateColumns={{
          base: "auto",
          md: `${DESKTOP_SECTION_WIDTH}px auto`
        }}
        gridRowGap={{
          base: "space-40",
          md: 0
        }}
        gridColumnGap={{
          base: 0,
          md: "52px"
        }}
        w="full"
      >
        <GridItem
          minWidth={{ base: "full", md: `${DESKTOP_SECTION_WIDTH}px` }}
          maxWidth={{ base: "full", md: `${DESKTOP_SECTION_WIDTH}px` }}
        >
          {items.map((item, index) => {
            if (!item) {
              return null;
            }

            const progressBarRemainingWidthProps = itemIndex !== index ? {
              width: "100%"
            } : {};

            return (
              <Item
                key={`${item.title}-${item.description}`}
                {...item}
                selected={isMobile ? true : itemIndex === index}
                desktopInView={desktopInView}
                forceMP4={forceMP4}
                progressBarFillingIn={progressBarFillingIn}
                progressBarFillingOut={progressBarFillingOut}
                duration={duration}
                progressBarRemainingWidthProps={progressBarRemainingWidthProps}
                onClick={() => setItemIndex(index)}
                containerProps={{
                  marginTop: !isMobile && index === 0 ? "0" : "8px"
                }}
              />
            )
          })}
        </GridItem>

        <GridItem
          display={{ base: "none", md: "block" }}
          ref={desktopRef}
        >
          {desktopVideoMP4 || desktopVideoWebM ? <AspectRatio
            width="100%"
            ratio={aspectRatio}
            overflow="hidden"
            borderRadius="lg"
            objectFit="cover"
          >
            {desktopVideoMP4 || desktopVideoWebM
              ? (<video id="desktop-video" poster={desktopPlaceholderImageUrl} autoPlay loop muted playsInline>
                {desktopInView && desktopVideoWebM && !forceMP4 ? <source id="desktop-video-webm" src={desktopVideoWebM} type="video/webm" /> : null}
                {desktopInView && desktopVideoMP4 ? <source id="desktop-video-mp4" src={desktopVideoMP4} type="video/mp4" /> : null}
              </video>) : null}
          </AspectRatio> : null}
        </GridItem>
      </Grid>
    </BlockContainer>
  );
};

export default BlockAnimation;
