import { BodyBlockMediaPosition, BodyBlockVariation } from "./types";

const TEMPLATE = {
  SMALL_LARGE: "1fr 2fr",
  MEDIUM_MEDIUM: "1fr 1fr",
  LARGE_SMALL: "2fr 1fr",
};

export const getGridTemplate = (
  variation: BodyBlockVariation | null,
  mediaPosition: BodyBlockMediaPosition | null,
) => {
  switch (variation) {
    case null:
    case BodyBlockVariation.MEDIUM_MEDIA:
      return TEMPLATE.MEDIUM_MEDIUM;

    case BodyBlockVariation.SMALL_MEDIA:
      return mediaPosition === "left"
        ? TEMPLATE.SMALL_LARGE
        : TEMPLATE.LARGE_SMALL;

    default:
      return mediaPosition === "left"
        ? TEMPLATE.LARGE_SMALL
        : TEMPLATE.SMALL_LARGE;
  }
};

export const getContentAlignment = (alignment?: string) => {
  switch (alignment) {
    case "center":
      return "center";

    case "bottom":
      return "flex-end";

    default:
      return "flex-start";
  }
};
