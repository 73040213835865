import {
  Box,
  Center,
  Container,
  Flex,
  Grid,
  Tab,
  TabList,
  Tabs,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import Renderer from "src/lib/renderer";
import PricingDisplay from "./Display";
import PricingTab from "./Tab";

const BOTTOM_OVERFLOW = 525;

const ANIMATING_DISTANCE = "20vw";

const motionVariants = {
  before: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),
  in: {
    opacity: 1,
    x: 0,
  },
  after: (direction: number) => ({
    opacity: 0,
    x: direction < 0 ? ANIMATING_DISTANCE : `-${ANIMATING_DISTANCE}`,
  }),
};

type Body = readonly Gatsby.Maybe<
  | ({
      readonly __typename: "PrismicCollectionPricingsDataBodyPricingDisplay";
    } & Gatsby.CollectionPricingsDisplayFragment)
  | ({
      readonly __typename: "PrismicCollectionPricingsDataBodyPricingTab";
    } & Gatsby.CollectionPricingsTabFragment)
>[];
interface Tab {
  tabData: Gatsby.CollectionPricingsTabFragment;
  displays: Gatsby.CollectionPricingsDisplayFragment[];
}
/**
 * Return an array regrouping corresponding tab name and plan display elements together
 * @param {Body} body - An array of tab name or plan display elements which should be ordered in this way:
 * TabName1, Display1A, Display 1B, TabName2, Display2A, Display2B
 */
const getTabList = (body: Body): Tab[] => {
  const tabList: Tab[] = [];
  let currentTab: Tab | null = null;

  for (let i = 0; i < body.length; i++) {
    const element = body[i];

    if (element?.__typename === "PrismicCollectionPricingsDataBodyPricingTab") {
      if (currentTab) {
        tabList.push(currentTab);
      }
      currentTab = {
        tabData: element,
        displays: [],
      };
      continue;
    }

    if (
      element?.__typename === "PrismicCollectionPricingsDataBodyPricingDisplay"
    ) {
      if (currentTab) {
        currentTab.displays.push(element);
      }
      continue;
    }
  }

  if (currentTab) {
    tabList.push(currentTab);
  }

  return tabList;
};

const CollectionPricings: FC<Gatsby.CollectionPricingsFragment> = ({
  data,
}) => {
  if (!data) throw Error();
  const { content, body } = data;
  if (!content || !body) throw Error();

  const tabList = getTabList(body);
  const [[tabIndex, direction], setTab] = useState([0, 0]);
  const currentTab = tabList[tabIndex];

  return (
    <Container paddingX="0">
      <Box
        as={Center}
        paddingTop="space-48"
        paddingBottom={`${BOTTOM_OVERFLOW}px`}
        marginBottom={`-${BOTTOM_OVERFLOW}px`}
        backgroundColor="ctaBlue-6"
        backgroundImage={`url('/images/sasu-pricing-bg.svg')`}
        backgroundSize="1200px"
        backgroundRepeat="no-repeat"
        backgroundPosition="bottom center"
      >
        <Flex
          direction="column"
          align="center"
          maxW="730px"
          paddingY="space-48"
          sx={{
            h2: {
              fontSize: "font-40",
            },
          }}
          textAlign="center"
        >
          <Renderer field={content} />
        </Flex>
      </Box>

      <Tabs
        variant="soft-rounded"
        index={tabIndex}
        onChange={(i) => i !== tabIndex && setTab([i, i - tabIndex])}
        align="center"
        marginTop="space-16"
      >
        {tabList.length > 1 && (
          <TabList
            width="fit-content"
            marginBottom="space-40"
            backgroundColor="white"
            borderRadius="full"
            paddingY="space-4"
            paddingX="space-0"
            sx={{
              "> * + *": {
                marginLeft: "space-16",
              },
            }}
          >
            {tabList.map((tab) => (
              <PricingTab key={tab.tabData.primary?.name} tab={tab.tabData} />
            ))}
          </TabList>
        )}

        <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
          <motion.div
            key={`plan-${tabIndex}`}
            custom={direction}
            initial="before"
            animate="in"
            exit="after"
            variants={motionVariants}
            transition={{ ease: "easeOut", duration: 0.2 }}
          >
            <Container size="block">
              <Grid
                width="100%"
                gap="space-16"
                gridTemplateColumns={{
                  base: `1fr`,
                  md: `repeat(${
                    currentTab?.displays.length ?? 0
                  }, minmax(0, 400px))`,
                }}
                justifyContent="center"
              >
                {currentTab?.displays.map((display) => (
                  <PricingDisplay
                    key={display.primary?.title}
                    display={display}
                  />
                ))}
              </Grid>
            </Container>
          </motion.div>
        </AnimatePresence>
      </Tabs>
    </Container>
  );
};

export default CollectionPricings;
