import { Box, Center, Grid, Text, VStack } from "@chakra-ui/react";

import { FC, Fragment } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";

import { Element } from "@prismicio/react";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { getMediaStyle, getSpeed } from "./utils";

import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "src/slices/Header";
import ParallaxMedia from "./atoms/ParallaxMedia";

const MOBILE_MARGIN = 100 // to add space at the top and bottom of sticky text to prevent text being over image

const BlockAnimatedTestimonial: FC<
  Gatsby.PageBlockAnimatedTestimonialFragment
> = ({ primary, items }) => {
  if (!primary) throw Error();

  const { buttons, content } = primary;

  return (
    <BlockContainer paddingX="0" paddingY="0" maxWidth="unset">
      <Center
        zIndex="1"
        position="sticky"
        top={{
          base: `${NAVBAR_HEIGHT_MOBILE}px`,
          md: `${NAVBAR_HEIGHT}px`,
        }}
        height={{
          base: `calc(100vh - ${NAVBAR_HEIGHT_MOBILE}px)`,
          md: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
        }}
        left="0"
        overflow="hidden"
        paddingX="space-16"
        marginY={{ base: `${MOBILE_MARGIN}px` , md: 0}}
      >
        <Box maxWidth="650px" mx="auto">
          <VStack spacing="space-32" sx={{ p: { color: "text-secondary" } }}>
            <Renderer
              overrides={{
                [Element.heading2]: (args) =>
                  heading2({
                    ...args,
                    overrideProps: {
                      variant: "heading-1",
                      marginBottom: 0,
                    },
                  }),
                [Element.heading3]: (args) =>
                  heading3({
                    ...args,
                    overrideProps: {
                      variant: "heading-4",
                      marginBottom: 0,
                    },
                  }),
              }}
              field={content}
            />
          </VStack>
          <Text as="div" marginTop="space-80">
            <Renderer field={buttons} />
          </Text>
        </Box>
      </Center>

      <Box
        maxW="100vw"
        overflow="hidden"
        w="full"
        marginTop={{
          base: `calc(-100vh + ${NAVBAR_HEIGHT_MOBILE}px - ${MOBILE_MARGIN * 2}px)`,
          md: `calc(-100vh + ${NAVBAR_HEIGHT}px)`,
        }}
      >
        <Grid
          maxWidth="1434px"
          mx="auto"
          w="full"
          gridTemplateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
          overflow="visible"
          minH="100vh"
          sx={{
            "@media(max-height: 800px)": {
              minH: "800px",
            },
            ".media-0": {
              transform: {
                base: "translateX(calc(50vw - 16px))",
                md: "translateX(58px)",
                "2xl": "translateX(0px)",
              },
            },
            ".media-1": {
              transform: {
                base: "translateX(calc(-50vw + 16px))",
                md: "translateX(calc(-33vw + 180px)) ",
                "2xl": "translateX(-340px)",
              },
            },
            ".media-2": {
              transform: {
                base: "translateX(-16px)",
                md: "translateX(-58px)",
                "2xl": "translateX(0)",
              },
            },
          }}
        >
          {items?.map((item, index) => {
            if (!item) return null;
            return (
              <Fragment key={item?.image?.id}>
                <ParallaxMedia
                  item={item}
                  maxWidth={{ base: "50vw", md: "33vw" }}
                  display={{ base: index === 2 ? "none" : "flex", md: "flex" }}
                  justifyContent={index === 2 ? "flex-end" : "flex-start"}
                  speed={getSpeed(index)}
                  imageProps={{ className: `media-${index}`, minW: "180px" }}
                  className={`media-container-${index}`}
                  imageContainerProps={{
                    paddingTop: getMediaStyle(index, false),
                    sx: {
                      "@media(max-height: 800px)": {
                        paddingTop: getMediaStyle(index, true),
                      },
                    },
                  }}
                />
              </Fragment>
            );
          })}
        </Grid>
      </Box>
    </BlockContainer>
  );
};

export default BlockAnimatedTestimonial;
