import { Container, Stack, Text, Grid, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { BlockContainer, IconBox } from "src/atoms";
import Renderer from "src/lib/renderer";

import { Element } from "@prismicio/react";
import { heading2 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { SvgOrImg } from "src/components/SvgOrImg";

const getTemplateFor = (items: number) =>
  items <= 6
    ? `repeat(${items}, minmax(120px, 220px))`
    : `repeat(6, minmax(120px, 220px))`;

const BlockProductRecap: FC<Gatsby.PageBlockProductRecapFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  const numberOfItems = items.length;

  return (
    <BlockContainer>
      <Container variant="heading">
        <VStack
          direction="column"
          align="center"
          textAlign="center"
          spacing={{ base: "space-16", md: "space-24" }}
        >
          <Renderer
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                  },
                }),
              [Element.paragraph]: (args) =>
                paragraph({
                  ...args,
                  overrideProps: {
                    variant: "subtitle",
                  },
                }),
            }}
            field={content}
          />
        </VStack>
      </Container>

      <Grid
        templateColumns={{
          base: getTemplateFor(2),
          md:
            numberOfItems > 4
              ? getTemplateFor(3)
              : getTemplateFor(numberOfItems),
          lg: getTemplateFor(numberOfItems),
        }}
        gridGap="space-16"
        marginTop={{ base: "space-16" , md: "space-48"}}
      >
        {items?.map((item) => {
          if (!item) return null;
          const icon = item.icon?.document;

          return (
            <Stack
              spacing="space-16"
              alignItems="center"
              key={item.label}
              role="group"
              padding="space-8"
            >
              {icon?.__typename === "PrismicElementImage" ? (
                <IconBox
                  w={{ base: "40px", md: "56px" }}
                  h={{ base: "40px", md: "56px" }}
                  bg="greyLight-4"
                  borderRadius={{ base: "12px" , md: "lg"}}
                  _groupHover={{
                    transform: "scale(1.15)",
                  }}
                  transitionProperty="all"
                  transitionDuration="400ms"
                  transitionTimingFunction="ease-out"
                >
                  <SvgOrImg
                    imageFile={icon.data?.image_file}
                    isBox={true}
                    size={24}
                  />
                </IconBox>
              ) : null}

              <Text as="div" variant="body">
                {item.label}
              </Text>
            </Stack>
          );
        })}
      </Grid>
    </BlockContainer>
  );
};

export default BlockProductRecap;
