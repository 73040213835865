import Renderer from "src/lib/renderer";
import { Box, IconButton, VStack, Container } from "@chakra-ui/react";
import { FC, useState } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";

import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { Element } from "@prismicio/react";

import YouTube from "react-youtube";

const BlockVideo: FC<Gatsby.PageBlockVideoFragment> = ({ primary }) => {
  const [play, setPlay] = useState(false);
  const [video, setVideo] = useState<any>(null);

  if (!primary) throw Error();

  const { content, buttons, youtube_id, thumbnail } = primary;

  const playVideo = () => {
    if (video) {
      video?.playVideo();
    }
  };

  return (
    <BlockContainer>
      <Container variant="heading">
        <VStack mx="auto" spacing="space-8" w="full">
          <Renderer
            field={content}
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                  },
                }),
              [Element.heading3]: (args) =>
                heading3({
                  ...args,
                  overrideProps: {
                    variant: "heading-4",
                    fontSize: { base: "font-20", lg: "font-24" },
                  },
                }),
            }}
          />
        </VStack>
        <Box mt="space-40" w="full">
          <Box
            width="full"
            paddingBottom="56.25%"
            position="relative"
            sx={{
              ".videoblock-video": {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "1px solid transparent",
                borderRadius: "lg",
              },
            }}
          >
            <YouTube
              videoId={youtube_id}
              className="videoblock-video"
              onPlay={() => setPlay(true)}
              onEnd={() => setPlay(false)}
              onReady={(event) => setVideo(event.target)}
              opts={{
                playerVars: {
                  modestbranding: 1, //removes youtube logo in controls
                },
              }}
            />
            {thumbnail?.document && (
              <Box
                opacity={!play ? "1" : "0"}
                position="absolute"
                w="full"
                h="full"
                top="0"
                left="0"
                zIndex="1"
                pointerEvents={play ? "none" : "auto"}
                transitionProperty="all"
                transitionDuration="300ms"
                transitionTimingFunction="ease-out"
              >
                <Box w="full" h="full" position="relative">
                  {thumbnail?.document?.__typename ===
                    "PrismicElementImage" && (
                    <Box
                      w="full"
                      h="full"
                      as={GatsbyImage}
                      image={
                        thumbnail.document.data?.image_file?.gatsbyImageData
                      }
                      alt={thumbnail.document.data?.image_file?.alt ?? ""}
                      sx={{
                        img: {
                          borderRadius: "lg",
                        },
                      }}
                    />
                  )}
                  <IconButton
                    onClick={() => playVideo()}
                    aria-label="Play"
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    bg="transparent"
                    sx={{
                      ".play": {
                        path: {
                          fill: "grey-0",
                          _hover: {
                            fill: "grey-1",
                          },
                        },
                      },
                    }}
                    _hover={{ bg: "transparent" }}
                    icon={
                      <ShiIcon
                        name="play"
                        className="play"
                        w={{ base: "44px", md: "92px" }}
                        h={{ base: "44px", md: "92px" }}
                      />
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {buttons && buttons.text !== "" && (
          <Box mt="space-40">
            <Renderer field={buttons} />
          </Box>
        )}
      </Container>
    </BlockContainer>
  );
};

export default BlockVideo;
