import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";

import { FC } from "react";
import { BlockContainer, Card } from "src/atoms";
import Carousel from "src/atoms/Carousel";
import Renderer from "src/lib/renderer";

const BlockTestimonialCarousel: FC<Gatsby.PageBlockTestimonialCarouselFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  return (
    <BlockContainer withBackground={true}>
      <VStack spacing="space-12" alignItems="center">
        <Flex direction="column" align="center" maxW="730px">
          <Renderer field={content} />
        </Flex>

        <Carousel
          items={items.map((item) => {
            if (!item) return null;

            const {
              author_picture,
              author_name,
              author_job,
              testimonial_source,
              testimonial,
            } = item;

            return (
              <Card
                key={author_name}
                as={VStack}
                spacing="space-16"
                paddingY="space-32"
                fontSize="font-16"
                minHeight="500px"
              >
                {author_picture?.document?.__typename ===
                  "PrismicElementImage" && (
                  <Box
                    boxSize="space-64"
                    borderRadius="full"
                    as={GatsbyImage}
                    image={
                      author_picture.document.data?.image_file?.gatsbyImageData
                    }
                    alt={author_picture.document.data?.image_file?.alt ?? ""}
                  />
                )}

                <Text fontWeight="medium">{author_job}</Text>
                <Box flexGrow={1}>
                  <Renderer field={testimonial} />
                </Box>

                <VStack spacing="space-4" fontSize="font-14">
                  <Text fontWeight="medium">{author_name}</Text>
                  <Text>{testimonial_source}</Text>
                </VStack>
              </Card>
            );
          })}
        />
      </VStack>
    </BlockContainer>
  );
};

export default BlockTestimonialCarousel;
