import { Box, Button, Grid, HStack, List, ListItem, Select, Text, VStack } from "@chakra-ui/react";
import { FC, useState } from "react";
import { BlockContainer, ShiIcon } from "src/atoms";
import Card from "./components/Card";
import { ESort } from "..";
import sortItems from "../utils/sortItems";
import SearchInput from "src/atoms/SearchInput";

const Marketech: FC<{
  sort: ESort;
  sortOptions: Array<{
    value: ESort;
    label: string;
  }>;
  setSort: (sort: ESort) => void;
  search: string;
  setSearch: (search: string) => void;
  categories: Array<string>;
  indexSelectedCategory: number;
  setIndexSelectedCategory: (index: number) => void;
  items: Gatsby.PageBlockFilterableListFragment["items"];
  selectedItems: Gatsby.PageBlockFilterableListFragment["items"];
  buttonMore?: string;
  showButtonMore: boolean;
  onClickButtonMore: () => void;
}> = ({
  sort,
  sortOptions,
  setSort,
  search,
  setSearch,
  categories,
  indexSelectedCategory,
  setIndexSelectedCategory,
  items,
  selectedItems,
  buttonMore,
  showButtonMore,
  onClickButtonMore
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showCategories, setShowCategories] = useState(false);

  const sortedItems = sortItems(items, sort);

  return (
    <BlockContainer
      className="filterable-list"
      paddingBottom="0 !important"
      maxWidth={{ base: "480px", md: "100%", xl: "1440px" }}
      textAlign="left"
    >
      <HStack
        w="full"
        alignItems="center"
        justifyContent="space-between"
        mb="6"
        spacing={{ base: "space-8", lg: "5" }}
      >
        <Text
          flex="1"
          fontFamily="body"
          fontSize={{ base: "font-20", lg: "font-22" }}
          lineHeight="1.8"
        >
          Toutes les ressources
        </Text>

        <Text
          display={{ base: "none", lg: "block" }}
          color="grey.700"
          fontFamily="body"
          fontSize="font-16"
          lineHeight="base"
        >
          Trier par
        </Text>

        <Select
          display={{ base: "none", lg: "block" }}
          opentOn
          fontFamily="body"
          fontSize="font-16"
          lineHeight="base"
          onChange={(e) => setSort(e.target.value as ESort)}
          value={sort}
          variant="unstyled"
          w="auto"
        >
          {sortOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>

        <Box
          display={{ base: "flex", lg: "none" }}
          alignItems="center"
          justifyContent="center"
          w="space-32"
          h="space-32"
          borderRadius="full"
          border="1px solid"
          borderColor="#E0E0E0"
          cursor="pointer"
          onClick={() => setShowSearch(!showSearch)}
        >
          <ShiIcon
            name="magnifier"
            sx={{
              path: {
                stroke: "text-primary",
                strokeWidth: .5
              },
            }}
          />
        </Box>

        <Box
          display={{ base: "flex", lg: "none" }}
          alignItems="center"
          justifyContent="center"
          w="space-32"
          h="space-32"
          borderRadius="full"
          border="1px solid"
          borderColor="#E0E0E0"
          cursor="pointer"
          onClick={() => setShowCategories(!showCategories)}
        >
          <ShiIcon
            name="sort"
            sx={{
              path: {
                stroke: "text-primary",
                strokeWidth: .5
              },
            }}
          />
        </Box>
      </HStack>

      <Grid
        w="full"
        columnGap="120px"
        templateColumns={{ base: "1fr", lg: "auto 1fr" }}
      >
        <VStack gridGap={{ base: "0", lg: "10" }}>
          <SearchInput
            display={{ base: showSearch ? "block" : "none", lg: "block" }}
            buttonLeft
            mb={{ base: "4", lg: "0" }}
            width={{ base: "100%", lg: "158px" }}
            maxW={{ base: "100%", lg: "158px" }}
            value={search}
            onChange={setSearch}
            onSubmit={setSearch}
            placeholder="Rechercher"
          />

          <List
            display={{ base: showCategories ? "flex" : "none", lg: "flex" }}
            flexDirection={{ base: "row", lg: "column" }}
            alignItems={{ base: "center", lg: "flex-start" }}
            justifyContent={{ base: "center", lg: "flex-end" }}
            flexWrap="wrap"
            gridGap={{ base: "2", lg: "4" }}
            pb={{ base: "4", lg: "0" }}
            w="full"
          >
            {categories.map((category, index) => {
              const isSelected = index === indexSelectedCategory;

              return (
                <ListItem
                  backgroundColor={{ base: isSelected ? "grey-0" : "grey-5", lg: "transparent" }}
                  border={{ base: "1px solid", lg: "none" }}
                  borderBottom={{ base: "1px solid", lg: isSelected ? "1px solid" : "none" }}
                  borderColor={{ base: isSelected ? "grey-0" : "grey-3", lg: "grey-0" }}
                  color={{ base: isSelected ? "white" : "grey-0", lg: isSelected ? "grey-0" : "grey-1" }}
                  fontFamily="body"
                  fontSize="font-15"
                  fontWeight={isSelected ? "medium" : "normal"}
                  key={category}
                  lineHeight="base"
                  py="2"
                  px={{ base: "5", lg: "0" }}
                  borderRadius={{ base: "full", lg: "0" }}
                  cursor="pointer"
                  onClick={() => {
                    setIndexSelectedCategory(index);
                    setSearch("");
                  }}
                >
                  {category}
                </ListItem>
              )
            })}
          </List>
        </VStack>

        <VStack>
          <Grid
            columnGap="6"
            rowGap="6"
            templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
            gridAutoRows="max-content"
          >
            {sortedItems && sortedItems.length > 0 ? sortedItems.map((item) => {
              if (!item) {
                return null;
              }

              /**
               * Due to flickering issues, rather than rendering based on `selectedItems`
               * we render based on `items` then hide items with CSS based on `selectedItems`
               */
              const isDisplayed = selectedItems?.includes(item) || false;

              const key = `${item.primary_text || ''}-${item.secondary_text || ''}-${item.tertiary_text || ''}`;
              return (
                <Card
                  key={key}
                  isDisplayed={isDisplayed}
                  item={item}
                />
              )
            }) : null}
          </Grid>

          {showButtonMore ? (
            <Button
              alignSelf="center"
              paddingX="space-20"
              paddingY="space-8"
              key={buttonMore}
              variant="primary"
              display="inline-block"
              backgroundColor="grey-0"
              mt="24px !important"
              mb={{ base: "24px !important", md: "60px !important" }}
              fontWeight="medium"
              fontSize="16"
              lineHeight="base"
              borderColor="grey-0"
              borderWidth="1px"
              onClick={onClickButtonMore}
            >
              {buttonMore}
            </Button>
          ) : null}
        </VStack>
      </Grid>
    </BlockContainer>
  );
};

export default Marketech;
