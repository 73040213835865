import { FC, useState, useEffect, useContext } from "react";
import { BlockContainer } from "src/atoms";
import Renderer from "src/lib/renderer";
import {
  Button,
  Box,
  HStack,
  Grid,
  GridItem,
  Text
} from "@chakra-ui/react";
import { heading2 } from "src/lib/renderer/elements/headings";
import { Element } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { clamp } from "../utils";
import Marketech from "./MarkeTech";
import getCategories from "./utils/getCategories";
import getFilteredItems from "./utils/getFilteredItems";
import FilterableContext from "./context";
import { Link } from "gatsby";

export let ITEMS_PER_CATEGORY = 9;
export const WIDTH_CATEGORIES = "216px";
export enum ESort {
  Trending = "trending",
  CreatedAt = "createdAt",
}
export const OPTIONS = [
  { value: ESort.Trending, label: "Popularité" },
  { value: ESort.CreatedAt, label: "Date de création" },
];

const BlockFilterableList: FC<Gatsby.PageBlockFilterableListFragment> = ({
  primary,
  items
}) => {
  if (!primary) {
    throw Error();
  }

  const { filters, setFilters } = useContext(FilterableContext);

  const {
    content,
    button_more: buttonMore,
    categories: categoriesUnsafe,
    index_all_categories: indexAllCategoriesUnsafe,
    index_default_category: indexDefaultCategoryUnsafe,
    is_marketech
  } = primary;

  if (is_marketech) {
    ITEMS_PER_CATEGORY = 6;
  }

  const categoriesArray = getCategories(categoriesUnsafe);
  const categoriesCount = categoriesArray.length;

  // Content editors can decide not to have `All categories` by using -1 on the CMS
  const indexAllCategories = clamp(-1, categoriesCount - 1, 0, indexAllCategoriesUnsafe);
  // Content editors have to select a default category on the CMS
  const indexDefaultCategory = clamp(0, categoriesCount - 1, 0, indexDefaultCategoryUnsafe);

  const [indexSelectedCategory, setIndexSelectedCategory] = useState(filters.category.length > 0 ? categoriesArray.indexOf(filters.category) : indexDefaultCategory);
  const [sort, setSort] = useState(ESort.Trending);

  const [search, setSearch] = useState(filters.query);

  const onClickButtonMore = () => {
    const filteredItems = getFilteredItems(items, categoriesArray, indexSelectedCategory, indexAllCategories, sort, search);
    setShowButtonMore(false);
    setSelectedItems(filteredItems);
  }

  const initialFilteredItems = getFilteredItems(items, categoriesArray, indexSelectedCategory, indexAllCategories, sort, search);
  const [selectedItems, setSelectedItems] = useState(initialFilteredItems.slice(0, ITEMS_PER_CATEGORY));
  const [showButtonMore, setShowButtonMore] = useState(initialFilteredItems.length > ITEMS_PER_CATEGORY);

  useEffect(() => {
    let shouldScroll = false;
    const index = categoriesArray.indexOf(filters.category);
    if (index > -1 && index !== indexSelectedCategory) {
      setIndexSelectedCategory(index);
      shouldScroll = true;
    }

    if (filters.query !== search) {
      setSearch(filters.query);
      shouldScroll = true;
    }

    const target = document.querySelector(".filterable-list");
    if (target && shouldScroll) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [filters]);

  useEffect(() => {
    setFilters({
      query: search,
      category: categoriesArray[indexSelectedCategory],
    });

    const filteredItems = getFilteredItems(items, categoriesArray, indexSelectedCategory, indexAllCategories, sort, search);
    setShowButtonMore(filteredItems.length > ITEMS_PER_CATEGORY);

    setSelectedItems(filteredItems.slice(0, ITEMS_PER_CATEGORY));
  }, [indexSelectedCategory, items, sort, search]);

  if (is_marketech) {
    return (
      <Marketech
        sort={sort}
        sortOptions={OPTIONS}
        setSort={setSort}
        search={search}
        setSearch={setSearch}
        categories={categoriesArray}
        indexSelectedCategory={indexSelectedCategory}
        setIndexSelectedCategory={setIndexSelectedCategory}
        items={items}
        selectedItems={selectedItems}
        buttonMore={buttonMore}
        showButtonMore={showButtonMore}
        onClickButtonMore={onClickButtonMore}
      />
    );
  }

  const renderImg = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageFixed72Fragment>;
  }>) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        as={GatsbyImage}
        borderRadius="md"
        borderColor="grey-3"
        borderWidth="1px"
        image={imageFile.gatsbyImageData}
        alt={imageFile.alt ?? ""}
        marginRight="space-24"
        minWidth="72px"
      />
    );
  }

  const renderCategory = (text: string, indexCategory: number, inline?: boolean) => {
    const isSelectedCategory = text === categoriesArray[indexSelectedCategory];

    if (inline) {
      return renderInlineCategory(text, indexCategory, isSelectedCategory);
    }

    const isFirstCategory = indexCategory === 0;
    const marginPaddingProps = isFirstCategory ? {
      mt: "0",
      pt: "0 !important",
      pb: "space-8",
    } : {
      py: "space-8"
    }

    const renderFunction = isSelectedCategory ? renderSelectedCategory : renderUnselectedCategory;
    return renderFunction(text, indexCategory, marginPaddingProps);
  };

  const renderInlineCategory = (text: string, indexCategory: number, isSelected: boolean) => {
    const additionalProps = isSelected ? {
      backgroundColor: "grey-0",
      borderColor: "grey-0",
      _hover: {
        background: "grey-0",
      }
    } : {
      backgroundColor: "grey-5",
      borderColor: "grey-3",
      color: "grey-0",
      _hover: {
        background: "grey-5",
      },
      onClick: () => setIndexSelectedCategory(indexCategory)
    };

    return (
      <Button
        alignSelf="center"
        paddingX="space-20"
        paddingY="space-8"
        key={text}
        variant="primary"
        display="inline-block"
        mr="space-8"
        mb="space-8"
        fontWeight="medium"
        fontSize="16"
        lineHeight="base"
        borderWidth="1px"
        {...additionalProps}
      >
        {text}
      </Button>
    );
  };

  const renderSelectedCategory = (text: string, _indexCategory: number, marginPaddingProps: { [key: string]: string | undefined }) => {
    return (
      <Button
        fontWeight="500"
        fontSize="font-15"
        color="grey-0"
        key={text}
        display="block"
        variant="unstyled"
        lineHeight={2.8}
        sx={{
          "::first-line": {
            lineHeight: "base"
          },
        }}
        {...marginPaddingProps}
      >
        <Text
          as="span"
          pt="0"
          pb="space-4"
          borderBottom="2px solid"
          borderColor="grey-0"
        >
          {text}
        </Text>
      </Button>
    );
  };

  const renderUnselectedCategory = (text: string, indexCategory: number, marginPaddingProps: { [key: string]: string | undefined }) => {
    return (
      <Button
        fontWeight="500"
        fontSize="font-16"
        lineHeight="base"
        color="grey-1"
        variant="unstyled"
        key={text}
        display="block"
        onClick={() => setIndexSelectedCategory(indexCategory)}
        {...marginPaddingProps}
      >
        {text}
      </Button>
    );
  };

  const renderItem = (isDisplayed: boolean, item: NonNullable<Gatsby.PageBlockFilterableListFragment["items"]>[number]) => {
    if (!item) {
      return null;
    }

    const {
      icon,
      primary_text: primaryText,
      secondary_text: secondaryText,
      tertiary_text: tertiaryText,
      link,
    } = item;

    const key = `${primaryText || ''}-${secondaryText || ''}-${tertiaryText || ''}`;
    return (
      <GridItem
        as={link?.url ? Link : 'div'}
        {...(link?.url ? { to: link.url, target: link.target } : {})}
        paddingX={{ base: "0", md: "space-12" }}
        marginBottom={{ base: "space-24", md: "space-32" }}
        alignSelf="stretch"
        key={key}
        display={isDisplayed ? "block" : "none"}
      >
        <HStack
          align='flex-start'
          alignItems='flex-start'
          verticalAlign='top'
        >
          {renderImg(icon)}
          <Box
            marginLeft="space-0"
            marginInlineStart="0 !important"
          >
            {primaryText ? <Text
              color="grey-0"
              fontSize="font-24"
              lineHeight="shortest"
              marginBottom={{ base: "space-4", md: "space-8" }}
            >
              {primaryText}
            </Text> : null}
            {secondaryText ? <Text
              color="grey-0"
              fontSize="font-16"
              lineHeight="short"
              marginBottom={{ base: "space-4", md: "space-8" }}
            >
              {secondaryText}
            </Text> : null}
            {tertiaryText ? <Text
              color="greyLight-1"
              fontSize="font-16"
              lineHeight="short"
              marginBottom="0"
            >
              {tertiaryText}
            </Text> : null}
          </Box>
        </HStack>
      </GridItem>
    )
  }

  const widthGrid = { base: "100%", md: `calc(100% - ${WIDTH_CATEGORIES})`};

  return (
    <BlockContainer
      className="filterable-list"
      paddingBottom="0 !important"
      maxWidth={{ base: "480px", md: "100%", xl: "1440px" }}
    >
      <Renderer
        field={content}
        overrides={{
          [Element.heading2]: (args: any) =>
            heading2({
              ...args,
              overrideProps: {
                fontSize: { base: "font-30", md: "font-46", lg: "font-50", xl: "font-58" },
                lineHeight: { base: "shorter", md: "none" },
                marginBottom: '0',
                textAlign: "left",
                minWidth: "100%"
              },
            })
          }}
      />
      <Box
        display={{ base: "block", md: "none" }}
        textAlign="left"
        marginTop="space-40"
        minWidth="100%"
        maxWidth="100%"
      >
        {categoriesArray.map((text: string, index: number) => {
          return renderCategory(text, index, true);
        })}
      </Box>
      <HStack
        marginTop={{ base: "space-32", md: "space-40", lg: "space-80" }}
        minWidth="100%"
        alignItems="start"
      >
        <Box
          minWidth={WIDTH_CATEGORIES}
          maxWidth={WIDTH_CATEGORIES}
          display={{ base: "none", md: "block" }}
        >
          {categoriesArray.map((text: string, index: number) => {
            return renderCategory(text, index);
          })}
        </Box>
        <Box
          marginInlineStart="0 !important"
          minWidth={widthGrid}
          maxWidth={widthGrid}
        >
          <Grid
            gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
            textAlign="left"
            mb={showButtonMore ? "space-48" : "space-28"}
          >
            {items && items.length > 0 ? items.map((item) => {
              if (!item) {
                return null;
              }

              /**
               * Due to flickering issues, rather than rendering based on `selectedItems`
               * we render based on `items` then hide items with CSS based on `selectedItems`
               */
              const isDisplayed = selectedItems?.includes(item) || false;

              return renderItem(isDisplayed, item);
            }) : null}
          </Grid>
        </Box>
      </HStack>
      {showButtonMore ? <Button
        alignSelf="center"
        paddingX="space-20"
        paddingY="space-8"
        key={buttonMore}
        variant="primary"
        display="inline-block"
        backgroundColor="grey-0"
        mb="space-60"
        fontWeight="medium"
        fontSize="16"
        lineHeight="base"
        borderColor="grey-0"
        borderWidth="1px"
        onClick={onClickButtonMore}
      >
        {buttonMore}
      </Button> : null}
    </BlockContainer>
  );
};

export default BlockFilterableList;
