import { FC, useState, Fragment } from "react";
import { BlockContainer } from "src/atoms";
import { GatsbyImage } from "gatsby-plugin-image";
import Renderer from "src/lib/renderer";
import { heading1 } from "src/lib/renderer/elements/headings";
import paragraph from "src/lib/renderer/elements/paragraph";
import { Element } from "@prismicio/react";
import { PrismicLink } from "src/atoms";
import { hyperlink } from "src/lib/renderer/elements/hyperlink";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
  Heading,
  Button
} from "@chakra-ui/react";

const MAX_INDEX_IMAGE = 2;

const BlockHoverableCards: FC<Gatsby.PageBlockHoverableCardsFragment> = ({
  primary,
  items
}) => {
  if (!primary) throw Error();

  const {
    content,
    mobile_title: mobileTitle,
    title_first_icon_color: titleFirstIconColor,
    title_first_icon: titleFirstIcon,
    title_second_icon_color: titleSecondIconColor,
    title_second_icon: titleSecondIcon,
    side_content_1_text: sideContent1Text,
    side_content_1_label: sideContent1Label,
    side_content_1_link: sideContent1Link,
    side_content_1_image_1: sideContent1Image1,
    side_content_1_image_2: sideContent1Image2,
    side_content_1_image_3: sideContent1Image3,
    side_content_2_text: sideContent2Text,
    side_content_2_label: sideContent2Label,
    side_content_2_link: sideContent2Link,
    side_content_2_image_1: sideContent2Image1,
    side_content_2_image_2: sideContent2Image2,
    side_content_2_image_3: sideContent2Image3,
  } = primary;

  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    "2xl": false,
    "3xl": false,
  });

  const [expandedItemsIndexes, setExpandedItemsIndexes] = useState<number[]>([]);

  const setItemsIndexes = (index: number) => {
    const newExpandedItemsIndexes = [index];
    setExpandedItemsIndexes(newExpandedItemsIndexes);
  };

  const clearItemsIndexes = () => {
    setExpandedItemsIndexes([]);
  };

  const renderSideContentText = (text?: string, sideContentLink?: Gatsby.Maybe<Gatsby.LinkFragment>) => {
    if (!text || !sideContentLink) {
      return null;
    }

    return (
      <Fragment>
        <PrismicLink
          display={{ base: "inline-block", md: "none" }}
          key={text}
          link={sideContentLink}
          color="#2A93F4"
          fontSize="font-12"
          lineHeight="base"
          fontWeight="500"
          textDecoration="underline"
          _hover={{
            textDecoration: "underline"
          }}
        >
          {text}{' '}→
        </PrismicLink>

        <Text
          display={{ base: "none", md: "block" }}
          color="greyLight-0"
          fontSize="font-14"
          lineHeight="shorter"
          fontWeight="400"
        >
          {text}
        </Text>
      </Fragment>
    );
  }

  const renderSideContentLink = (sideContentLabel?: string, sideContentLink?: Gatsby.Maybe<Gatsby.LinkFragment>) => {
    if (!sideContentLabel || !sideContentLink) {
      return null;
    }

    return (
      <PrismicLink
        display={{ base: "none", md: "inline" }}
        key={sideContentLabel}
        link={sideContentLink}
        color="#2A93F4"
        fontSize="font-15"
        lineHeight="base"
        fontWeight="500"
        pb="space-2"
        borderBottom="1px solid"
      >
        {sideContentLabel}
      </PrismicLink>
    )
  };

  const renderItem = (item: NonNullable<Gatsby.PageBlockHoverableCardsFragment["items"]>[number], index: number) => {
    const isLarge = index === 0;
    const widthProp = isLarge ? { width: { base: "100%", lg: "350px", xl: "426px" } } : { width: "inherit" };
    const titleProp = isLarge ? { maxWidth: { base: "100%", md: "90%", lg: "70%" } } : { maxWidth: { base: "100%", md: "100%", "2xl": "95%" } };

    if (!item) {
      return null;
    }

    const {
      title,
      description,
      background_image: backgroundImage,
      mobile_background_image: mobileBackgroundImage,
      link,
      mobile_cta_label
    } = item;

    const backgroundImageUrl = backgroundImage?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;
    const mobileBackgroundImageUrl = mobileBackgroundImage?.document?.data?.image_file?.gatsbyImageData?.images?.fallback?.src;
    const isExpanded = expandedItemsIndexes.includes(index);
    const boxShadowOpacity = isExpanded ? 1 : 0;
    const hasLink = !!link && link.link_type !== "Any";
    const Wrapper = hasLink && !isMobile ? PrismicLink : Fragment;
    const wrapperProps = hasLink && !isMobile ? {
      link,
    } : {};


    return (
      <GridItem
        {...widthProp}
        key={`${title}-${description}-${backgroundImageUrl}`}
        boxShadow={{ base: "none", md: `inset 0 0 0 2000px rgba(37, 36, 29, ${boxShadowOpacity})`}}
        backgroundImage={{ base: "none", md: `url('${mobileBackgroundImageUrl}')`, lg: `url('${backgroundImageUrl}')` }}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition={{ base: "center 22%", md: "center center" }}
        transition="box-shadow 200ms ease-in"
        borderTopRadius="lg"
        height={{ base: "initial", md: "398px", lg: "480px" }}
        borderBottomRadius="lg"
        marginTop={{ base: "0", md: "space-40" }}
        onMouseEnter={() => { if (!isMobile) setItemsIndexes(index) }}
        onMouseLeave={() => { if (!isMobile) clearItemsIndexes() }}
      >
        <Wrapper
          {...wrapperProps}
        >
          <Flex
            w="full"
            bg={{ base: "none", md: "linear-gradient(to top, #25241D00 70%, #25241D80 100%)"}}
            borderTopRadius="lg"
            flexDirection="column"
            height={{ base: "initial", md: "398px", lg: "480px" }}
          >
            <Box
              display={{ base: "block", md: "none" }}
              backgroundPosition="center 22%"
              backgroundImage={`url('${mobileBackgroundImageUrl}')`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              borderRadius="md"
              height="240px"
            />

            <Flex
              flexDirection="row"
            >
              <Box
                padding={{ base: "0", md: "space-32" }}
                paddingTop={{ base: "space-24", md: "space-32" }}
                paddingBottom={{ base: "space-8", md: "space-32" }}
                color={{ base: "grey-0", md: "white" }}
                fontSize="font-20"
                lineHeight="base"
                textAlign="left"
                w={{ base: "calc(100% - 56px - 8px)", md: "full" }}
              >
                <Text
                  {...titleProp}
                >
                  {title}
                </Text>
              </Box>
            </Flex>

            <Spacer
              display={isExpanded ? "block" : "none"}
            />
 
            <Text
              display={{ base: "block", md: isExpanded ? "block" : "none" }}
              textAlign="left"
              paddingY={{ base: "0", md: "space-24" }}
              paddingX={{ base: "0", md: "space-32" }}
              color={{ base: "grey-0", md: "white" }}
              fontSize={{ base: "font-15", md: "font-16" }}
              lineHeight={{ base: "base", md: "short" }}
            >
              {description}
            </Text>

            <Button
              as="a"
              href={link?.url}
              display={{ base: "flex", md: "none" }}
              fontSize="font-14"
              lineHeight="shortest"
              fontWeight="normal"
              minWidth="full"
              marginTop="space-24"
            >
              {mobile_cta_label}
            </Button>
          </Flex>
        </Wrapper>
      </GridItem>
    );
  }

  const renderImg = (image: Gatsby.Maybe<{
    readonly document: Gatsby.Maybe<Gatsby.ElementImageFixed40Fragment>;
  }>, index: number, shouldOverlap = false, includeMarginRight = false) => {
    const imageFile = image?.document?.data?.image_file;
    if (!imageFile) {
      return null;
    }

    return (
      <Box
        display="inline-block"
        as={GatsbyImage}
        image={imageFile.gatsbyImageData}
        alt={imageFile.alt ?? ""}
        marginRight={shouldOverlap && !includeMarginRight ? { base: "-12px", md: "-16px" } : "0"}
        zIndex={typeof index !== "undefined" ? MAX_INDEX_IMAGE - index : "inherit"}
        maxWidth={{ base: "28px", md: "32px" }}
        maxHeight={{ base: "28px", md: "32px" }}
      />
    );
  };

  return (
    <BlockContainer
      paddingTop={{ base: "-16px !important", md: "24px !important" }}
      paddingX={{ base: "space-16", md: "space-58", "2xl": 0 }}
      >
      <VStack w="full">
        <Flex
          w="full"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            maxWidth={{ base: "full", md: "50%" }}
            textAlign="left"
          >
            <Renderer
              field={mobileTitle}
              overrides={{
                [Element.heading1]: ({ key, content }: any) => {
                  const style = `
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 52px;
                    height: 34px;
                    border-radius: 18px;
                  `.trim()
                  const imgStyle = `
                    width: 16px;
                    height: 16px;
                  `.trim()
                  const text = content
                    .replace('$1', `
                      <span style="${style} background-color: ${titleFirstIconColor}">
                        <img src="${titleFirstIcon?.document?.data?.image_file?.url}" alt="${titleFirstIcon?.document?.data?.image_file?.alt}" style="${imgStyle}" />
                      </span>
                    `.trim())
                    .replace('$2', `
                      <span style="${style} background-color: ${titleSecondIconColor}">
                        <img src="${titleSecondIcon?.document?.data?.image_file?.url}" alt="${titleSecondIcon?.document?.data?.image_file?.alt}" style="${imgStyle}" />
                      </span>
                    `.trim())

                  return (
                    <Heading
                      as="h1"
                      variant="heading-1"
                      key={key}
                      marginBottom="0"
                      display={{ base: "flex", md: "none" }}
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      fontSize="font-40"
                      lineHeight="45px"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )
                },
              }}
            />

            <Renderer
              field={content}
              overrides={{
                [Element.heading1]: (args) =>
                  heading1({
                    ...args,
                    overrideProps: {
                      display: { base: "none", md: "block" },
                      fontSize: "font-58",
                      lineHeight: "shortest"
                    },
                  }),
                [Element.paragraph]: (args) =>
                  paragraph({
                    ...args,
                    overrideProps: {
                      marginTop: { base: "space-24", md: "0" },
                      textAlign: { base: "center", md: "left" },
                      fontSize: { base: "font-15", md: "font-20" },
                      lineHeight: "base"
                    },
                  }),
                [Element.hyperlink]: (args) =>
                  hyperlink({
                    ...args,
                    hasArrow: false,
                    overrideProps: {
                      fontWeight: "normal",
                      fontSize: { base: "font-14", md: "font-15" },
                      lineHeight: { base: "shortest", md: "base" },
                      minWidth: { base: "full", md: "inherit" },
                      marginBottom: { base: "8px", md: "0" }
                    }
                  }),
              }}
            />
          </Box>

          <Spacer
            display={{ base: "none", md: "block" }}
          />

          <Grid
            gridTemplateColumns={{ base: "auto 1fr auto 1fr", md: "auto auto" }}
            gridColumnGap={{ base: "space-8", md: "space-12" }}
            height={{ base: "inherit", md: "0" }}
            alignItems={{ base: "center", md: "flex-start" }}
            marginBottom={{ base: "space-40", md: "inherit" }}
          >
            <GridItem
              gridArea="1/1"
              display={{ base: "flex", md: "none" }}
              textAlign="left"
            >
              {renderImg(sideContent1Image1, 0, true, !sideContent1Image2 && !sideContent1Image3)}
              {renderImg(sideContent1Image2, 1, true, !sideContent1Image3)}
              {renderImg(sideContent1Image3, 2, true, true)}
            </GridItem>

            <GridItem
              display={{ base: "none", md: "block" }}
              textAlign="right"
            >
              {renderImg(sideContent1Image1, 0, true, !sideContent1Image2 && !sideContent1Image3)}
              {renderImg(sideContent1Image2, 1, true, !sideContent1Image3)}
              {renderImg(sideContent1Image3, 2, true, true)}
            </GridItem>

            <GridItem
              display={{ base: "none", md: "inline-block" }}
              textAlign="left"
            >
              {renderSideContentText(sideContent1Text, sideContent1Link)}
              {renderSideContentLink(sideContent1Label, sideContent1Link)}
            </GridItem>

            <GridItem
              gridArea="1/3"
              display={{ base: "flex", md: "none" }}
              textAlign="left"
            >
              {renderImg(sideContent2Image1, 0)}
              {renderImg(sideContent2Image2, 1)}
              {renderImg(sideContent2Image3, 2)}
            </GridItem>

            <GridItem
              gridArea="1/2"
              display={{ base: "block", md: "none" }}
              textAlign="left"
              mr="space-8"
            >
              {renderSideContentText(sideContent1Text, sideContent1Link)}
              {renderSideContentLink(sideContent1Label, sideContent1Link)}
            </GridItem>

            <GridItem
              gridArea="1/4"
              display={{ base: "block", md: "none" }}
              textAlign="left"
            >
              {renderSideContentText(sideContent2Text, sideContent2Link)}
              {renderSideContentLink(sideContent2Label, sideContent2Link)}
            </GridItem>

            <GridItem
              display={{ base: "none", md: "block" }}
              textAlign="right"
              marginTop="space-24"
            >
              {renderImg(sideContent2Image1, 0)}
              {renderImg(sideContent2Image2, 1)}
              {renderImg(sideContent2Image3, 2)}
            </GridItem>

            <GridItem
              textAlign="left"
              marginTop="space-24"
              display={{ base: "none", md: "inline-block" }}
            >
              {renderSideContentText(sideContent2Text, sideContent2Link)}
              {renderSideContentLink(sideContent2Label, sideContent2Link)}
            </GridItem>
          </Grid>
        </Flex>

        <Grid
          w='full'
          marginTop={{ base: "0 !important", md: "inherit" }}
          gridTemplateColumns={{ base: "1", md: "repeat(2, 1fr)", lg: "auto repeat(3, 1fr)" }}
          gridTemplateRows={{ base: "auto", md: "2", lg: "1" }}
          gridGap={{ base: "space-48", md: "space-16" }}
        >
          {items?.length ? items.map((item, index: number) => {
            if (!item) {
              return null;
            }

            return renderItem(item, index);
          }) : null}
        </Grid>
      </VStack>
    </BlockContainer>
  )
};

export default BlockHoverableCards;
