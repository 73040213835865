import { VStack } from "@chakra-ui/react";
import { Element } from "@prismicio/react";
import { FC } from "react";
import Renderer from "src/lib/renderer";
import { heading2, heading3 } from "src/lib/renderer/elements/headings";
import { BlockContainer } from "src/atoms";

import MobileStack from "./components/MobileStack";
import DesktopCarousel from "./components/DesktopCarousel";

const BlockPersona: FC<Gatsby.PageBlockPersonaFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { content } = primary;

  const shouldOverflow = items?.length > 4;

  return (
    <BlockContainer
      paddingX={{ base: "space-16", md: "0" }}
      maxWidth={{ md: shouldOverflow ? "1776px" : "1440px" }}
    >
      <VStack spacing={{ base: "space-24", md: "space-40" }} w={{ md: "full" }}>
        <VStack
          spacing={{ base: "space-8" }}
          paddingX={{ md: "194px", "2xl": "136px" }}
          maxWidth={{ md: "1440px", "2xl": "1434px" }}
          w="full"
          sx={{
            "h2, h3, p": {
              color: "greyLight-3",
            },
          }}
        >
          <Renderer
            overrides={{
              [Element.heading2]: (args) =>
                heading2({
                  ...args,
                  overrideProps: {
                    marginBottom: 0,
                  },
                }),
              [Element.heading3]: (args) =>
                heading3({
                  ...args,
                  overrideProps: {
                    variant: "heading-4",
                    marginBottom: 0,
                  },
                }),
            }}
            field={content}
          />
        </VStack>

        <MobileStack items={items} primary={primary} />

        <DesktopCarousel items={items} primary={primary} />

      </VStack>
    </BlockContainer>
  );
};

export default BlockPersona;
