import { Container, Heading, Tab, TabList, Tabs } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import { motionVariants } from "src/atoms/Carousel/utils";
import PressArticles from "./components/PressArticles";

type ArticleType = "articles" | "releases";

const BlockPressArticles: FC<Gatsby.BlockPressArticlesFragment> = ({
  primary,
  items,
}) => {
  if (!primary || !items) throw Error();

  const { press_article_title, press_release_title } = primary;

  const [[tabIndex, direction], setTab] = useState([0, 0]);

  const groupedItems = items.reduce(
    ({ articles, releases }, item) => ({
      articles:
        item?.article_type === "Press article" ? [...articles, item] : articles,
      releases:
        item?.article_type === "Press release" ? [...releases, item] : releases,
    }),
    {
      articles: [] as Gatsby.PressArticleFragment[],
      releases: [] as Gatsby.PressArticleFragment[],
    },
  );

  const tabs: { id: ArticleType; label?: string }[] = [
    {
      id: "articles",
      label: press_article_title,
    },
    {
      id: "releases",
      label: press_release_title,
    },
  ];

  return (
    <Container
      backgroundColor="ctaBlue-6"
      size="full"
      paddingY="space-64"
      paddingX="space-24"
    >
      <Tabs
        index={tabIndex}
        onChange={(i) => {
          if (i !== tabIndex) {
            setTab([i, i - tabIndex]);
          }
        }}
        align="center"
        width="100%"
        maxWidth="1000px"
      >
        <TabList
          width="fit-content"
          marginBottom="space-96"
          sx={{
            "> * + *": {
              marginLeft: "space-16",
            },
          }}
          flexDirection={{ base: "column", sm: "row" }}
        >
          {tabs.map((tab) => (
            <Tab
              fontWeight="medium"
              key={tab.id}
              paddingX="space-48"
              paddingY="space-24"
              _selected={{
                borderBottomColor: "bg-primary-active",
              }}
            >
              <Heading as="h2" variant="heading-2">
                {tab.label}
              </Heading>
            </Tab>
          ))}
        </TabList>

        <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
          <motion.div
            key={`plan-${tabs[tabIndex].id}`}
            custom={direction}
            initial="before"
            animate="in"
            exit="after"
            variants={motionVariants}
            transition={{ ease: "easeOut", duration: 0.2 }}
          >
            <PressArticles articles={groupedItems[tabs[tabIndex].id]} />
          </motion.div>
        </AnimatePresence>
      </Tabs>
    </Container>
  );
};

export default BlockPressArticles;
