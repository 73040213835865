import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { FC } from "react";
import { ShiIcon } from "src/atoms";

const CarouselArrow: FC<IconButtonProps> = ({ ...props }) => (
  <IconButton
    zIndex="docked"
    bg="bg-primary-active"
    color="white"
    _hover={
      props.disabled
        ? undefined
        : {
            bg: "bg-primary-hover",
          }
    }
    variant="icon"
    boxSize="40px"
    borderRadius="full"
    icon={<ShiIcon name="arrowicon" />}
    {...props}
  />
);

export default CarouselArrow;
