import { FC, Fragment } from "react";
import Renderer from "src/lib/renderer";
import BlockHubspotFormIntegration from './BlockHubspotFormIntegration';

const BlockHubspotForm: FC<Gatsby.PageBlockHubspotFormFragment & { index: number }> = ({
  index: formIndex,
  primary
}) => {
  if (!primary) throw Error();

  const {
    content,
    form_id: formId,
    inline_message: inlineMessage,
    redirect_url: redirectUrl
  } = primary;

  if (!formId) {
    return null;
  }

  const redirectUrlString = redirectUrl?.url || '';

  return <Fragment>
    <Renderer field={content} />
    <BlockHubspotFormIntegration
      formId={formId}
      formIndex={formIndex}
      inlineMessage={inlineMessage}
      redirectUrl={redirectUrlString}
    />
  </Fragment>;
};

export default BlockHubspotForm;
