const BACKGROUND_COLOR = {
  RED: "pink-0",
  BLUE: "blue-0",
  LIGHTBLUE: "blue-1",
  LIGHTGREY: "greyLight-3",
};

export const getBackgroundColor = (backgroundColor: string | null) => {
  switch (backgroundColor) {
    case "blue":
      return BACKGROUND_COLOR.BLUE;

    case "light grey":
      return BACKGROUND_COLOR.LIGHTGREY;

    case "light blue":
      return BACKGROUND_COLOR.LIGHTBLUE;

    default:
      return BACKGROUND_COLOR.RED;
  }
};
