const headingToAnchorLink = (text?: any) =>
  text && typeof text === "string"
    ? text
      .toLowerCase()
      .replace(/[*+~.()'"!:@?]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/%/, 'percent')
      .normalize('NFKD')
      .replace(/[^\w-]/g, '')
    : undefined;

export default headingToAnchorLink;
